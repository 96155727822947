<form [formGroup]="orderForm">
  <div class="bg-white1 row px-1 py-3 mt-2">
    <div class="col-12 mb-2">
      <h2 class="m-0 text-black-alpha-90">عنوان التوصيل</h2>
    </div>
    <div class="field col-8" *ngIf="billingAddresses.length != 0">
      <span class="p-float-label">
        <p-dropdown
          [options]="billingAddresses"
          formControlName="billing_address_id"
          (ngModelChange)="selectBillingAddress()"
          optionLabel="address"
          optionValue="id"
          styleClass="w-100"
          inputId="billing_address_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="billing_address_id">العنوان</label>
      </span>
    </div>
    <div class="col-3" *ngIf="billingAddresses.length == 0">
      <p class="m-0 text-danger">*هذا العميل ليس لديه عنوان توصيل</p>
    </div>
    <div class="col-md-4 d-flex align-items-center gap-2">
      <button class="btn btn-info" (click)="showAddBillingAddressModal('add')">
        إضافة
      </button>
      <button
        class="btn btn-success"
        (click)="showAddBillingAddressModal('edit')"
        *ngIf="selectedBillingAddress"
      >
        تعديل
      </button>
    </div>
    <div class="col-12" *ngIf="selectedBillingAddress">
      <div class="row py-2">
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">الاسم الأول :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.first_name || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">الاسم الأخير :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.last_name || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">رقم التليفون :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.phone || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">المنطقة :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.areas?.name || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">رقم المنزل :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.building_number || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">رقم الدور :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.floor_number || "--" }}
          </p>
        </div>
        <div class="col-md-3 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">رقم الشقة :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.apartment_number || "--" }}
          </p>
        </div>
        <div class="col-12 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">العنوان :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.address || "--" }}
          </p>
        </div>
        <div class="col-12 d-flex gap-2">
          <p class="m-0 text-black-alpha-90">الملاحظات :</p>
          <p class="m-0 text-black-alpha-90">
            {{ selectedBillingAddress?.notes || "--" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</form>

<p-dialog
  header="أضف عنوان للتوصيل"
  [(visible)]="addBillingAddressModalVisibility"
  [styleClass]="'w-md-50 w-100'"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="hideAddBillingAddressModal()"
>
  <form [formGroup]="billingAddressForm">
    <div class="row py-3">
      <div class="col-md-3 col-6 px-2">
        <span class="p-float-label">
          <p-dropdown
            [options]="towns"
            formControlName="area_id"
            optionLabel="name"
            optionValue="id"
            styleClass="w-100"
            inputId="area_id"
            [showClear]="true"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label for="area_id">المنطقة</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="latitude"
            type="text"
            pInputText
            class="w-100"
            formControlName="latitude"
          />
          <label for="latitude">خط العرض</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="longitude"
            type="text"
            pInputText
            class="w-100"
            formControlName="longitude"
          />
          <label for="longitude">خط الطول</label>
        </span>
      </div>
      <div class="col-md-1">
        <img
          (click)="showChooseLocationModal()"
          width="50"
          class="h-50px"
          src="assets/images/location.png"
        />
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="first_name"
            type="text"
            pInputText
            class="w-100"
            formControlName="first_name"
          />
          <label for="first_name">الاسم الأول</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="last_name"
            type="text"
            pInputText
            class="w-100"
            formControlName="last_name"
          />
          <label for="last_name">الاسم الأخير</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="mobile"
            type="text"
            pInputText
            class="w-100"
            formControlName="phone"
          />
          <label for="phone">رقم الهاتف</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="building_number"
            type="text"
            pInputText
            class="w-100"
            formControlName="building_number"
          />
          <label for="building_number">رقم المنزل</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="floor_number"
            type="text"
            pInputText
            class="w-100"
            formControlName="floor_number"
          />
          <label for="floor_number">رقم الدور</label>
        </span>
      </div>
      <div class="field col-md-4 col-6 px-2">
        <span class="p-float-label">
          <input
            id="apartment_number"
            type="text"
            pInputText
            class="w-100"
            formControlName="apartment_number"
          />
          <label for="apartment_number">رقم الشقة</label>
        </span>
      </div>

      <div class="field col-12">
        <span class="p-float-label">
          <input
            id="address"
            type="text"
            pInputText
            class="w-100"
            formControlName="address"
          />
          <label for="address">العنوان</label>
        </span>
      </div>
      <div class="field col-12">
        <span class="p-float-label">
          <input
            id="notes"
            type="text"
            pInputText
            class="w-100"
            formControlName="notes"
          />
          <label for="notes">الملاحظات</label>
        </span>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-info w-75"
          (click)="
            isBillingAddressEdit ? editBillingAddress() : addBillingAddress()
          "
        >
          {{ isBillingAddressEdit ? "تعديل" : "أضف" }}
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog
  [(visible)]="chooseLocationModalVisibility"
  header="تحديث العنوان"
  [styleClass]="'w-md-50 w-100'"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="hideChooseLocationModal()"
>
  <input
    type="text"
    style="margin-bottom: 15px"
    nz-input
    nzSize="large"
    id="address"
    [(ngModel)]="coordinate"
    #address
    placeholder="كتابة العنوان الجديد"
    (input)="changeAddress($event)"
  />

  <!-- <nz-autocomplete #address>
    <nz-auto-option
      *ngFor="let option of options"
      [nzLabel]="option.formatted_address"
      [nzValue]="option.place_id"
    >
      {{ option.formatted_address }}
    </nz-auto-option>
  </nz-autocomplete> -->
  <div class="row">
    <div class="col-12">
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" #map3>
        <agm-marker
          (dragEnd)="markerDragEnd($event)"
          [markerDraggable]="true"
          [latitude]="lat"
          [longitude]="lng"
        ></agm-marker>
      </agm-map>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button
        class="btn btn-info w-75 mt-1"
        matRipple
        (click)="hideChooseLocationModal()"
      >
        <div class="loader text-center w-100" *ngIf="updateLoader"></div>
        <span *ngIf="!updateLoader"> تحديث العنوان </span>
      </button>
    </div>
  </div>
</p-dialog>
