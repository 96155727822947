import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinanceReportsService {


  API_data : string = "bills/indexForAdmin";
  API_reports : string = "orders/getorderReportForAdmin";
  API_orders : string = "orders/getorderReportForAdminCounts";

  constructor(private http : HttpClient ) { }


  get_finance_reports_indexForAdmin() {
    return this.http.get(`${environment.endpoint}/${this.API_data}`).toPromise();
  }

  get_finance_reports_getorderReportForAdmin() {
    return this.http.get(`${environment.endpoint}/${this.API_reports}`).toPromise();
  }

  get_finance_reports_getorderReportForAdminCounts() {
    return this.http.get(`${environment.endpoint}/${this.API_orders}`).toPromise();
  }

  getCancelationOrderReport() {
    return this.http.get(`${environment.endpoint}/orders/getCancelationOrderReport`).toPromise();
  }

  getCommission() {
    return this.http.get(`${environment.endpoint}/orders/getorderReportByCategories`).toPromise()
  }


}

