<div class="row">

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  المطاعم الجديدة
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.weekclient?.NewVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  إجمالي المطاعم
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.VendortotalsMonth}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                    المطاعم النشطة
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.ActiveVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  في انتظار الموافقة
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.WaitApproveVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  في انتظار التدريب
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.WaitTrainingVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  في انتظار الإختبار
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.WaitTestVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-4 col-lg-3">
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="m-r-10">
                    <span class="btn btn-circle btn-lg btn-info">
                        <i class="fas fa-info"></i>
                    </span>
                </div>
                <div>
                  المرفوض
                </div>
                <div class="ml-auto">
                    <h2 class="m-b-0 font-light">{{counts.monthclient?.RefusedVendors}}</h2>
                </div>
            </div>
        </div>
    </div>
  </div>


</div>


<div class="row">
<div class="col-md-6 col-lg-6">
  <!-- ============================================================== -->
  <!-- month sales chart -->
  <!-- ============================================================== -->
  <app-bar-chart
      [sales]=" charts.monthordersForchart"
      [color]="'#4798e8'"
      [title]="'أكثر المطاعم طلبا هذا شهر'"
      [type]="'line'"
      [selector]="'month-chart'"
      [orderLabelsKey]="'modified'"
      [orderTotalKey]="'VendortotalsMonth'"></app-bar-chart>
  <!-- ============================================================== -->
  <!-- month sales chart -->
  <!-- ============================================================== -->
</div>
<div class="col-md-6 col-lg-6">
  <!-- ============================================================== -->
  <!-- week sales chart -->
  <!-- ============================================================== -->
  <app-bar-chart
      [sales]="charts.weekordersForchart"
      [color]="'#22c6ab'"
      [title]="'أكثر المطاعم طلبا هذا الاسبوع'"
      [type]="'line'"
      [selector]="'week-chart'"
      [orderLabelsKey]="'id'"
      [orderTotalKey]="'NewMonthVendor'"></app-bar-chart>
  <!-- ============================================================== -->
  <!-- week sales chart -->
  <!-- ============================================================== -->
</div>
</div>


<div class="row">
  <div class="col-6">
    <div class="card">
        <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;"> أكثر المطاعم طلبا </h3>
        <div class="card-body">
            <div class="table-responsive card custom-br custom-shadow">
                <nz-table
                    [nzData]="tables.mostOrderedVendor || []"
                    [nzScroll]="{ x: '240px' }"
                    class="table forms-table table-striped"
                    >
                    <thead>
                      <tr class="bg-light">
                          <th>#</th>
                            <th *ngFor="let head of mostOrderedVendorHead; let i = index">
                                {{head}}
                            </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let res of tables.mostOrderedVendor || []; let i = index" style="cursor: pointer; direction: ltr;">
                            <ng-container>
                                <td style="vertical-align: middle;">{{i + 1}}</td>
                                <td style="vertical-align: middle;">{{res?.name}}</td>
                                <td style="vertical-align: middle;">{{res?.itemamount}}</td>
                            </ng-container>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>
</div>
  <div class="col-6">
    <div class="card">
        <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;"> تقييم المطاعم</h3>
        <div class="card-body">
            <div class="table-responsive card custom-br custom-shadow">
                <nz-table
                    [nzData]="tables.getMostRateVendor || []"
                    [nzScroll]="{ x: '240px' }"
                    class="table forms-table table-striped"
                    >
                    <thead>
                      <tr class="bg-light">
                          <th>#</th>
                            <th *ngFor="let head of mostRateVendorHead; let i = index">
                                {{head}}
                            </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rate of tables.getMostRateVendor || []; let i = index" style="cursor: pointer; direction: ltr;">
                            <ng-container>
                                <td style="vertical-align: middle;">{{i + 1}}</td>
                                <td style="vertical-align: middle;">{{rate?.name}}</td>
                                <td style="vertical-align: middle;">{{rate?.comment}}</td>
                                <td style="vertical-align: middle;">{{rate?.rate}}</td>
                                <td style="vertical-align: middle;">{{rate?.RestaurantAvg}}</td>
                            </ng-container>
                        </tr>
                    </tbody>
                </nz-table>
            </div>
        </div>
    </div>
</div>
</div>

<div class="row">
<div class="col-12">
<a routerLink="/restaurants/all" class="btn btn-success">
  تفاصيل المطاعم
</a>
</div>
</div>
