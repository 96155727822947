import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MyVendorsRouterModule } from "./my-vendors-routing.module";
import { EditComponent } from "./add/edit/edit.component";
import { IndexComponent } from "./index/index.component";
import { SharedModule } from "src/core/module/shared.module";
import { MyVendorsRepInvComponent } from "./my-vendors-rep-inv/my-vendors-rep-inv.component";
import { NzModalRef, NzModalService } from "ng-zorro-antd";
import { MyVendorsReportComponent } from "./my-vendors-report/my-vendors-report.component";

@NgModule({
  declarations: [
    IndexComponent,
    MyVendorsRepInvComponent,
    MyVendorsReportComponent,
    EditComponent,
  ],
  imports: [CommonModule, SharedModule, MyVendorsRouterModule],
  providers: [
    {
      provide: NzModalRef,
      useValue: {
        getInstance: () => {
          return {
            setFooterWithTemplate: () => {},
          };
        },
      },
    },
  ],
})
export class MyVendorsModule {}
