export class OrdersHelper {

    constructor() {}

    shapeOrderObject(data: any[]) {
      let orders: any = [];

      data.forEach(val => {
        let obj = {
          id: val["id"],
          user: (Boolean(val["users"])) ? val["users"]["username"] : '',
          adress: (val["billing_address"]) ? val["billing_address"]["address"] : val["users"] != null ? val["users"]["address"] : "" ,

          total: val["total"],
          taxes: val["taxes"],
          discount: val["discount"],
          paymenttype: (val["paymenttype"]) ,

          order_status:(Boolean(val["order_status"])) ? val["order_status"]["name"] : '' ,

          modified: val["modified"],
          orderdetails: val["order_details"],
          billing_addres: val["billing_address"],

          order_status_id: val["order_status_id"],

          delivery_id: val["delivery_id"],
          branches: val["branches"],
        }

        orders = [...orders, obj];
      });

      return orders;
    }

  createDetails(orders: any[]) {
    const details = orders.map(val => {
      return {
        item: val["menu_categories_items"]["name"],
        options:val["order_details_options"],
        itemamount: val["amount"],
        itemtotal: val["total"],
        notes: val["notes"],
      }
    });

    return details;
  }

}
