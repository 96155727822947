<div class="row">
  <button
    matRipple
    matRippleColor="rgba(0,0,0,.2) "
    matRippleCentered="centered"
    nz-button
    nzType="default"
    class="mb-2"
    (click)="openAddDirectorModal()"
  >
    <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
    اضافة
  </button>

  <div class="col-md-12">
    <div class="table-responsive card custom-br custom-shadow">
      <nz-table
        [nzFrontPagination]="false"
        [nzData]="users?.results"
        [nzLoading]="loading"
        [nzScroll]="{ x: '240px' }"
        class="table forms-table table-striped"
      >
        <thead>
          <tr class="bg-light">
            <th>#</th>
            <th *ngFor="let head of heads; let i = index">
              {{ head }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let user of users?.results; let i = index"
            style="cursor: pointer; direction: ltr"
          >
            <ng-container>
              <td style="vertical-align: middle">{{ i + 1 }}</td>
              <td style="vertical-align: middle">{{ user.name }}</td>

              <!-- <td style="vertical-align: middle;">{{user.username}}</td>         -->

              <td style="vertical-align: middle">
                {{ user.mobile }}
              </td>

              <td>
                {{ user.position }}
              </td>
            </ng-container>
            <td>
              <div class="d-flex gap-1 justify-content-center">
                <button
                  class="btn btn-danger"
                  matRipple
                  (nzOnConfirm)="delteUser(user.id, i)"
                  nz-popconfirm
                  nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
                  nzPopconfirmPlacement="top"
                >
                  حذف
                </button>
                <button
                  class="btn btn-success"
                  (click)="editUser(user)"
                  matRipple
                >
                  تعديل
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-pagination
        *ngIf="users?.total"
        [nzPageIndex]="page"
        [nzTotal]="users?.total"
        nzShowSizeChanger
        [nzPageSizeOptions]="[5, 10, 15, 20, 50]"
        [nzPageSize]="limit"
        (nzPageIndexChange)="onPageChange($event)"
        (nzPageSizeChange)="onSizeChange($event)"
      >
      </nz-pagination>
    </div>
  </div>
</div>
