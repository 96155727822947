import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { options } from 'src/app/options';
import { environment } from 'src/environments/environment';
import { AreasCreate } from './citiesModel';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<AreasCreate[]>(`${environment.endpoint}/Cities/index`).toPromise();
  }  

  add(data: any) {
    return this.http.post(`${environment.endpoint}/Cities/add`, data).toPromise();
  }

  edit(obj, id) {
    return this.http.post(`${environment.endpoint}/Cities/edit/${id}`, obj).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/Cities/delete/${id}`).toPromise();
  }  

  GetArea() {
    return this.http.get<AreasCreate>(`${environment.endpoint}/Cities/getCities`).toPromise();
  }
}
