import { GenericService } from "src/core/services/generic/generic.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { BranchesService } from "src/core/services/branches/branches.service";
import { BillsService } from "src/core/services/bills/bills.service";
import * as moment from "moment";
import { ConfigrationsService } from "src/core/services/configrations/configrations.service";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: "app-my-vendors-rep-inv",
  templateUrl: "./my-vendors-rep-inv.component.html",
  styleUrls: ["./my-vendors-rep-inv.component.css"],
})
export class MyVendorsRepInvComponent implements OnInit, OnDestroy {
  form: FormGroup;
  id: any;
  branchId: any;
  name: any;
  branches: any[] = [];

  loader: boolean = false;

  totMonthSales: any = 0;
  totWeekSales: any = 0;

  payments: any[] = [];
  expenses: any[] = [];
  monthordersForchart: any[] = [];
  weekordersForchart: any[] = [];

  heads: any[] = ["الطبق", "القيمة", "المجموع"];
  orderDetails: any[] = [];

  percentage: number = 0;

  deserved: any[] = [];
  deservedHead: any[] = [
    "رقم الفاتورة",
    "من تاريخ",
    "الى تاريخ",
    " عدد الطلبات",
    " اجمالي الطلبات",
    "حالة الدفع",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];

  bills: any[] = [];
  billsHead: any[] = [
    "رقم الفاتورة",
    "من تاريخ",
    "الى تاريخ",
    " عدد الطلبات",
    "حالة الدفع",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];
  unpaidBills: number = 0;
  paidBills: number = 0;

  date: any[] = [];
  filtred: any[] = [];
  filtredHeads = [
    "#",
    "من تاريخ",
    "الى تاريخ",
    "عدد الطلبات",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];

  dates: any[] = [];

  isRestaurant =
    localStorage.getItem("RestaurantId") &&
    localStorage.getItem("RestaurantId") != "null"
      ? true
      : false;

  invoiceFor: any = {};
  from: any;
  to: any;
  invoiceType: string = "";
  invoiceTotal: number = 0;
  configrations: any[] = [];
  branch: any = {};

  constructor(
    private service: BranchesService,
    private route: ActivatedRoute,
    private billsService: BillsService,
    public generic: GenericService,
    private router: Router,
    private fb: FormBuilder,
    private configrationsService: ConfigrationsService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.name = this.route.snapshot.paramMap.get("name");
    this.createForm();
    this.get();
    this.getBranches(this.id);
  }

  async getBranches(id: any) {
    console.log(id);
    this.branches = await this.service.getRestaurant(id).toPromise();
    console.log(this.branches[0].id);
    this.form.get("branchId").setValue(this.branches[0].id || null);
  }

  async get() {
    const data: any = await this.configrationsService.get();
    this.configrations = data;
  }

  createForm() {
    this.form = this.fb.group({
      branchId: [null],
    });
  }

  getReport(branchId: any) {
    this.branchId = branchId;
    this.branch = this.branches.filter((branch) => branch.id == branchId)[0];
    this.loader = true;

    this.service.getBrachReport(branchId, {}).subscribe(
      (res: any) => {
        this.totMonthSales = res.monthorders[0].Ordertotals;
        this.totWeekSales = res.monthorders[0].weekorders;

        this.payments = res.OrderByPayment;
        this.monthordersForchart = res.monthordersForchart;
        this.weekordersForchart = res.weekordersForchart;
        this.orderDetails = res.orderdetails;

        this.percentage = parseFloat(res.Confirgrations[0].value);

        this.getorderReportForBranch(branchId);
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  getorderReportForBranch(branchId) {
    this.service.getorderReportForBranch(branchId).subscribe(
      (res: any) => {
        this.deserved = res.weekorders;
        this.getBills(branchId);
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  getFromDate(month: string) {
    const date: any[] = month.split("-");
    const today = date[2];
    date[2] = today - date[2] + 1;

    return date.join("-");
  }

  getBills(branchId) {
    this.billsService.get(branchId).subscribe(
      (res: any) => {
        this.bills = res;

        for (let i = 0; i < this.bills.length; i++) {
          this.dates.push(this.bills[i].date_from);
          this.dates.push(this.bills[i].date_to);
        }
        localStorage.setItem("dates", JSON.stringify(this.dates));
        this.unpaidBills = this.bills.filter((bill) => !bill.confirmed).length;
        this.paidBills = this.bills
          .filter((bill) => bill.confirmed)
          .map((bill) => bill.total)
          .reduce((prev, next) => prev + next, 0);
        this.loader = false;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  getFiltredBills() {
    const obj = {
      from: this.generic.formatDate(this.date[0], "filter"),
      to: this.generic.formatDate(this.date[1], "filter"),
    };

    this.loader = true;

    this.service.filtrOrdersForBills(this.branchId, obj).subscribe(
      (res: any) => {
        this.filtred = res;
        this.loader = false;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  disabledDate(current) {
    const formated = moment(current).format("YYYY-MM-DD");
    const dates: any[] = JSON.parse(localStorage.getItem("dates"));

    if (dates.includes(formated)) return true;

    return false;
  }

  createInvoice(item: any) {
    this.loader = true;

    const bill = {
      branch_id: this.branchId,
      confirmed: 0,
      date_from: this.generic.formatDate(this.date[0], "filter"),
      date_to: this.generic.formatDate(this.date[1], "filter"),
      total: item.Ordertotals,
      type: item.commission - item.visaOrders < 0 ? 1 : 0,
    };

    this.billsService.addBill(bill).subscribe(
      (res) => {
        this.loader = false;
        item.month = this.generic.formatDate(this.date[1], "filter");
        localStorage.setItem("invoiceFor", JSON.stringify(item));
        this.router.navigate([`invoice/create`]);
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  createDeservedInvoice(item: any) {
    this.loader = true;

    const date: any[] = item.month.split("-");
    const today = date[2];

    date[2] = today - date[2] + 1;
    date[2] = String(date[2]).length == 2 ? date[2] : `0${date[2]}`;
    const from = date.join("-");

    const bill = {
      branch_id: item.branch_id,
      confirmed: 0,
      date_from: from,
      date_to: item.month,
      total: item.Ordertotals,
      type: item.commission - item.visaOrders < 0 ? 1 : 0,
    };

    this.billsService.addBill(bill).subscribe(
      (res) => {
        this.loader = false;

        localStorage.setItem("invoiceFor", JSON.stringify(item));
        this.router.navigate([`invoice/create`]);
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  invoiceData(invoice) {
    this.invoiceFor = invoice;

    if (!(this.invoiceFor["month"] in this.invoiceFor)) {
      this.invoiceFor.month = this.generic.formatDate(
        invoice.date_to,
        "filter"
      );
    }

    const date: any[] = this.invoiceFor.month.split("-");
    const today = date[2];

    date[2] = today - date[2] + 1;
    this.from = date.reverse().join("-");

    date[0] = today;
    this.to = date.join("-");

    this.invoiceTotal = this.invoiceFor.commission - this.invoiceFor.visaOrders;
    this.invoiceType = this.invoiceTotal < 0 ? "صادر" : "وارد";
    this.invoiceTotal *= -1;

    return true;
  }

  captureScreen(invoice) {
    this.invoiceData(invoice);
    this.loader = true;

    setTimeout(() => {
      let data = document.getElementById("contentToConvert");
      data.classList.remove("d-none");
      html2canvas(data as any).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdfData = new jsPDF("p", "mm", "a4");
        var position = 0;
        pdfData.addImage(
          contentDataURL,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        pdfData.save(`${this.name}invoice.pdf`);
        data.classList.add("d-none");

        this.loader = false;
      });
    }, 10);
  }

  printPageArea(invoice) {
    this.invoiceData(invoice);
    this.loader = true;

    setTimeout(() => {
      let data = document.getElementById("contentToConvert");
      data.classList.remove("d-none");
      html2canvas(data as any).then((canvas) => {
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        const pdfData = new jsPDF("p", "mm", "a4");
        const position = 0;

        pdfData.addImage(
          contentDataURL,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        pdfData.autoPrint();
        window.open(URL.createObjectURL(pdfData.output("blob")), "_blank");
        data.classList.add("d-none");

        this.loader = false;
      });
    }, 10);
  }

  ngOnDestroy(): void {
    localStorage.removeItem("dates");
  }
}
