import { Routes } from "@angular/router";
import { NotificationsFormComponent } from "./notifications-form/notifications-form.component";
import { NotificationsIndexComponent } from "./notifications-index/notifications-index.component";

export const NotificationsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'all',
                component: NotificationsIndexComponent,
                data: {
                    title: 'الاشعارات',
                },                             
            },
            {
                path: 'add',
                component: NotificationsFormComponent,
                data: {
                    title: 'اضافة اشعار',
                },                             
            },
            {
                path: 'edit',
                component: NotificationsFormComponent,
                data: {
                    title: 'تعديل الاشعار',
                },                             
            },
        ]
    }
]