import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { options } from 'src/app/options';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuisinesService {

  constructor(private http: HttpClient) { }

  add(obj) {
    return this.http.post(`${environment.endpoint}/cuisines/add`, obj).toPromise();
  }

  edit(obj, id) { 
    return this.http.post(`${environment.endpoint}/cuisines/edit/${id}`, obj).toPromise();
  } 
  
  delete(id) {
    return this.http.get(`${environment.endpoint}/cuisines/delete/${id}`).toPromise();
  }

}
