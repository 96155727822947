import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;
import { select, Store } from "@ngrx/store";

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { fromEvent, merge, Observable, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { AppState } from "src/app/main/dashboards/dashboard-components/reducers";
import { BranchesService } from "src/core/services/branches/branches.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private store: Store<AppState>,
    public router: Router,
    public BranchesSerive: BranchesService
  ) {}

  tabStatus = "justified";

  public isCollapsed = false;
  totMonthSales$: Observable<any>;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: "dark", // two possible values: light, dark
    dir: "rtl", // two possible values: ltr, rtl
    layout: "vertical", // fixed value. shouldn't be changed.
    sidebartype: "full", // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: "fixed", // two possible values: fixed, absolute
    headerpos: "fixed", // two possible values: fixed, absolute
    boxed: "full", // two possible values: full, boxed
    navbarbg: "skin5", // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: "skin5", // six possible values: skin(1/2/3/4/5/6)
    logobg: "skin5", // six possible values: skin(1/2/3/4/5/6)
  };
  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  // Check metwork connection (retrun true or false)
  checkNetwork$ = merge(
    fromEvent(window, "offline").pipe(map(() => false)),
    fromEvent(window, "online").pipe(map(() => true)),
    new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
    })
  );

  swalDialog() {
    if (!navigator.onLine) {
      Swal.fire({
        title: "disconnected",
        text: "حدث مشكلة في اتصال الإنترنت",
        icon: "error",
        didClose: () => {
          this.swalDialog();
        },
        confirmButtonColor: "#0162e8",
      });
    }
  }
  async ngOnInit() {
    this.checkNetwork$.subscribe((isConnected) => {
      if (!isConnected) this.swalDialog();
      else Swal.close();
    });

    if (this.router.url === "/") {
      this.router.navigate(["/dashboard/dashboard1"]);
    }
    if (localStorage.getItem("options")) {
      this.options = JSON.parse(localStorage.getItem("options"));
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
      case "iconbar":
        if (this.innerWidth < 1170) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case "overlay":
        if (this.innerWidth < 767) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case "full":
      case "iconbar":
        this.options.sidebartype = "mini-sidebar";
        break;

      case "overlay":
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case "mini-sidebar":
        if (this.defaultSidebar === "mini-sidebar") {
          this.options.sidebartype = "full";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  showSetting() {
    this.showSettings = !this.showSettings;
    if (this.showSettings == false) {
      localStorage.setItem("options", JSON.stringify(this.options));
    }
  }

  tabs_clicked_yay(data: boolean, el: any) {
    if (data) {
      el.click();
    }
  }
}
