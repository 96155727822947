<p-table
  [value]="data"
  [rows]="10"
  [globalFilterFields]="['name']"
  responsiveLayout="scroll"
  [rowHover]="true"
  dataKey="id"
>
  <!-- HEADER -->
  <ng-template pTemplate="header">
    <tr>
      <th>#</th>
      <th [pSortableColumn]="value.key" *ngFor="let value of configuration">
        {{ value.title }} <p-sortIcon [field]="value.key"></p-sortIcon>
      </th>
      <th *ngIf="actions">
        <div class="w-100 d-flex justify-content-center">Actions</div>
      </th>
    </tr>
  </ng-template>
  <!-- BODY -->
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{ data.indexOf(item) + 1 }}</td>
      <td *ngFor="let config of configuration; let i = index">
        <ng-container *ngIf="config.type == 'text'">
          {{ item[config.key] | round }}
        </ng-container>
        <ng-container *ngIf="config.type == 'date'">
          {{ item[config.key] | date : "short" }}
        </ng-container>
        <ng-container *ngIf="config.type == 'image'">
          <img [src]="URL + '/' + item[config.key]" width="65" />
        </ng-container>
      </td>

      <td *ngIf="actions">
        <div class="d-flex justify-content-center">
          <button
            *ngIf="actions?.delete"
            nz-popconfirm
            nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
            nzPopconfirmPlacement="top"
            (nzOnConfirm)="handleAction('delete', item.id)"
            class="btn btn-danger"
          >
            <span class="control-buttons pi-trash"></span>
          </button>
          <button
            *ngIf="actions?.edit"
            (click)="handleAction('edit', item.id)"
            class="btn btn-success"
          >
            <span class="control-buttons pi-pencil"></span>
          </button>
          <button
            *ngIf="actions?.print"
            (click)="handleAction('print', item.id)"
            class="btn btn-info"
          >
            <span class="control-buttons pi-print"></span>
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
