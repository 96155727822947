<p-fileUpload
  [customUpload]="true"
  (uploadHandler)="uploadBranchesPhotos($event)"
  [files]="files"
  multiple="multiple"
  accept="image/*"
  maxFileSize="1000000"
  [chooseLabel]="'اختر'"
  [uploadLabel]="'ارفع'"
  [cancelLabel]="'الغاء'"
  [fileLimit]="10"
>
  <!-- [disabled]="branchesPhotos.length > 10" -->
  <ng-template pTemplate="content">
    <div class="d-flex gap-2 w-100 flex-wrap">
      <div class="" *ngFor="let branchesPhoto of branchesPhotos">
        <button
          class="close"
          nz-popconfirm
          nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
          nzPopconfirmPlacement="top"
          (nzOnConfirm)="deleteImg(branchesPhoto.id)"
        >
          <span class="text-danger">&times;</span>
        </button>
        <p-image
          [src]="baseUrl + '/' + branchesPhoto.photo"
          alt=""
          width="100"
          [preview]="true"
          appendTo="body"
        >
        </p-image>
      </div>
    </div>
  </ng-template>
</p-fileUpload>
