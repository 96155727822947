import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RenderPipe } from "src/core/pipes/render.pipe";
import { RoundPipe } from "src/core/pipes/round.pipe";
import { ItemStoreTypesPipe } from "../pipes/itemStoreTypes.pipe";
import { OrderTypePipe } from "../pipes/orderType.pipe";
import { DishsCountPipe } from "../pipes/dishsCount.pipe";

// all used pipes

@NgModule({
  declarations: [
    RenderPipe,
    RoundPipe,
    ItemStoreTypesPipe,
    OrderTypePipe,
    DishsCountPipe,
  ],
  imports: [CommonModule],
  exports: [
    RenderPipe,
    RoundPipe,
    ItemStoreTypesPipe,
    OrderTypePipe,
    DishsCountPipe,
  ],
})
export class PipesModule {}
