import { UsersService } from "src/core/services/users/users.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { NzModalRef } from "ng-zorro-antd";
import { UserGroup } from "src/core/enums/user.group.enum";
import { SharedService } from "src/core/shared/shared.service";
import { BranchesService } from "src/core/services/branches/branches.service";

@Component({
  selector: "app-users-form",
  templateUrl: "./users-form.component.html",
  styleUrls: ["./users-form.component.css"],
})
export class UsersFormComponent implements OnInit {
  form: FormGroup;
  users: any[] = [];
  user: any = null;
  id: any;
  isAdd: boolean = false;
  GroupId? = 0;
  BranchId? = 0;
  userGroup = UserGroup;

  isUser;
  isEmail;
  isMobile;

  isUserUsed = false;
  isEmailUsed = false;
  isMobileUsed = false;

  isUsed: any = { email: false, mobile: false, username: false };
  branches: any;
  isRestaurantPage: boolean;

  constructor(
    private fb: FormBuilder,
    private service: UsersService,
    private modal: NzModalRef,
    private navigate: Router,
    private generic: GenericService,
    private router: ActivatedRoute,
    private sharedService: SharedService,
    private branchesService: BranchesService
  ) {}

  ngOnInit() {
    if (this.navigate.url.includes("restuarantDetails")) {
      this.isRestaurantPage = true;
    }

    this.createForm();
    this.getUsers();
    this.getBranches(JSON.parse(localStorage.getItem("user")).restaurants.id);

    this.user = localStorage.getItem("item");

    if (this.user) {
      const user = JSON.parse(this.user);
      this.id = user.id;
      console.log(user, "user");

      this.form.reset(user);
    }
  }
  UserObject(newName: string) {
    const user = JSON.parse(newName);
    this.id = user.id;
    this.form.reset(user);
    this.isAdd = false;
    this.user = user;
  }

  createForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, Validators.required],
      user_group_id: [this.isRestaurantPage ? 4 : null, Validators.required],
      username: [null, Validators.required],
      password: ["codesroots@123"],
      name: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      position: [null, Validators.required],
      code: [this.sharedService.generateCode(10)],
      vendor_id: [JSON.parse(localStorage["user"]).restaurants.id],
      branch_id: [null],
      active:[1]
    });
  }

  async getUsers() {
    const users = await this.service.getUserGroups();
    this.users = users as any;
  }

  async addUser() {
    if (this.form.valid) {
      this.isAdd = true;
      let values = this.form.value;
      values.username = values.username + "@codesroots.com";
      if (this.isRestaurantPage) {
        values.branch_id = this.BranchId;
        values.user_group_id = this.GroupId;
      }
      const data = await this.service.addUser(values);
      this.isAdd = false;
      this.generic.showNotification("success", "اضافة", "تمت العملية بنجاح");
      if (!this.navigate.url.includes("restuarantDetails")) {
        this.navigate.navigateByUrl("/users/all");
      } else {
        this.modal.close();
      }
      this.form.reset();
    }
  }

  async editUser() {
    if (this.form.valid) {
      const form = this.form.getRawValue();
      delete form.password;
      const data = await this.service.editUser(form, this.id);
      this.isAdd = false;
      this.generic.showNotification("success", "تعديل", "تمت العملية بنجاح");
      if (!this.navigate.url.includes("restuarantDetails")) {
        this.navigate.navigateByUrl("/users/all");
      } else {
        this.modal.close();
      }
    }
  }

  async validateUniq(value, type) {
    console.log(value);

    if (value) {
      // if (type == "email") this.isEmail = true;
      // if (type == "username") this.isUser = true;
      // if (type == "mobile") this.isMobile = true;

      this.service
        .userSearch({
          username: type == "username" ? value + "@codesroots.com" : value,
        })
        .then((res) => {
          if (res.length) {
            // if (type == "email") this.isEmailUsed = true;
            // if (type == "username") this.isUserUsed = true;
            // if (type == "mobile") this.isMobileUsed = true;
            this.isUsed[type] = true;
          } else {
            // if (type == "email") this.isEmailUsed = false;
            // if (type == "username") this.isUserUsed = false;
            // if (type == "mobile") this.isMobileUsed = false;
            this.isUsed[type] = false;
          }
          // if (type == "email") this.isEmail = false;
          // if (type == "username") this.isUser = false;
          // if (type == "mobile") this.isMobile = false;
        });
    } else {
      // if (type == "email") this.isEmail = false;
      // if (type == "mobile") this.isMobile = false;
      // if (type == "username") this.isUser = false;
      this.isUsed[type] = false;
    }
  }
  async getBranches(id: number) {
    this.branches = await this.branchesService.getRestaurant(id).toPromise();
  }
  ngOnDestroy(): void {
    localStorage.removeItem("item");
  }
}
