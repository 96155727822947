import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { restaurant_cuisines } from "src/core/services/restaurants/RestaurantModel";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { Location } from "@angular/common";
import { NzModalRef } from "ng-zorro-antd/modal";

@Component({
  selector: "app-addCuisines",
  templateUrl: "./AddCuisines.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddCuisines implements OnInit {
  restaurant_cuisines = new restaurant_cuisines();
  zonesList: restaurant_cuisines[];
  resId;
  id;
  loading;
  constructor(
    private modal: NzModalRef,
    private service: RestaurantsService,
    private location: Location,
    private navigate: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    const data = await this.service.getCuisines();
    this.zonesList = data;
  }
  async add() {
    console.log(this.restaurant_cuisines);
    this.restaurant_cuisines.vendor_id = parseInt(this.resId);
    const data = await this.service.addRestCuisines({
      data: this.restaurant_cuisines,
    });
    this.modal.close();
  }
}
