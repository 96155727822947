<div class="row">
  <div class="mt-3 col-md-4" *ngFor="let input of inputs; let i = index">
    <div class="w-100" [ngSwitch]="input.type">
      <input
        [(ngModel)]="outputObj[input.name]"
        class="w-100"
        pInputText
        *ngSwitchCase="'text'"
        [type]="input.type"
        [id]="input.name"
        [placeholder]="input.placeholder"
      />
      <p-inputNumber
        [(ngModel)]="outputObj[input.name]"
        class="w-100"
        pInputNumber
        *ngSwitchCase="'number'"
        [type]="input.type"
        [id]="input.name"
        [placeholder]="input.placeholder"
      ></p-inputNumber>
      <input
        [(ngModel)]="outputObj[input.name]"
        class="w-100"
        pInputText
        *ngSwitchCase="'tel'"
        [type]="input.type"
        [id]="input.name"
        [placeholder]="input.placeholder"
      />
      <!-- <p-dropdown [(ngModel)]="outputObj[input.name]" class="w-100" *ngSwitchCase="'dropdown'" [id]="input.name" [placeholder]="input.placeholder" [options]="input.options" [optionLabel]="input.label" [optionValue]="input.value" showClear="true"></p-dropdown> -->

      <p-dropdown
        *ngSwitchCase="'dropdown'"
        [id]="input.name"
        class="w-100"
        [(ngModel)]="outputObj[input.name]"
        (onChange)="emitDropDownValue($event, input.name)"
        [placeholder]="input.placeholder"
        [options]="input.options"
        [optionLabel]="input.label"
        [optionValue]="input.value"
        [disabled]="input.disabled"
        [showClear]="!input.hideClear"
        [filter]="true"
        [filterBy]="input.label"
        [autoDisplayFirst]="input.autoDisplayFirst"
      >
        <ng-template let-item pTemplate="item">
          <div>{{ item[input.label] }}</div>
        </ng-template>
      </p-dropdown>

      <p-multiSelect
        *ngSwitchCase="'multiSelect'"
        [id]="input.name"
        styleClass="w-100"
        [name]="input.name"
        ngDefaultControl
        [(ngModel)]="outputObj[input.name]"
        (onChange)="emitDropDownValue($event, input.name)"
        [placeholder]="input.placeholder"
        [options]="input.options"
        [optionLabel]="input.label"
        [optionValue]="input.value"
        [showClear]="!input.hideClear"
        [filter]="true"
        [filte`rBy]="input.label"
      >
        <ng-template let-item pTemplate="item">
          <div>{{ item[input.label] }}</div>
        </ng-template>
      </p-multiSelect>

      <p-calendar
        [(ngModel)]="outputObj[input.name]"
        *ngSwitchCase="'date'"
        [id]="input.name"
        [placeholder]="input.placeholder"
        dateFormat="dd-mm-yy"
      ></p-calendar>
      <p-calendar
        [(ngModel)]="outputObj[input.name]"
        *ngSwitchCase="'month'"
        [id]="input.name"
        [placeholder]="input.placeholder"
        dateFormat="mm-yy"
        view="month"
      ></p-calendar>
      <p-autoComplete
        *ngSwitchCase="'search'"
        [(ngModel)]="outputObj[input.name]"
        (ngModelChange)="emitDropDownValue($event, input.name)"
        [suggestions]="input.options"
        [field]="input.field"
        placeholder="{{ input.placeholder }}"
        [ngModelOptions]="{ standalone: true }"
        styleClass="w-100"
      >
      </p-autoComplete>
    </div>
  </div>
</div>
<div class="row d-flex m-auto">
  <div class="col-md-4 mt-3 d-flex align-items-end">
    <button class="w-100 btn btn-success p-2" (click)="emitFilterData()">
      تصفية
    </button>
  </div>
  <div class="col-md-4 mt-3 d-flex align-items-end">
    <button class="w-100 btn btn-secondary p-2" (click)="resetData()">
      إعادة ضبط
    </button>
  </div>
  <div class="col-md-4 mt-3 d-flex align-items-end">
    <button
      class="w-100 btn btn-info p-2"
      (click)="shareFilter()"
    >
      مشاركة التصفية
    </button>
  </div>
</div>
