import { gql } from "apollo-angular";

export const USERS_DATA_QUERY = `
    id
    name
    first_name
    last_name
    username
    mobile
    email
    position
    vendor_id
    branch_id
    user_group_id
    code
    created
    group {
      id
      name
    }
`;

export const USERS__QUERY = gql`
  {
    users {
      id
      name
      username
    }
  }
`;

export const USERS_DATA_DROPDOWN_QUERY = `
    id
    name
    username
`;

export const USERS_DELETE_QUERY = gql`
  {
    users(Function: "Delete") {
      id
    }
  }
`;
export const USER_ADD_QUERY = gql`
  {
    users(Function: "Add") {
      id
      name
      username
      mobile
      email
    }
  }
`;
export const USER_EDIT_QUERY = gql`
{
  users(Function: "Edit") {
    id
    name
    username
    mobile
    email
  }
}
`;
