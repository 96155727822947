import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ITableActions } from './Interfaces/TableActions';
import { ITableConfiguration } from './Interfaces/TableConfiguration';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit {

  @Input() data: any;
  @Input() actions: ITableActions;
  @Input() configuration: ITableConfiguration;
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() edit: EventEmitter<number> = new EventEmitter<number>();
  @Output() print: EventEmitter<number> = new EventEmitter<number>();
  URL = environment.photoPath;
  constructor() { }


  ngOnInit(): void {}

  handleAction(type: string, id: number) {
    switch (type) {
      case 'delete':
        this.delete.emit(id);
        break;
      case 'edit':
        this.edit.emit(id);
        break;
      case 'print':
        this.print.emit(id);
        break;
    }
  }

}
