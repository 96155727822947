<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="form" style="width: 100%">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="username"> الاسم </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    formControlName="name"
                    id="name"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="first_name"> الاسم الأول </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    formControlName="first_name"
                    id="first_name"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="last_name"> الاسم الأخير </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    formControlName="last_name"
                    id="last_name"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="mobile"> رقم الهاتف </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    (blur)="validateUniq(form.get('mobile').value, 'mobile')"
                    formControlName="mobile"
                    id="mobile"
                  />
                </div>
                <span
                  *ngIf="isUsed.mobile && form.get('mobile').value"
                  class="s-float"
                >
                  هذا الرقم مستخدم من قبل
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="email"> البريد الالكتروني </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    (blur)="validateUniq(form.get('email').value, 'email')"
                    formControlName="email"
                    id="email"
                  />
                </div>
                <span
                  *ngIf="isUsed.email && form.get('email').value"
                  class="s-float"
                >
                  هذا الإيميل مستخدم من قبل
                </span>
                <span
                  *ngIf="
                    form.controls['email'].invalid && form.get('email').value
                  "
                  class="s-float"
                >
                  يرجى كتابه الايميل بطريقة صحيحة
                </span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="username"> اسم المستخدم </label>
                </nz-form-label>

                <div
                  class="input-group-lg flex"
                  style="flex-direction: row-reverse"
                >
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    (blur)="
                      validateUniq(form.get('username').value, 'username')
                    "
                    formControlName="username"
                    id="username"
                  />

                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    style="direction: ltr"
                    *ngIf="!user"
                    >@codesroots.com</span
                  >
                </div>

                <span
                  *ngIf="isUsed.username && form.get('username').value"
                  class="s-float"
                >
                  هذا الاسم مستخدم
                </span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="username"> كلمة المرور </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    disabled
                    id="password"
                    value="codesroots@123"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="!isRestaurantPage">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="title"> مجموعة المستخدم </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <nz-select formControlName="user_group_id">
                    <nz-option
                      *ngFor="let user of users"
                      [nzLabel]="user.name"
                      [nzValue]="user.id"
                    ></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>
            <div class="form-group mb-3 col-md-6">
              <nz-form-label nzRequired>
                <label for="address"> المسمي الوظيفي </label>
              </nz-form-label>
              <div class="input-group-lg">
                <input
                  class="form-control"
                  nz-input
                  type="text"
                  formControlName="position"
                  id="address"
                />
              </div>
            </div>
          </div>

          <div
            class="col-md-6 d-flex flex-column mb-2"
            *ngIf="
              form.get('user_group_id').value == 37 ||
              form.get('user_group_id').value == 38
            "
          >
            <label for="branch_id"> الفرع </label>
            <p-dropdown
              [showClear]="true"
              [autoDisplayFirst]="false"
              [options]="branches"
              formControlName="branch_id"
              optionLabel="name"
              optionValue="id"
              styleClass="w-100"
            ></p-dropdown>
          </div>

          <div class="col-md-2">
            <button
              class="btn btn-block"
              [class.btn-info]="!user"
              [class.btn-success]="user"
              matRipple
              nz-button
              [nzSize]="'large'"
              [disabled]="form.invalid"
              (click)="user ? editUser() : addUser()"
            >
              <i nz-icon nzType="loading" nzTheme="outline" *ngIf="isAdd"></i>
              {{ !user ? "اضافة" : "تعديل" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
