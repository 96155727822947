export const ar = {
  loginFormTxt: "تسجيل الدخول",
  forgetPwpTxt: "هل نسيت كلمة المرور؟",
  lblUsername: "اسم المستخدم",
  usernameRequired: "بالرجاء كتابة اسم المستخدم!",
  lblPwp: "كلمة المرور",
  loginTitleTxt: "تسجيل الدخول",
  loginSuccessTxt: "تم تسجيل الدخول بنجاح",
  loginFailTxt: "تعذرت عملية تسجيل الدخول بالرجاء المحاولة مرة اخرى",
  totMonthSales: "اجمالي المبيعات للشهر",
  totWeekSales: "اجمالي المبيعات للاسبوع",
  monthSalesTxt: "مبيعات الشهر",
  weekSalesTxt: "مبيعات الاسبوع",
  monthSalesDetailsTxt: "بيع الاقسام الشهرية",
  weekSalesDetailsTxt: "بيع الاقسام الاسبوعية",
  mostSellingTxt: "الاكثر مبيعا",
  itemRecipesTxt: "المكونات",
  marketersTxt: "اجمالي المسوقين",
  platFormsTxt: "اجمالي المنصات",
  paymentsTxt: "طرق الدفع",
  moneyFlowTxt: "الايرادات و المصروفات",
  expensesTxt: "مجموع المصروفات",
  expensesChartTxt: "توزيع المصروفات الاسبوعية",
  expensesChartMonthTxt: "توزيع المصروفات الشهرية",
  add: "اضافة",
  success: "تمت العملية بنجاح",
  fail: "فشلت العملية",
  failTxt: "فشلت العملية بالرجاء المحاولة مره اخرى",
  delteTitle: "حذف",
  delteMsg: "تمت عملية الحذف بنجاح",
  addNewTitle: "اضافة",
  addNewMsg: "تمت الاضافة بنجاح",
  validateerror: "اكمل جميع البيانات الفارغة",

  updateTitle: "تحديث",
  updateMsg: "تمت التحديث بنجاح",
  orderCount: "عدد الطلبات للشهر",
  orderCountWeek: "عدد الطلبات لللاسبوع",
  allorderCount: "عدد الطلبات التي تم توصيلها",
  ordertotal: "اجمالي الطلبات التي تم توصيلها",
};
