import { CategoriesModel } from "src/core/models/MenuModel";
import { Areas, MenuItems } from "../cities/citiesModel";

export interface PaginationDataInterFace<T> {
  results: T[];
  total: number;
}

export class Restuarant {
  id: number;
  name: string;
  name_en: string;
  logo: string;
  cover: string;
  minimum: number;
  delivery_time: number;
  taxes: number;
  category_id: number;
  restaurants_types_id: number;
  has_takeway: number;
  restaurants_status_id: number;
  sales_id: number;
  created: Date;
  modified: Date;
  restaurant_attachments: [any];
  navigationId: number;
  license_number: number;
  taxes_number: number;
  expr_taxescarr: string;
  expr_liences: string;
  branches: [branches];
  percentage: number;
  cuisines: [MenuItems];
  Sales_Employee: Users;
  RestaurantStatus: RestaurantStatus;
  RestaurantTypes: RestaurantStatus;
  category: CategoriesModel;
  restaurant_approval_assginemt: RestaurantStatus;
  discount_from: string;
  discount_to: string;
  user: Users;
  refuse_approve_reasons_id: number;
  restaurant_general_status_id: number;
  archived: number;
  refuseReason: refuseReason;
  Department: Department;
  discount_percentage: number;
  comment: string;
  backlog_id: number;
  activated: number;
  departments_id: number;
  content_id: number;
  personal_card: string;
  personal_card_exp: string;
  user_id: number;
  code: string;
}

export class Users {
  id?: number;
  username: string;
  password: string;
  name: string;
  email: string;
  mobile: string;
  position: string;
  code: string;
  vendor_id : number;
}
export class branches {
  id: number;
  name: String;
  name_en: String;
  address_en: String;
  address: String;
  phone: String;
  phone_two: String;
  latitude: number;
  code: string;
  longitude: number;
  category_id: number;
  vendor_id: number;
  status_id;
  user: Users;
  expand: boolean;
  navigationId: number;
}
export class working_times {
  id: number;
  branchId: number;
  day: String;
  open_time: String;
  close_time: String;
  day_en: String;
  isActive: number;
}

export class restaurant_areas {
  id?: number;
  area_id?: number;
  town_id?: number;
  city_id?: number;
  branch_id: number;
  delivery_cost: number;
  delivery_time: number;
  min_order: number;
  prepare_time: number;
}

export class restaurant_cuisines {
  cuisine_id: number[];
  vendor_id: number;
  cuisines: MenuItems;
}
export class RestaurantStatus {
  name: string;
  restaurant_id: number;
}
export class Department {
  name: string;
  restaurant_id: number;
  sort;
}
export class refuseReason {
  name: string;
  restaurant_id: number;
  sort;
}
