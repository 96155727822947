import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { BehaviorSubject } from "rxjs";
import { options } from "src/app/options";
import { environment } from "src/environments/environment";
import { TownsCreate } from "../cities/citiesModel";
import { restaurant_cuisines, Restuarant } from "./RestaurantModel";

@Injectable({
  providedIn: "root",
})
export class RestaurantsService {
  MenuDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  data: string = `
        id
        cover
        logo
        name
        name_en
        created
        modified
        restaurant_general_status_id
        category_id
        Account_Manager {
          id
        }
        RestaurantStatus {
          id
        }
        archived
        user_id
        Content_Employee {
          id
          username
        }
        RestaurantTypes {
          id
          name
          percentage_discount
          created_by
          modified_by
        }
        category {
          name
        }
        refuseReason {
          name
        }
        Department {
          name
          sort
        }
        restaurant_attachments {
          created_by
          id
          modified_by
          photo
          vendor_id
          type
        }
        `;

  constructor(private http: HttpClient, private apollo: Apollo) {}

  getRestaurant() {
    return this.http
      .get<Array<Restuarant>>(
        `${environment.endpoint}/Restaurant/getRestaurant`
      )
      .toPromise();
  }
  indexForAccountManager() {
    return this.http
      .get<Array<Restuarant>>(
        `${environment.endpoint}/RestaurantAdsSpace/indexForAccountManager`
      )
      .toPromise();
  }
  indexForDepartmentApprove() {
    return this.http
      .get<Array<Restuarant>>(
        `${environment.endpoint}/RestaurantAdsSpace/indexForDepartmentApprove`
      )
      .toPromise();
  }

  editApprove(obj, id) {
    return this.http
      .post(`${environment.endpoint}/RestaurantAdsSpace/edit/${id}`, obj)
      .toPromise();
  }

  addForAccountManager(obj) {
    return this.http
      .post(`${environment.endpoint}/RestaurantAdsSpace/add`, obj)
      .toPromise();
  }
  getRestaurantByDepartment(isManger: any = {}) {
    return this.http
      .post<Array<Restuarant>>(
        `${environment.endpoint}/Restaurant/getRestaurantByDepartments`,
        isManger
      )
      .toPromise();
  }
  getMyRestaurant(id) {
    return this.http
      .get<Restuarant>(
        `${environment.endpoint}/Restaurant/getRestaurantByUser/${id}`
      )
      .toPromise();
  }

  getRestaurantStatus() {
    return this.http
      .get<Restuarant>(`${environment.endpoint}/RestaurantStatus/index`)
      .toPromise();
  }

  getRestaurantTypes() {
    return this.http
      .get<Restuarant>(`${environment.endpoint}/RestaurantTypes/index`)
      .toPromise();
  }

  UpdateAttchment(id, FormData) {
    return this.http
      .post<Restuarant>(
        `${environment.endpoint}/RestaurantAttachment/add/${id}`,
        FormData
      )
      .toPromise();
  }

  getCuisines() {
    return this.http
      .get<Array<restaurant_cuisines>>(
        `${environment.endpoint}/Restaurant/getCusinesList/0`
      )
      .toPromise();
  }

  add(data: any) {
    return this.http
      .post<any>(
        `${environment.endpoint}/Restaurant/addRestaurants`,
        data,
        options
      )
      .toPromise();
  }

  edit(obj, id) {
    return this.http
      .post(`${environment.endpoint}/Restaurant/edit/${id}`, obj, options)
      .toPromise();
  }

  delete(data: any) {
    return this.http
      .post(`${environment.endpoint}/Restaurant/deleteRestaurants`, {
        id: data,
      })
      .toPromise();
  }

  filter(obj, params = {}) {
    return this.http
      .post<Array<Restuarant>>(
        `${environment.endpoint}/Restaurant/FilterRestaurant`,
        obj,
        { params }
      )
      .toPromise();
  }

  addRestCuisines(data: any) {
    return this.http
      .post(
        `${environment.endpoint}/Restaurant/addCusinesRestaurants`,
        data,
        options
      )
      .toPromise();
  }

  getRestCuisines(data: any) {
    return this.http
      .get<Array<restaurant_cuisines>>(
        `${environment.endpoint}/Restaurant/getRestaurantCuisines/${data}`
      )
      .toPromise();
  }

  DeletRestCuisines(id: any) {
    return this.http
      .get(`${environment.endpoint}/Restaurant/deleteRestaurantCuisines/${id}`)
      .toPromise();
  }

  RestaurantGeneralStatus() {
    return this.http
      .get<any[]>(`${environment.endpoint}/RestaurantGeneralStatus/index`)
      .toPromise();
  }

  FilterRestaurant(filter, params = {}) {
    return this.http
      .post(`${environment.endpoint}/Restaurant/FilterRestaurant`, filter, {
        params,
      })
      .toPromise();
  }

  getRestaurantByContent(filter) {
    return this.http
      .post(`${environment.endpoint}/Restaurant/getRestaurantByContent`, filter)
      .toPromise();
  }

  getRestaurantByDepartments(isManger: any = {}) {
    // return this.http.post(`${environment.endpoint}/Restaurant/getRestaurantByDepartments` , filter).toPromise();
    return this.http
      .post(
        `${environment.endpoint}/Restaurant/getRestaurantByDepartments`,
        isManger
      )
      .toPromise();
  }

  GetRefuseReasons(department_id) {
    return this.http
      .post<any[]>(
        `${environment.endpoint}/RefuseApproveReasons/FilterDepratment`,
        { department_id: department_id }
      )
      .toPromise();
  }
  SetMenuDisabled(val: boolean) {
    this.MenuDisabled.next(val);
  }

  getMenuDisabled() {
    return this.MenuDisabled.asObservable();
  }

  getMenuItemsByResturant(id) {
    return this.http
      .get(`${environment.endpoint}/Menu/getMenuItemsByResturant/${id}`)
      .toPromise();
  }

  getReport(search?) {
    return this.http
      .post(`${environment.endpoint}/Restaurant/getVendorReporForAdmin`, search)
      .toPromise();
  }

  CheckIfAddedMenu(RestaurantId?) {
    return this.http
      .get<boolean>(
        `${environment.endpoint}/MenuItem/GetItemsForRestaurants/${RestaurantId}`
      )
      .toPromise();
  }

  getRestaurantDetails(id: string | number) {
    return this.http.get(`${environment.endpoint}/Restaurant/view/${id}`);
  }

  getRestaurantsWithGraphQL(filter: string, page: number, limit: number) {
    return this.apollo.watchQuery({
      query: gql`
    { 
      restaurants (pages:[${page + "," + limit}]${filter ? "," + filter : ""}) {
        ${this.data}
      }
    }`,
    }).valueChanges;
  }
}
