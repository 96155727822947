import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RestaurantAttachmentService {

  constructor(private http: HttpClient) { }

  restaurantAttachment(id: any) {
    return this.http.get(`${environment.endpoint}/RestaurantAttachment/index/${id}`).toPromise();
  }

  downloadRestaurantAttachment(id: any) {
    return this.http.get<any>(`${environment.endpoint}/RestaurantAttachment/downloadAttachmens/${id}`).pipe(map(d=>d.data as string)).toPromise();
  }

  add(data: any) {
    return this.http.post(`${environment.endpoint}/RestaurantAttachment/add`, data,{
      reportProgress: true,
      observe: 'events'
    })
  }
  delete(data: any) {
    return this.http.get(`${environment.endpoint}/RestaurantAttachment/delete/${data}`).toPromise();
  }

}
