import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { gql } from "apollo-angular";
import { pairwise, startWith } from "rxjs/operators";
import { CancelReasonsService } from "src/core/services/cancel-resasons/cancel-reasons.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { OrdersService } from "src/core/services/orders/orders.service";

@Component({
  selector: "app-order-form-main-info",
  templateUrl: "./order-form-main-info.component.html",
  styleUrls: ["./order-form-main-info.component.scss"],
})
export class OrderFormMainInfoComponent implements OnInit {
  @Input("orderForm") orderForm: FormGroup;
  @Input("branches") branches: any;
  @Input("towns") towns: any;
  @Input("paymentTypes") paymentTypes: any;
  @Input("orderTypes") orderTypes: any;
  @Input("orderStatus") orderStatus: any;
  @Input("isOrderEdit") isOrderEdit: any;

  tables: any;
  vendor_id: any = JSON.parse(localStorage.getItem("user"))?.restaurants?.id;
  waiters: any;
  deliveries: any;
  showCancelBuutton: boolean = false;
  cancelReasons: any;

  constructor(
    private graphQLService: GraphQLService,
    private CancelReasonsService: CancelReasonsService
  ) {}

  ngOnInit(): void {
    this.orderFormValuesChages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["orderForm"]) console.log(changes);
  }

  orderFormValuesChages() {
    this.orderForm.valueChanges
      .pipe(startWith(null as string), pairwise())
      .subscribe({
        next: ([prev, cuurent]) => {
 
          if (prev?.branch_id != cuurent?.branch_id) {
            this.getWaiters(cuurent?.branch_id);
            this.getTables(cuurent?.branch_id);
            this.getDeliveries();
          }
        },
      });
  }

  getTables(branch_id): void {
    this.graphQLService
      .query(
        gql`
    {
      restables (Function: "tablesStatis",branch_idEq:${branch_id}){
        id
        waiterId
        isAvail
        number
        branch_id
        position
      }
    }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.tables = response.data.restables;
        },
        error: (error) => {},
      });
  }
  getCanceleReasons(): void {
    this.CancelReasonsService.Get().subscribe({
      next: (response: any) => {
        this.cancelReasons = response.data.cancelReasons;
      },
      error: (error) => {},
    });
  }

  getWaiters(branch_id: number) {
    this.graphQLService
      .query(
        gql`
          {
            users(
              vendor_idEq: ${this.vendor_id}
              branch_idEq : ${branch_id}
              user_group_idEq:37) {
              id
              name
            }
          }
        `
      )
      .subscribe({
        next: (Response: any) => {
          this.waiters = Response.data.users;
        },
      });
  }

  getDeliveries(): void {
    this.graphQLService
      .query(
        gql`
      {
        delivers(branch_idEq:${this.orderForm.controls["branch_id"].value}){
          id
          name
        }
      }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.deliveries = response.data.delivers;
        },
        error: (error) => {},
      });
  }
  handleOrderStatusChange(status: any) {
    if ([5, 7, 8].includes(status)) {
      this.getCanceleReasons();
      this.showCancelBuutton = true;
    }
  }
}
