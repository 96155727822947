export class validations {

   constructor(document:Document){
   

}

vlidate(){
    const validInputs = Array.from(document.querySelectorAll('input')).filter( input => input.attributes.getNamedItem("required") && input.value === "" );
    const validUniq = Array.from(document.querySelectorAll('.used'))
    if (validInputs.length == 0 && validUniq.length == 0) {

    return true;
       } else {
           return false;
       }
}
}
