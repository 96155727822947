<div class="container-fluid">
  <div class="row">
    <div class="col-4 table-hover">
      <a
        (click)="openModal()"
        matRipple
        matRippleColor="rgba(0,0,0,.2) "
        matRippleCentered="centered"
        nz-button
        nzType="default"
        [nzLoading]="isLoadingTwo"
      >
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        تغيير تخصصات للمطعم
      </a>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="table-responsive card custom-br custom-shadow">
          <nz-table
            [nzFrontPagination]="false"
            [nzData]="dataarray"
            [nzLoading]="loading"
            [nzPageSize]="20"
            [nzPageIndex]="1"
            [nzScroll]="{ x: '240px' }"
            (nzPageIndexChange)="onPageChange($event)"
            class="table forms-table table-striped"
          >
            <thead>
              <tr class="bg-light">
                <th
                  style="color: white"
                  *ngFor="let head of heads; let i = index"
                >
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let allData of dataarray; let i = index"
                style="cursor: pointer; direction: ltr"
              >
                <td>{{ allData?.cuisines?.name }}</td>
                <td>{{ allData?.cuisines?.name_en }}</td>
                <!-- <td>
                  <button
                    nz-popconfirm
                    nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
                    nzPopconfirmPlacement="top"
                    (nzOnConfirm)="delete(allData.id, i)"
                    class="btn btn-danger"
                  >
                    حذف
                  </button>
                </td> -->
              </tr>
            </tbody>
          </nz-table>
          <!-- <nz-table [nzFrontPagination]="false" [nzData]="dataarray"></nz-table> -->
        </div>
      </div>
    </div>
  </div>
</div>
