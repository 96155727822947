<section class="restuarant-info">
  <div class="container-fluid">
    <div class="row">
      <!-- restuarant Data -->
      <div class="col-12 mb-8">
        <div class="card custom-br custom-shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 text-center">
                <div
                  class="restuarant-icon mx-auto py-3 custom-br"
                  [ngStyle]="
                    restuarant?.cover != ''
                      ? {
                          'background-image':
                            'url(' + baseUrl + '/' + restuarant?.cover + ')'
                        }
                      : { 'background-image': 'url(' + CoverSrc + ')' }
                  "
                >
                  <div style="position: relative">
                    <img
                      (click)="uploadLogo.click()"
                      [src]="
                        restuarant?.logo == ''
                          ? imageSrc
                          : baseUrl + '/' + restuarant?.logo
                      "
                      alt=""
                      class="img-fluid custom-shadow d-block"
                    />

                    <button
                      *ngIf="restuarant?.logo != '' || logo"
                      class="p-1 deleteLogotext text-danger"
                      (click)="deleteLogo()"
                    >
                      <i class="pi pi-trash"></i>
                    </button>
                  </div>

                  <button
                    class="p-1 deleteCovertext text-danger"
                    (click)="deleteCover()"
                  >
                    <i class="pi pi-trash"></i>
                  </button>

                  <button
                    class="p-1 uploadCovertext text-danger"
                    (click)="uploadCoverInput.click()"
                  >
                    <i class="pi pi-pencil"></i>
                  </button>
                </div>
                <input
                  hidden
                  accept="image/*,.xlsx,.xls"
                  type="file"
                  #uploadLogo
                  (change)="uploadLogoFile($event, 'logo', 0)"
                />
                <input
                  hidden
                  accept="image/*,.xlsx,.xls"
                  type="file"
                  #uploadCoverInput
                  (change)="uploadLogoFile($event, 'cover', 0)"
                />
                <div class="restuarant-name">
                  <h6>
                    <a href="" class="text-dark font-weight-bold">
                      {{ restuarant?.name }}({{ restuarant?.name_en }})
                    </a>
                  </h6>
                  <p class="text-secondary fs-6">
                    <span *ngFor="let c of restuarant?.cuisines; let i = index">
                      {{ c.name }}
                      <span
                        *ngIf="i != restuarant?.cuisines.length - 1"
                        style="margin: 5px"
                        >-</span
                      >
                    </span>
                  </p>
                </div>
                <div class="restuarant-attendance">
                  <div class="row">
                    <button
                      *ngIf="savelogoOrCover"
                      (click)="EditRestaurant()"
                      class="btn btn-primary col-4 mx-auto"
                    >
                      حفظ
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-8" style="margin-bottom: 25px">
                <div class="restuarant-info">
                  <div class="row text-center text-nowrap">
                    <div class="col-lg-3">
                      <span class="label">القسم الرئيسي</span>
                      <p class="px-3 mb-0">
                        {{ restuarant?.category?.name }}
                      </p>
                    </div>
                    <div class="col-lg-3">
                      <span class="label">{{ "تاريخ الانضمام" }}</span>
                      <p class="px-3 mb-0">
                        {{ restuarant?.created | date : "yyyy-M-dd" }}
                      </p>
                    </div>
                    <div class="col-lg-3 px-3 mb-0">
                      <span class="label">الضريبة</span>
                      <p class="px-3 mb-0">
                        {{
                          restuarant?.taxes
                            ? restuarant?.taxes + "%"
                            : "لا يوجد"
                        }}
                      </p>
                    </div>
                    <div class="col-lg-3">
                      <span class="label">البطاقة الضريبية</span>
                      <p class="px-3 mb-0">
                        {{
                          restuarant?.taxes_number
                            ? restuarant?.taxes_number
                            : "لا يوجد"
                        }}
                      </p>
                    </div>
                    <div class="col-lg-3">
                      <span class="label">موظف المبيعات</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.Sales_Employee
                            ? restuarant?.Sales_Employee.username
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">انتهاء السجل التجاري</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.expr_liences
                            ? (restuarant?.expr_liences | date : "yyyy-M-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">انتهاء البطاقة الضريبية</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.expr_taxescarr
                            ? (restuarant?.expr_taxescarr | date : "yyyy-M-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">رقم السجل التجاري</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.license_number
                            ? restuarant?.license_number
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">حالة المطعم</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.RestaurantStatus
                            ? restuarant?.RestaurantStatus.name
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">فئة المطعم</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.RestaurantTypes
                            ? restuarant?.RestaurantTypes.name
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">إسم القسم</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.category?.name
                            ? restuarant?.category.name
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">فئة المتجر </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.RestaurantTypes?.name
                            ? restuarant?.RestaurantTypes.name
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">استلام من الفرع</span>

                      <p class="px-3 mb-0">
                        {{ restuarant?.has_takeway ? "يوجد" : "لا يوجد" }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">تاريخ انتهاء السجل التجاري </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.expr_liences
                            ? (restuarant?.expr_liences | date : "yyyy-m-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">البطاقة الضريبية </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.taxes_number
                            ? restuarant?.taxes_number
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">المالك</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.user?.name
                            ? restuarant?.user?.name
                            : "مجهول"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">البطاقة الشخصية </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.personal_card
                            ? restuarant?.personal_card
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">انتهاء البطاقة الشخصية </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.personal_card_exp
                            ? (restuarant?.personal_card_exp
                              | date : "yyyy-M-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">بداية الخصم </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.discount_from
                            ? (restuarant?.discount_from | date : "yyyy-M-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">نهاية الخصم </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.discount_to
                            ? (restuarant?.discount_to | date : "yyyy-M-dd")
                            : "لا يوجد"
                        }}
                      </p>
                    </div>

                    <div class="col-lg-3">
                      <span class="label">موظف المبيعات </span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.Sales_Employee?.name
                            ? restuarant?.Sales_Employee?.name
                            : "لا يوجد"
                        }}
                      </p>
                    </div>
                    <div class="col-lg-3">
                      <span class="label">كود المتجر</span>

                      <p class="px-3 mb-0">
                        {{ restuarant?.code ? restuarant?.code : "لا يوجد" }}
                      </p>
                    </div>

                    <div class="col-3">
                      <span class="label">نسبة الخصم</span>

                      <p class="px-3 mb-0">
                        {{
                          restuarant?.discount_percentage
                            ? restuarant?.discount_percentage
                            : "لا يوجد"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <button class="p-1 EditButton" (click)="openEditModal()">
              تعديل
            </button>
            <button class="p-1 EditButtonOnwer" (click)="editOwner()">
              مالك المتجر
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-12 m-auto BranchesList">
        <div class="card custom-br custom-shadow border-0">
          <div class="card-body">
            <div class="row" style="padding: 10px">
              <div class="col-md-6">
                <h6 class="overflow-hidden mb-3">
                  <span class="font-weight-bold">الفروع </span>
                </h6>
              </div>
              <div class="col-md-6 text-right">
                <button
                  matRipple
                  matRippleColor="rgba(0,0,0,.2)"
                  matRippleCentered="centered"
                  nz-button
                  nzType="default"
                  (click)="openAddBranchModal()"
                  [disabled]="this.adding"
                >
                  <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
                  اضافة
                </button>
              </div>
            </div>

            <nz-table #nzTable [nzShowPagination]="false" [nzData]="branches">
              <thead>
                <tr style="background: #00000029">
                  <th nzShowExpand></th>
                  <th>
                    <p class="text-nowrap">الاسم</p>
                  </th>
                  <th>
                    <p class="text-nowrap">اسم المستخدم</p>
                  </th>
                  <th>
                    <p class="text-nowrap">العنوان</p>
                  </th>
                  <th>
                    <p class="text-nowrap">رقم الهاتف</p>
                  </th>
                  <th>
                    <p class="text-nowrap">رقم هاتف اخر</p>
                  </th>
                  <th>
                    <p class="text-nowrap">رقم هاتف المسئول</p>
                  </th>
                  <th>
                    <p class="text-nowrap">حالة الفرع</p>
                  </th>
                  <th>
                    <p class="text-nowrap">تاريخ الانشاء</p>
                  </th>
                  <th *appPermessions="[userGroup.AccountManager]">
                    تفاصيل الطلبات
                  </th>
                  <th *appPermessions="[userGroup.Activate]">عمل اختبار</th>
                  <th *appPermessions="[userGroup.Activate]">تفعيل</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  ngFor
                  let-data
                  [ngForOf]="nzTable.data"
                  let-index="index"
                >
                  <tr>
                    <td
                      nzShowExpand
                      [(nzExpand)]="mapOfExpandData[data.id]"
                    ></td>
                    <td>{{ data.name }} ({{ data.name_en }})</td>
                    <td>{{ data?.user?.username }}</td>
                    <td>{{ data.address }}</td>
                    <td>{{ data.phone }}</td>
                    <td>{{ data.phone_two }}</td>
                    <td>{{ data.phone_two }}</td>
                    <td>{{ data?.branch_Status?.name }}</td>
                    <td>{{ data.created | date }}</td>
                    <td *ngIf="isAccountManager == 17">
                      <a
                        routerLink="/restuarantDetails/account-details/{{
                          data.id
                        }}"
                        class="btn btn-primary"
                        style="margin-left: 10px"
                      >
                        تفاصيل الطلبات
                      </a>
                    </td>

                    <!-- <ng-container *appPermessions="[userGroup.Activate]">
                      <td *ngIf="data.activated == 1">
                        <button
                          class="btn btn-success"
                          style="margin-left: 10px"
                        >
                          مفعل
                        </button>
                      </td>
                    </ng-container> -->
                    <!-- <ng-container *appPermessions="[userGroup.Activate]">
                      <td *ngIf="data.activated == 0">
                        <button
                          class="btn btn-primary"
                          style="margin-left: 10px"
                          (click)="openTest(data.id); selectedBranches = data"
                        >
                          اختيار طبق
                        </button>
                      </td>
                    </ng-container> -->
                    <!-- <ng-container *appPermessions="[userGroup.Activate]">
                      <td *ngIf="data.activated == 0">
                        <button
                          class="btn btn-success"
                          (click)="OpenActivationModalForBranch(data.id)"
                          style="margin-left: 10px"
                        >
                          تفعيل
                        </button>
                      </td>
                    </ng-container> -->
                  </tr>
                  <tr [nzExpand]="mapOfExpandData[data.id]">
                    <td>
                      <button
                        class="btn btn-info"
                        (click)="showBranchesPhotos(data.id)"
                      >
                        الصور
                      </button>
                    </td>

                    <td>
                      <button
                        routerLink="/worketimes/all/{{ data.id }}"
                        class="btn btn-rounded btn-block btn-info"
                      >
                        اوقات الافتتاح
                      </button>
                    </td>
                    <td>
                      <a
                        routerLink="/brancheszones/all/{{ data.id }}"
                        class="btn btn-rounded btn-block btn-warning"
                      >
                        مناطق التوزيع
                      </a>
                    </td>
                    <td>
                      <a
                        (click)="openDirectorModal(index)"
                        class="btn btn-rounded btn-block btn-warning"
                      >
                        المديرين
                      </a>
                    </td>
                    <td>
                      <a
                        routerLink="/orders-admin/branch/{{ data.id }}"
                        class="btn btn-rounded btn-block btn-warning"
                      >
                        الطلبات
                      </a>
                    </td>
                    <td>
                      <a
                        (click)="branchStatusModel = true; branchDetails = data"
                        style="color: white"
                        class="btn btn-rounded btn-block btn-success"
                      >
                        تغيير الحالة
                      </a>
                    </td>
                    <td>
                      <a
                        (click)="openEditBranchModal(index)"
                        style="color: white"
                        class="btn btn-rounded btn-block btn-success"
                      >
                        تعديل
                      </a>
                    </td>
                    <td>
                      <button
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure delete this restuarant?"
                        (nzOnConfirm)="delete(data.id, index)"
                        (nzOnCancel)="cancel()"
                        nzPopconfirmPlacement="topLeft"
                        nz-button
                        class="btn btn-rounded btn-block btn-danger"
                      >
                        حذف
                      </button>
                    </td>

                    <td>
                      <button
                        (click)="SwitchToGoogleMaps(data)"
                        style="color: white"
                        class="btn btn-rounded btn-block btn-success"
                      >
                        الانتقال للفرع
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="height: 40px"
      *ngIf="
        this.restuarant?.departments_id == departmentEnum.content &&
        this.restuarant?.restaurant_general_status_id == 7
      "
    >
      <div class="col">
        <p-messages [value]="msgs"></p-messages>

        <button
          class="p-1 ApproveButton"
          (click)="addBranchFirstNotification()"
        >
          ارسال
        </button>
      </div>
    </div>

    <div
      class="row"
      style="height: 40px"
      *ngIf="
        ((restuarant?.departments_id == departmentEnum.SetMenu &&
          whatUser == userGroup.Content) ||
          restuarant?.departments_id == $any(userDepartment_id) ||
          (whatUser == userGroup.Admin &&
            restuarant?.restaurant_general_status_id !=
              this.generalStatus.ActiveStatus)) &&
        restuarant?.restaurant_general_status_id !=
          this.generalStatus.ContentState &&
        restuarant?.departments_id != departmentEnum.Training &&
        restuarant?.departments_id != departmentEnum.acount_manager
      "
    >
      <div class="col">
        <p-messages [value]="msgs"></p-messages>

        <button
          class="p-1 ApproveButton"
          disable="this.adding"
          (click)="ShowApproveDialog = true"
        >
          ارسال
        </button>
        <button
          class="p-1 CancelButton"
          disable="this.adding"
          *ngIf="
            !(
              restuarant?.departments_id == departmentEnum.SetMenu &&
              whatUser == userGroup.Content
            )
          "
          (click)="ShowRefuseDialog = true"
        >
          رفض
        </button>
      </div>
    </div>
    <!-- <div class="row" style="height:40px" *ngIf="this.restuarant?.departments_id != departmentEnum.content && whatUser != userGroup.Content && whatUser != userGroup.TrainingDepatment">
      <div class="col">

        <p-messages [value]="msgs"></p-messages>

        <button class="p-1  ApproveButton "
        disable="this.adding"
        *ngIf="whatUser != userGroup.Activate"
        (click)='ShowApproveDialog = true'>
          ارسال
        </button>
        <button class="p-1  CancelButton "
        disable="this.adding"

                (click)="ShowRefuseDialog = true">
          رفض
        </button>

      </div>

    </div> -->
    <div
      class="row"
      style="height: 40px"
      *appPermessions="[userGroup.TrainingDepatment]"
    >
      <div class="col">
        <p-messages [value]="msgs"></p-messages>

        <button
          class="p-1 ApproveButton"
          *ngIf="restuarant?.departments_id == departmentEnum.Training"
          (click)="ShowApproveDialog = true"
        >
          تم التدريب
        </button>
        <button
          *ngIf="restuarant?.backlog_id != 1"
          class="p-1 CancelButton"
          (click)="ShowBackLogDialog = true"
        >
          لم يتم التدريب
        </button>
      </div>
    </div>
  </div>

  <!-- ./restuarant grades -->

  <!-- ./restuarant Calendar -->

  <!-- ./restuarant Calendar -->
</section>

<!-- Dialog -->

<nz-modal
  [nzStyle]="{ direction: 'ltr', 'text-align': 'right' }"
  [(nzVisible)]="ShowApproveDialog"
  nzTitle="تاكيد التفعيل"
  nzOkText="تاكيد"
  nzCancelText="الغاء"
  (nzOnOk)="ApproveFunc()"
  (nzOnCancel)="handleCancelApprove()"
>
  {{ this.restuarant?.Department?.name }}هل ترغب في موافقة ادارة ال
</nz-modal>

<nz-modal
  [nzFooter]="' '"
  [nzStyle]="{ direction: 'ltr', 'text-align': 'right' }"
  [(nzVisible)]="ShowRefuseDialog"
  nzTitle="رفض التفعيل"
  (nzOnOk)="RefuseFunc()"
  (nzOnCancel)="handleCancelApprove()"
>
  هل ترغب في رفض الطلب من فضلك حدد السبب
  <br />

  <div class="input-group-lg col-12 mx-auto">
    <nz-select [(ngModel)]="selectedRefuseReasonId" style="width: 100%">
      <nz-option
        *ngFor="let reason of RefuseReasons; let i = index"
        [nzLabel]="reason.name"
        [nzValue]="reason.id"
      >
      </nz-option>
    </nz-select>
    <div class="input-group-lg col-12 mx-auto">
      <textarea
        [(ngModel)]="messageForBackLogs"
        class="form-control form-control-lg"
        required
      ></textarea>
      <span style="color: red" *ngIf="!selectedRefuseReasonId">
        يرجي اختيار السبب
      </span>
      <br />
      <span style="color: red" *ngIf="!messageForBackLogs">
        يرجي كتابة سبب الرفض
      </span>
      <br />
      <button
        nz-button
        [disabled]="!messageForBackLogs && !selectedRefuseReasonId"
        class="btn btn-primary ant-btn btn-info ant-btn-default ant-btn-lg"
        style="width: 150px; margin-top: 15px"
        (click)="RefuseFunc()"
      >
        تأكيد
      </button>
    </div>
  </div>
</nz-modal>
<nz-modal
  [nzFooter]="' '"
  [nzStyle]="{ direction: 'ltr', 'text-align': 'right' }"
  [(nzVisible)]="ShowBackLogDialog"
  nzTitle="رفض التفعيل"
  (nzOnOk)="RefuseAndBackLogFunc()"
  (nzOnCancel)="handleCancelApprove()"
>
  backlog هل ترغب في ارسال متجر الي من فضلك حدد السبب
  <br />

  <div class="input-group-lg col-12 mx-auto">
    <textarea
      [(ngModel)]="messageForBackLogs"
      class="form-control form-control-lg"
      required
    ></textarea>

    <span style="color: red" *ngIf="!messageForBackLogs">
      يرجي كتابة سبب الرفض
    </span>
    <button
      nz-button
      [disabled]="!messageForBackLogs"
      class="btn btn-primary ant-btn btn-info ant-btn-default ant-btn-lg"
      style="width: 150px; margin-top: 15px"
      (click)="RefuseAndBackLogFunc()"
    >
      تأكيد
    </button>
  </div>
</nz-modal>
<nz-modal
  nzShowSearch
  [(nzVisible)]="isTest"
  nzTitle="اختبار الطلبات"
  [nzContent]="modalContent"
  [nzFooter]="TestmodalFooter"
  (nzOnCancel)="closeTest()"
>
  <ng-template #modalTitle>Custom Modal Title</ng-template>

  <ng-template #modalContent>
    <div class="table-responsive">
      <nz-table
        [nzFrontPagination]="false"
        [nzData]="menuItems"
        [nzLoading]="loading"
        [nzPageSize]="20"
        [nzPageIndex]="1"
        [nzScroll]="{ x: '240px' }"
        (nzPageIndexChange)="get($event)"
        class="table forms-table"
        *ngIf="menuItems.length >= 1"
      >
        <thead>
          <tr style="background-color: #000 !important">
            <th>#</th>
            <th>الطبق</th>
            <th>الكمية</th>
            <th>السعر</th>
            <th>اضافة</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let allData of menuItems; let i = index">
            <tr style="background-color: #0000000d">
              <td colspan="5" style="color: #000">{{ allData["name"] }}</td>
            </tr>
            <tr
              *ngFor="let item of allData?.menu_categories_items; let i = index"
            >
              <td style="color: #000">
                <button
                  nz-button
                  nzType="default"
                  [nzSize]="size"
                  nzShape="circle"
                  (click)="quantity(1, item)"
                >
                  <i nz-icon nzType="plus" nzTheme="outline"></i>
                </button>

                <button
                  nz-button
                  nzType="default"
                  [nzSize]="size"
                  nzShape="circle"
                  (click)="item.amount > 1 && quantity(-1, item)"
                >
                  <i nz-icon nzType="minus" nzTheme="outline"></i>
                </button>
              </td>
              <td style="color: #000">{{ item.name }}</td>
              <td style="color: #000">{{ item.amount }}</td>
              <td style="color: #000">{{ item.total }}</td>
              <td style="color: #000">
                <input type="checkbox" (change)="addCart($event, item)" />
              </td>
            </tr>
          </ng-container>
          <tr>
            <td colspan="5">
              <div class="form-group">
                <textarea
                  class="form-control"
                  rows="4"
                  [(ngModel)]="orderTest.notes"
                >
                </textarea>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>

      <nz-table [nzData]="menuItems" *ngIf="menuItems.length == 0"></nz-table>
    </div>
  </ng-template>

  <ng-template #TestmodalFooter>
    <button
      nz-button
      class="btn btn-primary ant-btn btn-success ant-btn-default ant-btn-lg"
      style="padding: 10px 0; width: 100px; margin-left: 15px"
      (click)="addOrder()"
    >
      اضافة اوردر قبول
    </button>
    <button
      nz-button
      class="btn btn-primary ant-btn btn-danger ant-btn-default ant-btn-lg"
      style="padding: 10px 0; width: 100px; margin-left: 15px"
      (click)="addOrder()"
    >
      اضافة اوردر الغاء
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [(nzVisible)]="noTraining"
  nzTitle="سبب عدم التدريب"
  [nzContent]="noTrainingmodalContent"
  (nzOnCancel)="noTraining = false"
>
  <ng-template #modalTitle>Custom Modal Title</ng-template>

  <ng-template #noTrainingmodalContent>
    <textarea [(ngModel)]="reason" class="form-control" required></textarea>
    <span style="color: red" *ngIf="reason?.length > 10"> 10 max </span>
    <span style="color: red" *ngIf="!reason"> required </span>
    <button
      nz-button
      [disabled]="reason?.length > 10 && !reason"
      class="btn btn-primary ant-btn btn-info ant-btn-default ant-btn-lg"
      style="width: 150px; margin-top: 15px"
      (click)="notTraining()"
    >
      تأكيد
    </button>
  </ng-template>
</nz-modal>

<nz-modal
  [(nzVisible)]="isVis"
  nzTitle="التفعيل"
  [nzContent]="VirficationContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="isVis = false; ans = []"
>
  <ng-template #VirficationContent>
    <div class="table-responsive">
      <nz-table
        [nzFrontPagination]="false"
        [nzData]="questions"
        [nzLoading]="loading"
        [nzPageSize]="20"
        [nzPageIndex]="1"
        [nzScroll]="{ x: '240px' }"
        (nzPageIndexChange)="get($event)"
        class="table forms-table"
        *ngIf="questions.length >= 1"
      >
        <thead>
          <tr style="background-color: #000 !important">
            <th>#</th>
            <th>الاسئلة</th>
            <th>تم</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let allData of questions; let i = index">
            <td style="color: #000">{{ i + 1 }}</td>
            <td style="color: #000">{{ allData.question }}</td>
            <td style="color: #000">
              <input type="checkbox" (change)="addAns($event, allData)" />
            </td>
          </tr>
        </tbody>
      </nz-table>
      <nz-table [nzData]="questions" *ngIf="questions.length == 0"></nz-table>

      <button
        nz-button
        [disabled]="questions.length != ans.length"
        class="btn btn-primary ant-btn btn-success ant-btn-default ant-btn-lg"
        style="padding: 10px 0; width: 100px; margin-left: 15px"
        (click)="ActivateBranches()"
      >
        تفعيل
      </button>
    </div>
  </ng-template>

  <ng-template #modalFooter> </ng-template>
</nz-modal>

<nz-modal
  [(nzVisible)]="branchStatusModel"
  nzTitle="تغيير حالة الفرع"
  [nzContent]="branchStatusContent"
  nzFooter=" "
  (nzOnCancel)="branchStatusModel = false"
>
  <ng-template #branchStatusContent>
    <nz-select
      nzShowSearch
      style="width: 100%"
      [(ngModel)]="branchDetails.status_id"
      nzShowSearch
      nzAllowClear
      nzPlaceHolder=" اختر حالة الفرع "
      [nzSize]="'large'"
    >
      <nz-option
        *ngFor="let p of resturantStatus"
        [nzLabel]="p.name"
        [nzValue]="p.id"
      ></nz-option>
    </nz-select>

    <button
      nz-button
      class="btn btn-primary ant-btn btn-info ant-btn-default ant-btn-lg"
      style="width: 150px; margin-top: 15px"
      (click)="branchStatus()"
    >
      تأكيد
    </button>
  </ng-template>
</nz-modal>

<p-dialog
  header="صور الفرع"
  [(visible)]="branchesPhotosVisibility"
  [modal]="true"
  [draggable]="false"
  [dismissableMask]="true"
  [style]="{ width: '60vw' }"
  (onHide)="hideBranchesPhotos()"
>
  <app-branches-photos [branch_id]="selectedBranch"></app-branches-photos>
</p-dialog>
