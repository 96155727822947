import { Component, OnInit } from "@angular/core";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";

@Component({
  selector: "app-my-vendors-report",
  templateUrl: "./my-vendors-report.component.html",
  styleUrls: ["./my-vendors-report.component.scss"],
})
export class MyVendorsReportComponent implements OnInit {
  charts: any = {
    monthordersForchart: [],
    weekordersForchart: [],
  };
  counts: any = {
    weekclient: {},
    monthclient: {},
  };
  tables: any = { mostOrderedVendor: [], getMostRateVendor: [] };
  mostRateVendorHead: string[] = [
    "المطعم",
    "التعليق",
    "التقيم",
    "متوسط التقيمات",
  ];
  mostOrderedVendorHead: string[] = ["المطعم", "العدد"];
  constructor(private service: RestaurantsService) {}

  ngOnInit() {
    this.getReport();
  }

  async getReport() {
    let data = await this.service.getReport();
    this.charts.monthordersForchart = data["monthordersForchart"];
    this.charts.weekordersForchart = data["weekordersForchart"];
    this.counts.weekclient = { ...data["weekclient"][0] };
    this.counts.monthclient = { ...data["Monthclient"][0] };
    this.tables.mostOrderedVendor = data["MostOrderedVendor"];
    this.tables.getMostRateVendor = data["getMostRateVendor"];
  }
}
