import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Iinputs } from "./Interfaces/inputInterface";
import { OutputData } from "./View-Models/outputDataViewModel";
import * as moment from "moment/moment";
import { ActivatedRoute, Router } from "@angular/router";
import { querystring } from "@firebase/util";
import { GenericService } from "src/core/services/generic/generic.service";
import { isArray } from "@apollo/client/cache/inmemory/helpers";
@Component({
  selector: "app-generic-filter",
  templateUrl: "./generic-filter.component.html",
  styleUrls: ["./generic-filter.component.scss"],
})
export class GenericFilterComponent implements OnInit {
  @Input() inputs: Iinputs[];

  @Output() filter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDropDownChange: EventEmitter<any> = new EventEmitter<any>();
  outputObj: any;
  outputObjCopy: any;
  queryParams: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private genericService: GenericService,
    private router: Router
  ) {}

  ngOnInit() {
    this.outputObj = new OutputData(this.inputs);

    this.handleQueryParams();

    this.emitFilterData();
  }

  handleQueryParams() {
    this.queryParams = { ...this.activatedRoute.snapshot.queryParams };

    Object.keys(this.queryParams).forEach((param) => {
      //is number parse it
      if (!isNaN(this.queryParams[param])) {
        this.queryParams[param] = JSON.parse(this.queryParams[param]);
      } else if (isArray(this.queryParams[param])) {
        //prase numbers in array
        (this.queryParams[param] as Array<any>).forEach((element, index) => {
          if (!isNaN(element)) {
            this.queryParams[param][index] = parseInt(
              this.queryParams[param][index]
            );
          }
        });
      }
    });

    if (this.queryParams.date_to) {
      this.queryParams.date_to = new Date(this.queryParams.date_to);
    }
    if (this.queryParams.date_from) {
      this.queryParams.date_from = new Date(this.queryParams.date_from);
    }
    this.outputObj = { ...this.queryParams };
  }

  deleteEmptyKeys(key: string) {
    delete this.outputObjCopy[key];
  }

  formatDates(i: number) {
    let currentDateValue = this.outputObjCopy[this.inputs[i].name];
    if (!currentDateValue) {
      console.log("the if condition is true in the date");
      let currentDateLabel = this.inputs[i].name;
      // Returns the first day of the month if the date from is undefined or null
      if (
        (currentDateLabel.includes("date_from") ||
          currentDateLabel.includes("startfrom") ||
          currentDateLabel.includes("start_date") ||
          currentDateLabel.includes("enddate") ||
          currentDateLabel.includes("created") ||
          currentDateLabel.includes("modified")) &&
        this.inputs[i].ignoreStartDate != false
      ) {
        this.outputObjCopy[currentDateLabel] = moment()
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
      }
      // Returns the date of today if the date is undefined or null
      else if (
        (currentDateLabel.includes("date_to") ||
          currentDateLabel.includes("startto") ||
          currentDateLabel.includes("end_date") ||
          currentDateLabel.includes("startdate")) &&
        this.inputs[i].ignoreStartDate != false
      ) {
        this.outputObjCopy[currentDateLabel] = moment()
          .clone()

          .format("YYYY-MM-DD");
      }
    }
    // Formats the date before sending it
    else {
      this.outputObjCopy[this.inputs[i].name] =
        moment(currentDateValue).format("YYYY-MM-DD");
      console.log(this.inputs[i].name);
    }
  }

  emitFilterData() {
    // Sets the output object copy
    this.handleFilterData();
    // Emits the manipulated filter object
    this.filter.emit(this.outputObjCopy);
    // Resets the output copy object
    this.outputObjCopy = this.outputObj;
  }

  handleFilterData() {
    this.outputObjCopy = { ...this.outputObj };
    Object.entries(this.inputs).forEach(([key, value], i) => {
      if (this.inputs[i].type == "date") {
        this.formatDates(i);
      } else if (this.inputs[i].type == "month") {
        this.outputObjCopy[this.inputs[i].name] = moment(
          this.outputObjCopy[this.inputs[i].name]
        ).format("YYYY-MM");
      }
      if (
        (this.outputObjCopy[this.inputs[i].name] === null ||
          this.outputObjCopy[this.inputs[i].name] === undefined ||
          this.outputObjCopy[this.inputs[i].name] === "") &&
        this.inputs[i].type != "date"
      ) {
        this.deleteEmptyKeys(this.inputs[i].name);
      }
    });
  }

  emitDropDownValue(object: any, id: any) {
    const data = {};
    data[`${id}`] = object.value || object;
    this.onDropDownChange.emit(data);
  }

  resetData() {
    this.outputObj = new OutputData(this.inputs);
    this.emitFilterData();
  }

  shareFilter() {
    this.handleFilterData();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.outputObjCopy,
    });

    let queryParams = "?" + querystring(this.outputObjCopy).slice(1);
    navigator.clipboard.writeText(
      window.location.href.split("?")[0] + queryParams
    );
    this.genericService.showNotification("success", "تم نسخ الرابط", "");

    this.outputObjCopy = this.outputObj;
  }
}
