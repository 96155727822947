<div class="container">
  <div class="row bigrow mainview p-20">
    <div class="col-md-12 card-border">
      <h2 *ngIf="username" style="padding: 15px 0">
        اضافة مطعم للمستخدم : {{ username }}
      </h2>

      <div class="row townsinputs">
        <div class="col">
          <div class="form-group">
            <span *ngIf="!RestuarantObj.name && val_arr[0]">
              بالرجاء كتابة اسم المطعم
            </span>
            <h5>اسم المطعم</h5>
            <input
              type="text"
              class="form-control location"
              pattern="^[\u0621-\u064A\u0660-\u0669 ]+$"
              [(ngModel)]="RestuarantObj.name"
              (click)="val_arr[0] = true"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <span *ngIf="!RestuarantObj.name_en && val_arr[1]">
              بالرجاء كتابة اسم المطعم
            </span>
            <h5>اسم المطعم انجليزي</h5>
            <input
              class="form-control"
              [(ngModel)]="RestuarantObj.name_en"
              required
              (click)="val_arr[1] = true"
            />
          </div>
        </div>
      </div>

      <div class="row townsinputs">
        <div class="col">
          <div class="form-group">
            <span *ngIf="!RestuarantObj.code"> بالرجاء كتابة كود المطعم </span>
            <h5>كود المطعم</h5>
            <input
              class="form-control location"
              [(ngModel)]="RestuarantObj.code"
              disabled
            />
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <span *ngIf="!RestuarantObj.category_id">
              بالرجاء اختيار القسم
            </span>
            <h5>اختار اسم القسم</h5>

            <nz-select
              nzShowSearch
              style="width: 100%"
              [(ngModel)]="RestuarantObj.category_id"
              nzShowSearch
              nzAllowClear
            >
              <nz-option
                *ngFor="let p of this.zonesList"
                [nzLabel]="p.name"
                [nzValue]="p.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <span *ngIf="!RestuarantObj.restaurants_types_id && val_arr[2]">
              بالرجاء اختار فئة المتجر
            </span>
            <h5>اختار فئة المتجر</h5>
            <nz-select
              style="width: 100%"
              [(ngModel)]="RestuarantObj.restaurants_types_id"
              nzShowSearch
              nzAllowClear
              (click)="val_arr[2] = true"
            >
              <nz-option
                *ngFor="let p of this.types"
                [nzLabel]="p.name"
                [nzValue]="p.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <h5>استلام من الفرع</h5>
            <nz-select
              style="width: 100%"
              [(ngModel)]="RestuarantObj.has_takeway"
              nzShowSearch
              nzAllowClear
              required
            >
              <nz-option
                [nzLabel]="'استلام من الفرع'"
                [nzValue]="1"
              ></nz-option>
              <nz-option [nzLabel]="'غير متاح'" [nzValue]="2"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <h5>رقم البطاقة الشخصية</h5>
            <input
              class="form-control"
              #required
              (blur)="
                RestuarantObj.personal_card &&
                  RestuarantObj.personal_card.toString().length == 14 &&
                  checkpersonal_carddata()
              "
              type="text"
              [(ngModel)]="RestuarantObj.personal_card"
              required
              (keyup)="inputchange($event)"
              (click)="val_arr[3] = true"
            />

            <!-- <nz-input-number class="form-control"
            #required (blur)="
            RestuarantObj.personal_card &&
              RestuarantObj.personal_card.toString().length == 14 &&
              checkpersonal_carddata()
          " [(ngModel)]="RestuarantObj.personal_card" [nzMin]="1" [nzMax]="14" [nzStep]="1" required (click)="val_arr[3]=true;">
            </nz-input-number> -->

            <span *ngIf="!RestuarantObj.personal_card && val_arr[3]">
              بالرجاء كتابة رقم البطاقة الشخصية
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <span
              class="ids"
              *ngIf="!RestuarantObj.personal_card_exp && val_arr[4]"
            >
              بالرجاء كتابة تاريخ انتهاء البطاقة الشخصية
            </span>
            <h5>تاريخ انتهاء البطاقة الشخصية</h5>

            <nz-date-picker
              [(ngModel)]="RestuarantObj.personal_card_exp"
              required
              nzAllowClear="true"
              (click)="val_arr[4] = true"
            >
            </nz-date-picker>

            <div class="col"></div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <h5>رقم البطاقة الضريبية</h5>
            <input
              class="form-control"
              maxlength="9"
              min="100000000"
              (blur)="
                RestuarantObj.taxes_number &&
                  RestuarantObj.taxes_number.toString().length == 9 &&
                  checktaxes_number()
              "
              [(ngModel)]="RestuarantObj.taxes_number"
              type="number"
            />

            <span
              *ngIf="
                RestuarantObj.taxes_number &&
                RestuarantObj.taxes_number.toString().length != 9
              "
            >
              رقم البطاقة الضريبية مكون من 9 ارقام
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <h5>تاريخ انتهاء البطاقة الضريبية</h5>

            <nz-date-picker
              [(ngModel)]="RestuarantObj.expr_taxescarr"
              required
              nzAllowClear="true"
            >
            </nz-date-picker>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <h5>رقم السجل التجاري</h5>

            <input
              class="form-control location"
              type="number"
              min="100000"
              (blur)="
                RestuarantObj.license_number &&
                  RestuarantObj.license_number.toString().length > 0 &&
                  checklicense_number()
              "
              [(ngModel)]="RestuarantObj.license_number"
            />

            <span
              *ngIf="
                RestuarantObj.license_number &&
                RestuarantObj.license_number.toString().length < 6
              "
            >
              الحد الأدنى هو 6 ارقام
            </span>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <!-- <span *ngIf="!RestuarantObj.expr_liences">
                        بالرجاء كتابة تاريخ انتهاء السجل التجاري
                    </span>   -->
            <h5>تاريخ انتهاء السجل التجاري</h5>
            <nz-date-picker
              [(ngModel)]="RestuarantObj.expr_liences"
              nzAllowClear="true"
            >
            </nz-date-picker>
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <h5>ضريبة القيمة المضافة</h5>
            <input
              maxlength="10"
              class="form-control"
              [(ngModel)]="RestuarantObj.taxes"
              required
              type="number"
              min="1"
              (click)="val_arr[5] = true"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <h5>نسبة الخصم</h5>
            <input
              class="form-control"
              type="number"
              min="0"
              maxlength="100"
              [(ngModel)]="RestuarantObj.discount_percentage"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <h5>تاريخ بداية الخصم</h5>

            <nz-date-picker
              [(ngModel)]="RestuarantObj.discount_from"
              nzAllowClear="true"
            ></nz-date-picker>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <h5>تاريخ انتهاء الخصم</h5>

            <nz-date-picker
              [(ngModel)]="RestuarantObj.discount_to"
              nzAllowClear="true"
            >
            </nz-date-picker>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <h5>اختار اسم موظف المبيعات</h5>

            <nz-select
              style="width: 100%"
              [(ngModel)]="RestuarantObj.sales_id"
              nzShowSearch
              nzAllowClear
            >
              <nz-option
                *ngFor="let p of this.sales"
                [nzLabel]="p.username"
                [nzValue]="p.id"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>

      <br />

      <div *ngIf="!this.Isedit && !username" class="row townsinputs">
        <nz-form-label nzRequired>
          <label style="color: black; font-size: 20px">
            تفاصيل صاحب المتجر
          </label>
        </nz-form-label>

        <div class="col-6">
          <div class="form-group">
            <h5>اسم المستخدم</h5>

            <div class="flex" style="flex-direction: row-reverse">
              <input
                class="form-control location {{ isUserUsed ? 'used' : '' }}"
                id="username"
                [(ngModel)]="User.username"
                required
                (blur)="validateUniq(User.username, 'username')"
                style="height: 40px"
                (click)="val_arr[6] = true"
              />

              <div class="input-group-append">
                <span
                  class="input-group-text"
                  id="basic-addon2"
                  style="direction: ltr; font-size: 15px"
                  >@sata.com</span
                >
              </div>
            </div>

            <span
              *ngIf="isUserUsed && User.username && val_arr[6]"
              class="s-float"
            >
              هذا الاسم مستخدم من قبل
            </span>
            <span *ngIf="!User.username && val_arr[6]" class="s-float">
              بالرجاء كتابة اسم المستخدم
            </span>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group mb-3">
            <h5>كلمة المرور</h5>

            <div class="input-group-lg">
              <input
                class="form-control"
                nz-input
                disabled="true"
                required
                value="sata@123"
                [(ngModel)]="User.password"
                id="password"
                style="height: 40px"
              />

              <span *ngIf="!User.password" class="s-float">
                بالرجاء كتابة كلمة المرور
              </span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <h5>الإسم</h5>

            <div class="input-group-lg">
              <input
                class="form-control"
                nz-input
                type="text"
                required
                [(ngModel)]="User.name"
                id="name"
                style="height: 40px"
                (click)="val_arr[7] = true"
              />

              <span *ngIf="!User.name && val_arr[7]" class="s-float">
                بالرجاء الاسم
              </span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <h5>رقم الهاتف</h5>

            <div class="input-group-lg">
              <input
                class="form-control location"
                nz-input
                type="text"
                required
                (blur)="validateUniq(User.mobile, 'mobile')"
                [(ngModel)]="User.mobile"
                id="mobile"
                style="height: 40px"
                (click)="val_arr[8] = true"
              />

              <span
                *ngIf="isMobileUsed && User.mobile && val_arr[8]"
                class="s-float"
              >
                هذا الرقم مستخدم من قبل
              </span>
              <span *ngIf="!User.mobile && val_arr[8]" class="s-float">
                بالرجاء كتابة رقم الهاتف
              </span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <h5>البريد الالكتروني</h5>

            <div class="input-group-lg">
              <input
                class="form-control"
                nz-input
                type="email"
                required
                [(ngModel)]="User.email"
                id="email"
                (blur)="validateUniq(User.email, 'email')"
                style="height: 40px"
                (click)="val_arr[9] = true"
              />

              <span
                *ngIf="isEmailUsed && User.email && val_arr[9]"
                class="s-float"
              >
                هذا الإيميل مستخدم
              </span>

              <span *ngIf="!User.email && val_arr[9]" class="s-float">
                بالرجاء كتابة البريد الالكتروني
              </span>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <h5>المسمي الوظيفي</h5>

            <div class="input-group-lg">
              <input
                nz-input
                type="text"
                required
                [(ngModel)]="User.position"
                id="position"
                class="form-control location ng-pristine ng-invalid ng-touched"
                style="height: 40px"
                (click)="val_arr[10] = true"
              />

              <span *ngIf="!User.position && val_arr[10]" class="s-float">
                بالرجاء كتابة المسمي الوظيفي
              </span>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="row">
        <div class="col-md-12">
          <div class="form-group mb-3">
            <button
              class="btn btn-block d-flex align-items-center justify-content-center py-4 font-20 bg-info text-white text-2x1"
              [class.btn-info]="!Isedit"
              [class.btn-success]="Isedit"
              matRipple
              nz-button
              [disabled]="this.adding"
              (click)="add()"
            >
              <i nz-icon nzType="loading" nzTheme="outline" *ngIf="adding"></i>
              {{ !Isedit ? " اضافة مطعم" : "تعديل مطعم" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- end modal -->

<nz-modal
  [(nzVisible)]="isVis"
  nzTitle="المتاجر"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="CancelDublication()"
>
  <ng-template #modalContent>
    <div class="">
      <div class="table-responsive card custom-br custom-shadow">
        <nz-table
          [nzFrontPagination]="false"
          [nzData]="data"
          [nzLoading]="loading"
          [nzPageSize]="20"
          [nzPageIndex]="1"
          [nzScroll]="{ x: '240px' }"
          class="table forms-table table-striped"
          *ngIf="data.length >= 1"
        >
          <thead>
            <tr class="bg-light">
              <th>الاسم</th>
            </tr>
          </thead>

          <tbody>
            <tr style="cursor: pointer">
              <td
                style="vertical-align: middle"
                (click)="chooseDublicateRestaurant(data[0])"
              >
                {{ data[0]?.name ? data[0]?.name : "--" }}
              </td>
            </tr>
          </tbody>
        </nz-table>
        <nz-table [nzData]="data" *ngIf="data.length == 0"></nz-table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-3">
          <button
            class="btn btn-block btn-primary"
            matRipple
            nz-button
            (click)="getUser()"
          >
            استخدم نفس هذا الحساب
          </button>
        </div>
      </div>

      <div class="col">
        <div class="form-group mb-3">
          <button
            class="btn btn-block btn-danger"
            matRipple
            nz-button
            (click)="CancelDublication()"
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #modalFooter> </ng-template>
</nz-modal>
