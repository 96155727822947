import { gql } from "apollo-angular";

export const BRANCHES_PHOTOS_ADD_WITH_PHOTO_ARRAY_QUERY = `
{
  gallery(Function : "AddWithPhoto"){
    id
  }
}
`

export const BRANCHES_PHOTOS_DELETE_QUERY = gql`
{
  gallery(Function : "Delete"){
    id
  }
}
`