<div class="card">
    <div class="card-body">
        <div>
            <nz-select
                style="width: 200px;"
                [(ngModel)]="selectedValue"
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Filter By Status"
                (ngModelChange)="filter('Filter[order_status]', $event)">
                <nz-option
                    *ngFor="let c of status"
                    [nzLabel]="c.name"
                    [nzValue]="c.id"></nz-option>
            </nz-select>
        </div>
    </div>
</div>


<div class="table-responsive card custom-br custom-shadow">
    <nz-table
        [nzFrontPagination]="false"
        [nzData]="data"
        [nzLoading]="loading"
        [nzPageSize]="20"
        [nzPageIndex]="1"
        [nzScroll]="{ x: '240px' }"
        (nzPageIndexChange)="get($event)"
        class="table forms-table table-striped"
        *ngIf="data.length >= 1">
        <thead>
          <tr class="bg-light">
            <th *ngFor="let head of heads; let i = index">
                {{head}}
            </th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let allData of data; let i = index">
                <td>{{i + 1}}</td>
                <td>{{allData["branches"]["name"]}}</td>
                <td>{{allData["order_status"]["name"]}}</td>
                <td>{{allData["CashOrders"]}}</td>
                <td>{{allData["visaOrders"]}}</td>
                <td>{{allData["Ordertotals"]}}</td>
                <td>{{allData["commission"]}}</td>
                <td>{{allData["discount"]}}</td>
                <td>{{allData["taxes"]}}</td>
                <td>{{allData["total"]}}</td>
            </tr>
        </tbody>

        <button
            class="btn btn-block btn-info button-custom"
            style="margin-top: 20px;"
            matRipple
            nz-button
            [nzSize]="'large'"
            *ngIf="canLoadMore"
            (click)="loadMore()">
            تحميل المزيد
        </button>
    </nz-table>
    <nz-table [nzData]="data" *ngIf="data.length == 0"></nz-table>
</div>
