import { gql } from "apollo-angular";

export const ADD_ORDER_QUERY = gql`
    {
        orders (Function: "Add"){
            id
        }
    }
`;

export const EDIT_ORDER_QUERY = gql`
    {
        orders (Function: "Edit"){
            id
        }
    }
`;

export const ORDER_dATA = `
    id
    total
    user_id
    order_status_id
    paymenttype_id
    branch_id
    taxes
    waiter_id
    notes
    billing_address_id
    delivery_id
    takeway
    area_id
    sub_total
    table_id
    order_type_id
    canceled_reason
    order_details {
        id
        notes
        amount
        total
        created
        order_details_options {
          menu_options {name}
          menu_options_id
        }
        menu_categories_items{
            id
            name
            description
            photo
            price
            active
            menu_categories{
                name
            }
            menu_options_topics {
              id
              name
              max_option_checks
              price
              required
              menu_options {
                id
                name
                price
              }
            }
        }
    }
`
;

export const DELETE_ORDER_DETAIL_QUERY = gql`
{
    orderDetails(Function: "Delete") {
      id
    }
}
`
export const ADD_ORDER_DETAIL_QUERY = gql`
{
    orderDetails(Function: "Add") {
      id
    }
}
`
export const EDIT_ORDER_DETAIL_QUERY = gql`
{
    orderDetails(Function: "Edit") {
      id
    }
}
`
