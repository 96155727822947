<div class="container">
    <div class="row">
        <div class="col-md-12 d-flex">
            <a *ngIf="removeLink" (click)="remove()"><i nz-icon nzType="close" nzTheme="outline" ></i></a>
            <a *ngIf="downloadLink" (click)="download()"><i nz-icon nzType="download" nzTheme="outline"></i></a>
        </div>
        <div class="col-md-12">
            <img [src]="srcUrl" alt="">
        </div>
    </div>
</div>