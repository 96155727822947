import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment/moment";
import { OrdersAdminService } from "src/core/services/orders-admin/orders-admin.service";

@Component({
  selector: "app-main-report",
  templateUrl: "./main-report.component.html",
  styleUrls: ["./main-report.component.scss"],
})
export class MainReportComponent implements OnInit {
  orderDetails: any[] = [];
  orderByPayment: any[] = [];
  form: FormGroup;
  isFiltered: boolean = false;
  count: any = {
    weekorders: {},
    monthorders: {},
  };
  charts: any = {
    monthordersForchart: [],
    weekordersForchart: [],
    getMostOrderedCategoryWeek: [],
    getMostOrderedCategory: [],
  };
  pieCharts: any[] = [];
  OrderDetailsHead: string[] = ["الاسم", "العدد", "الإجمالي"];
  OrderByPaymentHead: string[] = ["طريقة الدفع", "القيمة"];
  constructor(
    private service: OrdersAdminService,
    private fb: FormBuilder,
    private navigate: Router
  ) {}

  ngOnInit() {
    this.getorderReporForAdmin();
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      startfrom: [null, Validators.required],
      startto: [null, Validators.required],
    });
  }

  async getorderReporForAdmin(search = {}) {
    let data = await this.service.getReport(search);
    this.orderDetails = data["orderdetails"];
    this.charts.monthordersForchart = data["monthordersForchart"];
    this.charts.weekordersForchart = data["weekordersForchart"];
    this.charts.getMostOrderedCategoryWeek = data["getMostOrderedCategoryWeek"];
    this.charts.getMostOrderedCategory = data["getMostOrderedCategory"];
    this.count.weekorders = { ...data["weekorders"][0] };
    this.count.monthorders = { ...data["monthorders"][0] };
    this.orderByPayment = data["OrderByPayment"];
  }

  async filter() {
    if (this.form.valid) {
      let values = this.form.value;
      values.startfrom = moment(values.start).format("YYYY-MM-DD");
      values.startto = moment(values.end).format("YYYY-MM-DD");
      this.getorderReporForAdmin(values);
      this.isFiltered = true;
    }
  }
  reset() {
    this.getorderReporForAdmin();
    this.form.reset();
    this.isFiltered = false;
  }

  details(type: string) {
    if (type == "visa") {
      this.navigate.navigateByUrl("/orders-admin/all?payment=1");
    } else if (type == "cash") {
      this.navigate.navigateByUrl("/orders-admin/all?payment=2");
    }
  }
}
