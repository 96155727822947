<div class="bg-bluegray-50 w-100 h-100" style="direction: rtl">
  <div class="row">
    <div class="col-12 d-flex">
      <button (click)="print()" class="btn btn-success m-1">طباعة</button>
    </div>
  </div>

  <div
    class="row p-3 m-auto border-1 border-400 bg-white"
    style="max-width: 700px"
  >
    <img
      src="{{ URL }}/{{ orderDetails?.branches?.restaurant?.logo }}"
      style="width: 100px; height: 100px"
      class="mb-2"
    />

    <div class="row col-12 p-0 mb-2">
      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">رقم الطلب :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.id }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">اسم العميل:</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.billing_address?.first_name }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">مطعم:</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.branches?.restaurant?.name }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">طريقة الدفع:</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.paymenttype?.name }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">وقت التحويل:</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.created | date : "medium" }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">العنوان :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.billing_address?.address || "N/A" }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">رقم الدور :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.billing_address?.floor_number || "N/A" }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">رقم الشقة :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.billing_address?.apartment_number || "N/A" }}
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">رقم التليفون :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.billing_address?.phone || "N/A" }}
      </p>
    </div>

    <div class="table-container">
      <table class="table table-light mb-1">
        <thead>
          <tr>
            <th class="fw-semibold" scope="col">الأطباق</th>

            <th class="fw-semibold text-center" scope="col">الكمية</th>
            <th class="fw-semibold text-center" scope="col">السعر</th>

            <th class="fw-semibold text-center" scope="col">الملاحظات</th>
            <th class="fw-semibold text-center" scope="col">خيارات</th>

            <th class="fw-semibold text-center" scope="col">الكلى</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let order of orderDetails?.order_details">
            <td class="p-1">
              <p class="mb-0 text-center">
                {{ order?.menu_categories_items?.name }}
                <br />
              </p>
            </td>
            <td class="p-1 text-center">{{ order?.amount }}</td>
            <td class="p-1 text-center">
              {{ order?.total / order?.amount }}
            </td>

            <td class="p-1 text-center">
              <span class="d-inline-block text-truncate modal-order-note">{{
                order?.notes || "---"
              }}</span>
            </td>
            <td class="p-1 text-center">
              <p *ngFor="let option of order['order_details_options']">
                {{ option.menu_options.name }}
              </p>
            </td>
            <td class="p-1 text-center">
              ج.م
              {{ +order?.total | number : "1.2-2" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row col-12 p-0 mb-2">
      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">الحساب الجزئى :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.sub_total | number : "1.0-2" }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">تكلفة التوصيل :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.delivery_serivce }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">الضريبة :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.taxes }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">خصم الكوبون :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.discount }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">خصم المحفظة :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.wallet_discount }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">خصم الكود :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.offer_discount }} ج.م
      </p>

      <p class="col-3 d-flex fs-14 mb-0 p-0 fw-normal">الإجمالى :</p>
      <p class="col-9 d-flex fs-14 mb-0 p-0 fw-semibold">
        {{ orderDetails?.total | number : "1.2-2" }} ج.م
      </p>
    </div>
  </div>
</div>
