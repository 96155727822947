import { SharedService } from "src/core/shared/shared.service";
import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { CategoriesService } from "src/core/services/categories/categories.service";
import {
  Restuarant,
  Users,
} from "src/core/services/restaurants/RestaurantModel";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { UsersService } from "src/core/services/users/users.service";
import { NzModalRef, NzModalService } from "ng-zorro-antd";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { validations } from "src/core/helper/validations";
import { CardDetailsComponent } from "src/app/main/restaurants/components/restuarants-details/Card-Details/card-details.component";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.css"],
})
export class EditComponent implements OnInit {
  BranchObj = new Restuarant();
  User = new Users();
  RestuarantObj = new Restuarant();
  logofile;
  coverfile;
  adding;
  zonesList;
  image: any;
  logo: any;
  attachment: any[] = [];
  attachmentfiles: any[] = [];
  types;
  selectedUSer;
  status;
  username: string;
  sales;
  Isedit = false;
  departmentEnum = DepartmentsEnum;
  temp_number: any;
  writing_status: boolean = false;

  URL = environment.photoPath;

  // validation dummy data
  val_arr: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  constructor(
    private service: RestaurantsService,
    private location: Location,
    private Userservice: UsersService,
    private modal?: NzModalRef,
    private Catservice?: CategoriesService,
    private navigate?: Router,
    public NZmodalService?: NzModalService,
    private activatedRoute?: ActivatedRoute,
    private generic?: GenericService,
    private sharedService?: SharedService
  ) {}

  async ngOnInit() {
    const data = await this.Catservice.getCategories();
    this.zonesList = data;

    const status = await this.service.getRestaurantStatus();
    this.status = status;
    const types = await this.service.getRestaurantTypes();
    this.types = types;
    this.User.password = "sata@123";
    this.RestuarantObj.code = this.sharedService.generateCode(10);

    const sales_man = await this.Userservice.getuserSalesDropdown({
      user_group_id: 8,
    });
    this.sales = sales_man;

    this.zonesList = data;

    if (localStorage.getItem("restaurant_id_for_edit")) {
      this.service
        .getRestaurantDetails(localStorage.getItem("restaurant_id_for_edit"))
        .subscribe({
          next: (data: any) => {
            this.RestuarantObj = data[0];
          },
        });
      if (this.sales.length)
        this.RestuarantObj.sales_id = this.sales[0]["id"] || 0;
      this.RestuarantObj.category_id = this.zonesList[0].id || 0;
      this.Isedit = true;
      delete this.BranchObj.navigationId;
    }
  }
  inputnumber;
  inputchange(e) {
    this.RestuarantObj.personal_card = this.RestuarantObj.personal_card.replace(
      /(?![0-9])./gim,
      ""
    );
  }
  uploadFile($event) {
    console.log($event.target.files[0]); // outputs the first file
    this.image = $event.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(this.image);

    reader.onload = (_event) => {
      this.coverfile = reader.result;
    };
  }
  uploadLogoFile($event) {
    this.logo = $event.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(this.logo);

    reader.onload = (_event) => {
      this.logofile = reader.result;
    };
    console.log(this.logofile); // outputs the first file
  }
  uploadAttahcmentFile($event) {
    this.attachment = $event.target.files;
    for (let index = 0; index < $event.target.files.length; index++) {
      const element = $event.target.files[index];
      console.log(element); // outputs the first file

      var reader = new FileReader();

      reader.onload = (_event: any) => {
        this.attachmentfiles.push(_event.target.result);
      };
      reader.readAsDataURL(element);
    }
    console.log(this.attachmentfiles); // outputs the first file
  }

  async add() {
    this.adding = true;
    if (new validations(document).vlidate()) {
      var formData: any = new FormData();
      this.RestuarantObj.content_id = parseInt(localStorage.getItem("user_id"));
      this.RestuarantObj.departments_id = this.departmentEnum.content;

      // post-fix "@sata.com"
      this.User.username = this.User.username + "@sata.com";
      this.User.code = this.sharedService.generateCode(10);

      if (this.User.id != null) {
        this.RestuarantObj.user_id = this.User.id;
      } else {
        formData.append("user", JSON.stringify(this.User));
      }
      formData.append("data", JSON.stringify(this.RestuarantObj));

      formData.append(
        "img",
        this.image != undefined ? this.image : this.RestuarantObj.cover
      );
      formData.append(
        "logo",
        this.logo != undefined ? this.logo : this.RestuarantObj.logo
      );

      for (let index = 0; index < this.attachment.length; index++) {
        formData.append("attachment", this.attachment[index]);
      }

      const data = await this.service.add(formData);
      if (data.id > 0 || data > 0) {
        if (this.Isedit == true) {
          this.generic.showNotification(
            "success",
            "تم التعديل بنجاح",
            "تم التعديل بنجاح"
          );
          if (this.modal.afterOpen) {
            this.modal.close();
          }
          this.navigate.navigate(["/restuarantDetails/" + data.id]);
        } else {
          this.generic.showNotification(
            "success",
            "تم الاضافة بنجاح",
            "تم الاضافة بنجاح"
          );
          this.navigate.navigate(["/restuarantDetails/" + data.id]);
        }
      }
    } else {
      this.adding = false;
      this.generic.showNotification(
        "error",
        "يرجى مراجعة جميع الحقول",
        "يرجى مراجعة جميع الحقول"
      );
      this.adding = false;
    }
  }

  prev: boolean = false;
  isVis: boolean = false;
  data: any[] = [];

  chooseDublicateRestaurant(pro) {
    const modalRef = this.NZmodalService.create({
      nzTitle: " المعلومات الاساسية للمطعم",
      nzContent: CardDetailsComponent,
      nzWidth: "1200px",
      nzBodyStyle: { background: "#333d54" },
    });

    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.restuarant = pro;
    }, 1000);
  }

  async checktaxes_number() {
    const taxes_numberobjs = {
      taxes_number: this.RestuarantObj.taxes_number,
    };

    console.log(taxes_numberobjs);
    const dataa: any = await this.service.FilterRestaurant(taxes_numberobjs);

    console.log(dataa);

    if (dataa.length) {
      this.data = dataa;
      console.log(dataa[0]);
      this.isVis = true;
    }
  }

  async checkpersonal_carddata() {
    const personal_cardobj = {
      personal_card: this.RestuarantObj.personal_card,
    };

    const personal_carddata: any = await this.service.FilterRestaurant(
      personal_cardobj
    );
    console.log(personal_carddata);
    if (personal_carddata.length) {
      this.data = personal_carddata;
      this.isVis = true;
    }
  }

  async checklicense_number() {
    const obj = {
      license_number: this.RestuarantObj.license_number,
    };

    const data: any = await this.service.FilterRestaurant(obj);

    console.log(data);

    if (data.length) {
      this.data = data;
      this.isVis = true;
    }
  }

  async CancelDublication() {
    const temp = this.RestuarantObj.code;
    this.RestuarantObj = new Restuarant();
    this.RestuarantObj.code = temp;
    this.isVis = false;
  }

  async getUser() {
    console.log("data from last function : ", this.data);
    // const user_id = this.data.filter((user) => user.id == this.selectedUSer)[0]
    const user_id = this.data[0].user_id;

    const data: any = await this.Userservice.view(user_id);
    console.log(data);
    if (data.length == 0) {
      this.generic.showNotification(
        "warning",
        "بحث",
        "لا يوجد مستخدم مرتبط بهذا المتجر"
      );
    } else {
      this.username = data[0].username;
      this.User = data[0];
      this.RestuarantObj.user = data;
      this.isVis = false;
      this.data = [];
      this.selectedUSer = null;
    }
  }

  isUser;
  isUserUsed = false;
  isEmail;
  isMobile;
  isEmailUsed = false;
  isMobileUsed = false;

  async validateUniq(value, type) {
    if (value) {
      if (type == "email") this.isEmail = true;
      if (type == "username") this.isUser = true;
      if (type == "mobile") this.isMobile = true;

      this.Userservice.userSearch({
        username: type == "username" ? value + "@sata.com" : value,
      }).then((res) => {
        if (res) {
          if (type == "email") this.isEmailUsed = true;
          if (type == "username") this.isUserUsed = true;
          if (type == "mobile") this.isMobileUsed = true;
        } else {
          if (type == "email") this.isEmailUsed = false;
          if (type == "username") this.isUserUsed = false;
          if (type == "mobile") this.isMobileUsed = false;
        }
        if (type == "email") this.isEmail = false;
        if (type == "username") this.isUser = false;
        if (type == "mobile") this.isMobile = false;
      });
    } else {
      if (type == "email") this.isEmail = false;
      if (type == "mobile") this.isMobile = false;
      if (type == "username") this.isUser = false;
    }
  }

  // limit_14_numbers_input()
  // {

  //   if ( this.RestuarantObj.personal_card.toString().length == 14 )
  //   {
  //     this.temp_number = this.RestuarantObj.personal_card;
  //     console.log("working!");
  //     this.writing_status = true;
  //   }

  // }

  // edit_number()
  // {
  //   this.writing_status = false;
  // }
}
