<nz-tabset
  (nzSelectChange)="tab_changed($event)"
  [nzSelectedIndex]="active_tab"
  nzType="card"
>
  <nz-tab
    [nzTitle]="tab.name"
    *ngFor="let tab of tabs"
    (nzSelect)="onTabChange(tab.id)"
  >
    <div class="row">
      <div class="col-12">
        <div class="card">
          <ng-container>
            <div class="card-body">
              <form
                class="form"
                [formGroup]="form"
                style="padding-top: 1.25rem"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Filter By Name"
                        id="name"
                        name="name"
                        formControlName="name"
                      />
                    </div>
                    <div class="col-3">
                      <nz-select
                        style="width: 100%"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Filter By Category"
                        [nzSize]="'large'"
                        formControlName="category_id"
                      >
                        <nz-option
                          *ngFor="let cat of categories"
                          [nzLabel]="cat.name"
                          [nzValue]="cat.id"
                        ></nz-option>
                      </nz-select>
                    </div>

                    <div class="col-3">
                      <nz-select
                        style="width: 100%"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Filter By Restaurant Status"
                        [nzSize]="'large'"
                        formControlName="restaurants_status_id"
                      >
                        <nz-option
                          *ngFor="let filter of restaurantStatus"
                          [nzLabel]="filter.name"
                          [nzValue]="filter.id"
                        ></nz-option>
                      </nz-select>
                    </div>
                    <div class="col-3">
                      <nz-select
                        style="width: 100%"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Filter By Restaurant Types"
                        [nzSize]="'large'"
                        formControlName="restaurants_types_id"
                      >
                        <nz-option
                          *ngFor="let filter of restaurantTypes"
                          [nzLabel]="filter.name"
                          [nzValue]="filter.id"
                        ></nz-option>
                      </nz-select>
                    </div>
                    <div class="col-3">
                      <nz-date-picker
                        formControlName="created"
                        [nzSize]="'large'"
                        nzPlaceHolder="Filter By Date"
                        nzAllowClear="true"
                      ></nz-date-picker>
                    </div>

                    <div class="col">
                      <button
                        (click)="filterGraph()"
                        type="button"
                        class="btn btn-info btn-raised mr-1 d-block w-100"
                      >
                        <span *ngIf="!filterLoading">Filter</span>
                        <span *ngIf="filterLoading">
                          <i nz-icon nzType="loading" nzTheme="outline"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col">
                      <button
                        (click)="reset()"
                        type="button"
                        class="btn btn-secondary btn-raised mr-1 d-block w-100"
                      >
                        <span>Reset</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div
                class="product-box"
                *ngFor="let pro of restaurants.results; let i = index"
              >
                <div
                  class="restuarant-icon py-3 custom-br"
                  [ngStyle]="
                    pro?.cover != ''
                      ? {
                          'background-image':
                            'url(' + baseUrl + '/' + pro?.cover + ')'
                        }
                      : { 'background-image': 'url(' + CoverSrc + ')' }
                  "
                  style="background-repeat: no-repeat; background-size: cover"
                >
                  <div style="position: relative">
                    <img
                      [src]="pro?.logo == '' ? imageSrc : URL + '/' + pro?.logo"
                      alt=""
                      class="img-fluid custom-shadow d-block"
                    />
                  </div>
                </div>

                <div class="col-md-10">
                  <h3 class="text-left">{{ pro.name }} ({{ pro.name_en }})</h3>

                  <b style="margin-left: 5px"> القسم :</b>
                  <label class="label label-primary" style="margin-left: 5px">{{
                    pro.created | date
                  }}</label
                  ><br />

                  <b style="margin-left: 5px">تاريخ الانشاء :</b>
                  <label class="label label-primary" style="margin-left: 5px">{{
                    pro.created | date
                  }}</label
                  ><br />
                  <b style="margin-left: 5px">تاريخ وجوده بالادارة :</b>
                  <div *ngIf="pro.modified; then content; else other_content">
                    here is ignored
                  </div>
                  <ng-template #content>{{ pro.modified | date }}</ng-template>
                  <ng-template #other_content>لا يوجد</ng-template>
                </div>

                <div class="col-md-12" style="padding: 22px">
                  <nz-alert
                    *ngIf="
                      pro.refuseReason && pro.restaurant_general_status_id == 5
                    "
                    nzType="error"
                    nzMessage=" مرفوض من ادارة ({{ pro.Department.name }})"
                    nzDescription="{{ pro.refuseReason.name }}"
                    nzShowIcon
                  >
                  </nz-alert>
                </div>

                <div class="col-md-12">
                  <div class="row button-group" style="margin: 15px 0">
                    <div class="col-6">
                      <a
                        (click)="resReport(null, pro.id, 'details')"
                        class="btn btn-rounded btn-block btn-warning text-white"
                      >
                        تفاصيل المتجر
                      </a>
                    </div>

                    <div class="col-6">
                      <a
                        (click)="edit(i)"
                        class="btn btn-rounded btn-block btn-success"
                      >
                        تعديل
                      </a>
                    </div>
                    <!-- <div class="col-6">
                      <button
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure delete this restuarant?"
                        (nzOnConfirm)="delete(pro.id, i)"
                        (nzOnCancel)="cancel()"
                        nzPopconfirmPlacement="topLeft"
                        nz-button
                        class="btn btn-rounded btn-block btn-danger"
                      >
                        حذف
                      </button>
                    </div> -->
                    <div class="col-6">
                      <a
                        (click)="resReport(pro.name, pro.id, 'report')"
                        class="btn btn-rounded btn-block sata-btn text-white"
                      >
                        تقرير المطعم
                      </a>
                    </div>
                    <ng-container *ngIf="this.currentTab == 5">
                      <div class="col-2">
                        <a
                          style="color: white"
                          (click)="BackToDepartment(i)"
                          class="btn btn-rounded btn-block btn-danger"
                        >
                          اعادة ارسال
                        </a>
                      </div>
                      <div class="col-2">
                        <a
                          style="color: white"
                          (click)="Archive(i)"
                          class="btn btn-rounded btn-block sata-btn"
                        >
                          ارشيف
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <!-- <div class="loader-rapper" style="margin-top: 20px;">
                                    <div class="text-center" *ngIf="paginationLoader">
                                        <i nz-icon nzType="loading" nzTheme="outline"></i>
                                        جار التحميل ...
                                    </div>
                                </div> -->
              </div>
            </div>
          </ng-container>

          <nz-pagination
            *ngIf="restaurants.total"
            [nzPageIndex]="page"
            [nzTotal]="restaurants.total"
            nzShowSizeChanger
            [nzPageSizeOptions]="[5, 10, 15, 20, 50]"
            [nzPageSize]="limit"
            (nzPageIndexChange)="onPageChange($event)"
            (nzPageSizeChange)="onSizeChange($event)"
          >
          </nz-pagination>
          <div class="col">
            <nz-empty *ngIf="restaurants.total == 0"></nz-empty>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>

<!-- loader -->
<div
  class="loader"
  style="
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background-color: #f6f6f6;
  "
  *ngIf="loader"
>
  <div class="loader-wrapper w-100">
    <img src="./../../../../assets/images/loader.gif" alt="loader" />
  </div>
</div>

<!-- modal -->

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header" style="direction: rtl">
    <button
      type="button"
      class="close"
      style="position: absolute; left: 0"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form
      class="form text-right"
      #f="ngForm"
      style="padding-top: 1.25rem; direction: rtl"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="percentage">نسبة العرض:</label>
            <input
              type="number"
              class="form-control"
              placeholder="نسبة العرض ..."
              id="percentage"
              name="percentage"
              ngModel
              required
              percentage
              #percentage="ngModel"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                !percentage.valid && (percentage.dirty || percentage.touched)
              "
            >
              بالرجاء تحديد نسبة بداية العرض!!
            </small>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="start_date">تاريخ بداية العرض:</label>
            <input
              type="date"
              class="form-control"
              placeholder="تاريخ بدية العرض ..."
              id="start_date"
              name="start_date"
              ngModel
              required
              start_date
              #start_date="ngModel"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <small
              class="form-text text-danger"
              *ngIf="
                !start_date.valid && (start_date.dirty || start_date.touched)
              "
            >
              بالرجاء تحديد تاريخ بداية العرض!!
            </small>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="end_date">تاريخ نهاية العرض:</label>
            <input
              type="date"
              class="form-control"
              placeholder="تاريخ نهاية العرض ..."
              id="end_date"
              name="end_date"
              ngModel
              required
              end_date
              #end_date="ngModel"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <small
              class="form-text text-danger"
              *ngIf="!end_date.valid && (end_date.dirty || end_date.touched)"
            >
              بالرجاء تحديد تاريخ نهاية العرض!!
            </small>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
          <button
            (click)="addOffer(f)"
            type="button"
            style="margin-top: 30px"
            class="btn btn-info btn-raised mr-1"
          >
            <span *ngIf="!offerloader"> اضافة عرض </span>
            <span *ngIf="offerloader"> جار الاضافة ... </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
