<div class="container">
<div class="row bigrow mainview">
    <div class="col-md-12 card-border">
        <img src="assets/images/res.png" alt="" width="10%">

        <h2 *ngIf="username" style=" padding: 15px 0;">
            اضافة مطعم للمستخدم : {{username}}
        </h2>

        <div class="row townsinputs">
            <div class="col">


                <div class="form-group">

                    <input class="form-control location" placeholder=" اسم المطعم" [(ngModel)]="RestuarantObj.name">

                    <span *ngIf="!RestuarantObj.name">
                        بالرجاء كتابة اسم المطعم
                   </span>

                </div>
            </div>

            <div class="col">
                <div class="form-group">

                    <input class="form-control" [(ngModel)]="RestuarantObj.name_en" placeholder="اسم المطعم انجليزي"
                        required>

                    <span *ngIf="!RestuarantObj.name_en">
                        بالرجاء كتابة اسم المطعم
                    </span>

                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <nz-select
                    nzShowSearch
                    style="width: 100%;" [(ngModel)]="RestuarantObj.category_id"
                         nzShowSearch nzAllowClear nzPlaceHolder="اختار اسم القسم"
                        >
                        <nz-option *ngFor="let p of this.zonesList" [nzLabel]="p.name" [nzValue]="p.id"></nz-option>
                    </nz-select>

                    <span *ngIf="!RestuarantObj.category_id">
                        بالرجاء اختيار القسم
                    </span>
                </div>
            </div>

            <div class="col-6">

                <div class="form-group">

                    <input maxlength="10"  class="form-control" [(ngModel)]="RestuarantObj.taxes"
                        placeholder=" ضريبة القيمة المضافة  " >

                    <span *ngIf="!RestuarantObj.taxes">
                        بالرجاء اضافة ضريبة القيمة المضافة
                    </span>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <nz-select style="width: 100%;" [(ngModel)]="RestuarantObj.restaurants_types_id"
                        nzShowSearch nzAllowClear nzPlaceHolder="اختار فئة المتجر "
                        >
                        <nz-option *ngFor="let p of this.types" [nzLabel]="p.name" [nzValue]="p.id"></nz-option>
                    </nz-select>

                    <span *ngIf="!RestuarantObj.restaurants_types_id">
                        بالرجاء اختار فئة المتجر
                    </span>
                </div>
            </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <nz-select style="width: 100%;" [(ngModel)]="RestuarantObj.has_takeway"
                            nzShowSearch nzAllowClear nzPlaceHolder="استلام من الفرع"
                            required
                            >
                            <nz-option  [nzLabel]="'استلام من الفرع'" [nzValue]="1"></nz-option>
                            <nz-option  [nzLabel]="'غير متاح'" [nzValue]="2"></nz-option>

                        </nz-select>
                    </div>
                </div>


        </div>
        <div class="row ">
            <div class="col-md-6">

                <div class="form-group">

                    <input  class="form-control  location"
                            maxlength="14"
                            type="number"
                            placeholder="رقم السجل التجاري"
                            (blur)="(RestuarantObj.license_number && RestuarantObj.license_number.toString().length == 14) && checklicense_number()"
                            [(ngModel)]="RestuarantObj.license_number">

                    <span *ngIf="RestuarantObj.license_number && RestuarantObj.license_number.toString().length != 14">
                        رقم السجل التجاري مكون من 14 رقم
                    </span>

                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">

                        <nz-date-picker
                        nzPlaceHolder="تاريخ انتهاء السجل التجاري"
                        [(ngModel)]="RestuarantObj.expr_liences"
                        nzAllowClear="true" >
                    </nz-date-picker>





                </div>
            </div>


            <div class="col-md-6">
                <div class="form-group">
                    <input
                        class="form-control"
                        maxlength="9"
                        (blur)="(RestuarantObj.taxes_number && RestuarantObj.taxes_number.toString().length == 9) && checktaxes_number()"
                        [(ngModel)]="RestuarantObj.taxes_number"
                        type="number"
                        placeholder="رقم البطاقة الضريبية">

                    <span *ngIf="RestuarantObj.taxes_number && RestuarantObj.taxes_number.toString().length != 9">
                        رقم البطاقة الضريبية مكون من 9 ارقام
                    </span>

                </div>
            </div>


            <div class="col-md-6">
                <div class="form-group">

                    <input
                        class="form-control"
                        #required
                        (blur)="(RestuarantObj.personal_card && RestuarantObj.personal_card.toString().length == 14) && checkpersonal_carddata()"
                        maxlength="14"
                        type="number"
                        [(ngModel)]="RestuarantObj.personal_card"
                        placeholder="رقم البطاقة الشخصية" required>

                    <span *ngIf="!RestuarantObj.personal_card">
                        بالرجاء كتابة رقم البطاقة الشخصية
                    </span>

                    <span *ngIf="RestuarantObj.personal_card && RestuarantObj.personal_card.toString().length != 14">
                        رقم البطاقة الشخصية مكون من 14 رقم
                    </span>

                </div>
            </div>


        </div>


        <div class="row ">

            <div class="col">

                <div class="form-group">

                        <nz-date-picker

                        nzPlaceHolder="تاريخ انتهاء السجل التجاري"
                        required
                        [(ngModel)]="RestuarantObj.expr_liences"
                        nzAllowClear="true" >
                    </nz-date-picker>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <nz-date-picker

                    [(ngModel)]="RestuarantObj.expr_taxescarr"
                    nzPlaceHolder="تاريخ انتهاء البطاقة الضريبية"
                    required
                    nzAllowClear="true" >
                </nz-date-picker>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <nz-date-picker

                    [(ngModel)]="RestuarantObj.personal_card_exp "
                    nzPlaceHolder="تاريخ انتهاء البطاقة الشخصية" required
                    nzAllowClear="true" >

                </nz-date-picker>

                <div class="col">


                <!-- </nz-date-picker> -->

                <span *ngIf="!RestuarantObj.personal_card_exp ">
                    بالرجاء كتابة تاريخ انتهاء البطاقة الشخصية
                </span>
                </div>
            </div>


            </div>
        </div>


        <div class="row ">
            <div class="col">

                <div class="form-group">

                    <input class="form-control "  maxlength="10"  placeholder="نسبة الخصم"
                        [(ngModel)]="RestuarantObj.discount_percentage" >
                </div>
            </div>
            <div class="col">

                <div class="form-group">

                        <nz-date-picker

                        nzPlaceHolder="تاريخ بداية  الخصم"
                        [(ngModel)]="RestuarantObj.discount_from"
                        nzAllowClear="true" ></nz-date-picker>


                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <nz-date-picker

                    [(ngModel)]="RestuarantObj.discount_to"
                    nzPlaceHolder="تاريخ انتهاء  الخصم"
                    nzAllowClear="true" >
                </nz-date-picker>


                </div>

            </div>

            <div class="col">
                <div class="form-group">
                    <nz-select style="width: 100%;" [(ngModel)]="RestuarantObj.sales_id"
                        nzShowSearch nzAllowClear nzPlaceHolder="اختار اسم  موظف المبيعات "
                        >
                        <nz-option *ngFor="let p of this.sales" [nzLabel]="p.username" [nzValue]="p.id"></nz-option>
                    </nz-select>

                </div>
            </div>
        </div>

        <div *ngIf="!this.Isedit && !username"  class="row townsinputs">
            <nz-form-label nzRequired>
                <label>
                    تفاصيل صاحب المتجر
                </label>
            </nz-form-label>
            <div class="col">

                <div class="form-group">

                    <input class="form-control location" placeholder="اسم المستخدم" [(ngModel)]="User.username"
                        required>

                    <span *ngIf="!User.username">
                        بالرجاء كتابة اسم المستخدم
                    </span>

                </div>
            </div>

            <div class="col">
                <div class="form-group mb-3">

                    <div class="input-group-lg">
                        <input
                            class="form-control"
                            nz-input
                            placeholder="الإسم"
                            type="text"
                            required
                            [(ngModel)]="User.name"
                            id="name" />

                        <span *ngIf="!User.name">
                            بالرجاء الاسم
                        </span>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="form-group mb-3">

                    <div class="input-group-lg">
                        <input
                            class="form-control"
                            nz-input
                            type="text"
                            required
                            placeholder="رقم الهاتف"
                            [(ngModel)]="User.mobile"
                            id="mobile" />

                        <span *ngIf="!User.mobile">
                            بالرجاء كتابة رقم الهاتف
                        </span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="form-group mb-3">

                    <div class="input-group-lg">
                        <input
                            class="form-control"
                            nz-input
                            type="text"
                            required
                            placeholder="البريد الالكتروني"
                            [(ngModel)]="User.email"
                            id="email" />

                        <span *ngIf="!User.email">
                            بالرجاء كتابة البريد الالكتروني
                        </span>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-3">
                    <button
                        class="btn btn-block"
                        [class.btn-info]="!obj"
                        [class.btn-success]="obj"
                        matRipple
                        nz-button

                        (click)="add()">
                        <i nz-icon nzType="loading" nzTheme="outline" *ngIf="adding"></i>
                        {{(!obj) ? 'اضافة' : 'تعديل'}}
                    </button>

                </div>
            </div>
        </div>
    </div>

</div>
</div>

<!-- end modal -->

<nz-modal
  [(nzVisible)]="isVis"
  nzTitle="المتاجر"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="CancelDublication()"
  >

<ng-template #modalContent>
    <div class="">
        <div class="table-responsive card custom-br custom-shadow">
            <nz-table
                [nzFrontPagination]="false"
                [nzData]="data"
                [nzLoading]="loading"
                [nzPageSize]="20"
                [nzPageIndex]="1"
                [nzScroll]="{ x: '240px' }"
                class="table forms-table table-striped"
                *ngIf="data.length >= 1">
                <thead>
                  <tr class="bg-light">
                    <th>
                        الاسم
                    </th>
                  </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let pro of data; let i = index"
                        (click)="selectedUSer = pro?.id"
                        style="cursor: pointer;"
                        [class.selected]="selectedUSer == pro?.id">
                        <td style="vertical-align: middle;">
                            {{pro?.name ? pro?.name : '--'}}
                        </td>
                    </tr>
                </tbody>

            </nz-table>
            <nz-table [nzData]="data" *ngIf="data.length == 0"></nz-table>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-group mb-3">
                <button
                    class="btn btn-block btn-primary"
                    matRipple
                    nz-button

                    [disabled]="!selectedUSer"
                    (click)="getUser()">
                    استخدم نفس هذا الحساب
                </button>

            </div>
        </div>

        <div class="col">
            <div class="form-group mb-3">
                <button
                    class="btn btn-block btn-danger"
                    matRipple
                    nz-button

                    (click)="CancelDublication()">
                    الغاء
                </button>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalFooter>
</ng-template>
</nz-modal>
