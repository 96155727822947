import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { MainmenuService } from "src/core/services/Mainmenu/Mainmenu";
import { UserGroup } from "src/core/enums/user.group.enum";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  @Output() tabs_clicked_event = new EventEmitter<boolean>();

  userGroup = UserGroup;
  searchName: string = "";
  showMenu = "";
  showSubMenu = "";
  URL = environment.photoPath;
  ResturantName = localStorage.getItem("RestaurantName");
  Username = localStorage.getItem("username");

  ResturantPhoto = localStorage.getItem("RestaurantLogo");
  routes: any[] = [];
  sidebarnavItems: any[] = [];
  vendor_id = JSON.parse(localStorage.getItem("user"))?.restaurants?.id;
  // this is for the open close

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private mainmenuService: MainmenuService
  ) {}

  // End open close
  ngOnInit() {
    this.routes = JSON.parse(localStorage.getItem("MenuData"));
    this.sidebarnavItems = this.routes.filter(
      (sidebarnavItem) => sidebarnavItem
    );

    /*if (parseInt(localStorage.getItem("user_group_id")) == 7) {
      this.sidebarnavItems = OperationsROUTES.filter(sidebarnavItem => sidebarnavItem);
      return
    }

    if (localStorage.getItem("RestaurantId") == "null"){
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);

    }else {
      this.sidebarnavItems = ResTaurantROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    if (localStorage.getItem("BranchId") != "null"){
      this.sidebarnavItems = BranchesROUTES.filter(sidebarnavItem => sidebarnavItem);
    }*/
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
  }

  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["/authentication/login"]);
  }
  async search() {
    if (this.searchName == "") this.sidebarnavItems = this.routes;
    else
      this.sidebarnavItems = await this.mainmenuService.search(this.searchName);
  }

  one_of_tabs_clicked() {
    // close_tabs_btn_m
    console.log("tab clicked!");

    this.tabs_clicked_event.emit(true);
  }
}
