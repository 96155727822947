import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { gql } from "apollo-angular";
import { Dialog } from "primeng/dialog";
import { startWith, pairwise } from "rxjs/operators";
import { GenericService } from "src/core/services/generic/generic.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { OrdersService } from "src/core/services/orders/orders.service";
import { environment } from "src/environments/environment";
import {
  ADD_ORDER_DETAIL_QUERY,
  ADD_ORDER_QUERY,
  DELETE_ORDER_DETAIL_QUERY,
  EDIT_ORDER_DETAIL_QUERY,
  EDIT_ORDER_QUERY,
  ORDER_dATA,
} from "../../../queries/orders.queries";

@Component({
  selector: "app-order-admin-form",
  templateUrl: "./order-admin-form.component.html",
  styleUrls: ["./order-admin-form.component.css"],
})
export class OrderAdminFormComponent implements OnInit {
  @Output("closeEditOrderModal") closeEditOrderModal = new EventEmitter();

  selectedItems: any[] = [];
  heads: string[] = [
    "#",
    "القسم",
    "الطبق",
    "السعر",
    "الكمية",
    "الإجمالى",
    "Actions",
  ];
  addItemModalIsVisible: boolean = false;
  tabIndex: number = 1;
  menuCategories: any[] = [];
  vendor_id: number = JSON.parse(localStorage.getItem("user")).restaurants.id;
  imgSrc: string = environment.photoPath + "/";
  selectedCategory: any;
  menuCategoriesItems: any;
  selectedItem: any;
  amount: number = 1;
  itemTotal: number = 0;
  orderDetails: any[] = [];
  notes: string = "";
  order_details_options: any[] = [];
  orderDetailsData: any[] = [];
  selectedOptions: any[] = [];
  branches: any;
  towns: any[] = [];
  paymentTypes: any[] = [];
  subTotal: number;
  taxes: number = JSON.parse(localStorage.getItem("user")).restaurants.taxes;
  total: number = 0;
  addToOrderDetailsDisabled: boolean = true;
  users: any[] = [];
  selectedUser: any;
  billingAddresses: any[] = [];
  selectedBillingAddress: any;
  addBillingAddressModalVisibility: boolean;
  deliveries: any;
  delivery_id: number;
  delivery_cost: any;
  discount: any;
  isItemEdit: boolean = false;
  editItemIndex: number;
  orderNotes: string = "";
  chooseLocationModalVisibility: boolean = false;

  orderForm: FormGroup;

  orderTypes: any[] = [
    { id: 0, name: "توصيل" },
    { id: 1, name: "استلام من المطعم" },
    { id: 2, name: "صالة" },
  ];
  isOrderEdit: boolean;
  order_id = +localStorage.getItem("order_id");
  orderDetailId: any;
  orderStatus: Promise<Object>;

  constructor(
    private formBuilder: FormBuilder,
    private graphQLService: GraphQLService,
    private genericService: GenericService,
    private router: Router,
    private ordersService: OrdersService,
  ) {}
  ngOnInit(): void {
    this.createOrderForm();
    this.getMenuCategories();
    this.getBranches();
    this.getPaymentTypes();
    this.getTowns();
    this.getUsers();
    this.getOrderStatus();

    console.log(localStorage.getItem("order_id"));
    
    if (this.order_id) {
      this.isOrderEdit = true;
      this.handleEditOrderData(this.order_id);
    }
  }

  createOrderForm(): void {
    this.orderForm = this.formBuilder.group({
      branch_id: [null, Validators.required],
      table_id: [null, Validators.required],
      waiter_id: [null, Validators.required],
      paymenttype_id: [null, Validators.required],
      order_type_id: [null, Validators.required],
      area_id: [null],
      order_status_id: [0],
      user_id: [null],
      billing_address_id: [null],
      delivery_id: [null],
      canceled_reason: [null],
    });
  }
  async getOrderStatus() {
    try {
      const data: any = await this.ordersService.status();
      this.orderStatus = data;
    } catch (e) {
      this.genericService.showNotification(
        "error",
        "حالات الطلب",
        "حدث خطا ما"
      );
    }
  }
  showAddItemModal(): void {
    if (!this.orderForm.controls["branch_id"].value) {
      this.genericService.showNotification("error", "اضافة طلب", "اختر الفرع");
    } else if (this.orderForm.get("order_type_id").value == null) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "اختر نوع الطلب"
      );
    } else if (
      !this.orderForm.controls["user_id"].value &&
      this.orderForm.get("order_type_id").value == 0
    ) {
      this.genericService.showNotification("error", "اضافة طلب", "اختر العميل");
    } else if (
      !this.orderForm.controls["billing_address_id"].value &&
      this.orderForm.get("order_type_id").value == 0
    ) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "اختر عنوان التوصيل"
      );
    } else {
      this.addItemModalIsVisible = true;
    }
  }
  hideAddItemModal(): void {
    this.addItemModalIsVisible = false;
    this.tabIndex = 1;
  }
  back(): void {
    this.tabIndex--;
    this.itemTotal = 0;
    this.selectedOptions = [];
    this.notes = "";
    this.amount = 1;
  }
  getUsers() {
    this.graphQLService
      .query(
        gql`
      {
        users(vendor_idEq:${this.vendor_id},user_group_idIn:[3,2,0]){
          id
          username
        }
      }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.users = response.data.users;
        },
        error: (error) => {},
      });
  }
  getMenuCategories(): void {
    this.graphQLService
      .query(
        gql`
        {
        menuCategories (vendor_idEq:${this.vendor_id}){
          id
          name
          photo
        }
        }
        `
      )
      .subscribe({
        next: (response: any) => {
          this.menuCategories = response.data.menuCategories;
        },
      });
  }

  getItems(category_id: number): void {
    this.graphQLService
      .query(
        gql`
        {
          menuCategoriesItems (activeEq:${1},menu_categories_idEq:${category_id}){
            id
            name
            description
            photo
            price
            active
            menu_options_topics {
              id
              name
              max_option_checks
              price
              required
              menu_options {
                id
                name
                price
              }
            }
          }
        }
      `
      )
      .subscribe({
        next: (response: any) => {
          this.tabIndex++;
          this.menuCategoriesItems = response.data.menuCategoriesItems;
        },
      });
  }

  selectCategory(menuCategory: any): void {
    this.selectedCategory = menuCategory;
    this.getItems(menuCategory.id);
  }

  selectItem(menuCategoriesItem): void {
    this.graphQLService
      .query(
        gql`
    {
      menuCategoriesItems (idEq:${menuCategoriesItem.id}){
        id
        name
        description
        photo
        price
        active
        menu_options_topics {
          id
          name
          max_option_checks
          price
          required
          menu_options {
            id
            name
            price
          }
        }
      }
    }`
      )
      .subscribe({
        next: (response: any) => {
          this.selectedItem = response.data.menuCategoriesItems[0];
          if (this.isItemEdit) {
            this.showAddItemModal();
          }
          this.tabIndex = 3;
          this.calculateItemTotal();
          this.addToOrderDetailsValidation();
        },
        error: (error) => {},
      });
  }

  selectOption(
    option: any,
    topic: any,
    topicIndex: number,
    optionIndex: number,
    event: any
  ): void {
    if (topic.max_option_checks == 1) {
      this.selectedItem.menu_options_topics[topicIndex].isFocused = true;
      this.selectedItem.menu_options_topics[topicIndex].menu_options.forEach(
        (option: any, index: number) => {
          this.selectedItem.menu_options_topics[topicIndex].menu_options[index][
            "isChecked"
          ] = false;
        }
      );
      this.selectedItem.menu_options_topics[topicIndex].menu_options[
        optionIndex
      ]["isChecked"] = true;
    } else {
      if (
        this.selectedItem.menu_options_topics[topicIndex]
          ?.selectedOptionCount == undefined
      ) {
        this.selectedItem.menu_options_topics[
          topicIndex
        ].selectedOptionCount = 0;
      }

      if (event.target.checked) {
        this.selectedItem.menu_options_topics[topicIndex].selectedOptionCount++;
        this.selectedItem.menu_options_topics[topicIndex].menu_options[
          optionIndex
        ].isChecked = true;
      } else {
        this.selectedItem.menu_options_topics[topicIndex].selectedOptionCount--;
        this.selectedItem.menu_options_topics[topicIndex].menu_options[
          optionIndex
        ].isChecked = false;
      }
    }
    this.addToOrderDetailsValidation();

    this.calculateItemTotal();
  }

  calculateItemTotal(): void {
    let subTotal = this.selectedItem.price;
    this.order_details_options = [];
    console.log(this.selectedItem);

    if (
      this.selectedItem.menu_options_topics &&
      this.selectedItem.menu_options_topics.length > 0
    ) {
      this.selectedItem.menu_options_topics.forEach((topic: any) => {
        topic.menu_options.forEach((option: any) => {
          if (option.isChecked) {
            subTotal += option.price;
            this.order_details_options.push({ menu_options_id: option.id });
          } else {
            option.isChecked = false;
          }
        });
      });
    }
    this.itemTotal = subTotal * this.amount;
  }

  addToOrderDetailsValidation(): void {
    if (this.selectedItem.menu_options_topics.length == 0) {
      this.addToOrderDetailsDisabled = false;
    } else {
      this.addToOrderDetailsDisabled =
        this.selectedItem.menu_options_topics.some((topic: any) => {
          if (topic.required && topic?.isFocused == undefined) {
            return true;
          } else {
            return false;
          }
        });
    }
  }

  addToOrderDetails(): void {
    this.addItemModalIsVisible = false;
    let orderDetail = {
      amount: this.amount,
      menu_categories_itemId: this.selectedItem.id,
      notes: this.notes,
      total: this.itemTotal,
      order_details_options: this.order_details_options,
    };

    if (this.isOrderEdit) {
      if (this.isItemEdit) {
        this.editOrderDetailGraph(orderDetail);
      } else {
        this.addOrderDetailGraph(orderDetail);
      }
    } else {
      if (this.isItemEdit) {
        this.orderDetails[this.editItemIndex] = orderDetail;
        this.orderDetailsData[this.editItemIndex] = {
          ...this.orderDetailsData[this.editItemIndex],
          item: this.selectedItem,
        };
      } else {
        this.orderDetails.push(orderDetail);
        this.orderDetailsData.push({
          menuCategoryName: this.selectedCategory.name,
          item: this.selectedItem,
        });
      }
      this.calculateSubTotal();
      setTimeout(() => {
        this.resetItemModal();

        this.genericService.showNotification(
          "success",
          "اضافة طبق",
          "تمت إضافة طبق بنجاح"
        );
      }, 500);
    }
  }

  resetItemModal() {
    this.tabIndex = 1;
    this.menuCategoriesItems = [];
    this.selectedCategory = undefined;
    this.selectedItem = undefined;
    this.amount = 1;
    this.notes = "";
    this.isItemEdit = false;
  }

  addOrderDetailGraph(orderDetail) {
    this.graphQLService
      .query(ADD_ORDER_DETAIL_QUERY, {
        orderId: this.order_id,
        ...orderDetail,
      })
      .subscribe({
        next: (response: any) => {
          if (response.data.orderDetails) {
            this.orderDetails.push(orderDetail);
            this.orderDetailsData.push({
              menuCategoryName: this.selectedCategory.name,
              item: this.selectedItem,
            });
            this.calculateSubTotal();
            setTimeout(() => {
              this.resetItemModal();

              this.genericService.showNotification(
                "success",
                "اضافة طبق",
                "تم الاضافة بنجاح"
              );
            }, 500);
          } else {
            this.genericService.showNotification(
              "error",
              "اضافة طبق",
              "حدث خطأ ما"
            );
          }
        },
        error: (error) => {
          this.genericService.showNotification(
            "error",
            "اضافة طبق",
            "حدث خطأ ما"
          );
        },
      });
  }

  editOrderDetailGraph(orderDetail) {
    this.graphQLService
      .query(EDIT_ORDER_DETAIL_QUERY, {
        id: this.orderDetailId,
        ...orderDetail,
      })
      .subscribe({
        next: (response: any) => {
          if (response.data.orderDetails) {
            this.orderDetails[this.editItemIndex] = orderDetail;
            this.orderDetailsData[this.editItemIndex] = {
              ...this.orderDetailsData[this.editItemIndex],
              item: this.selectedItem,
            };
            this.calculateSubTotal();
            setTimeout(() => {
              this.resetItemModal();

              this.genericService.showNotification(
                "success",
                "تعديل طبق",
                "تم التعديل بنجاح"
              );
            }, 500);
          } else {
            this.genericService.showNotification(
              "error",
              "تعديل طبق",
              "حدث خطأ ما"
            );
          }
        },
        error: (error) => {
          this.genericService.showNotification(
            "error",
            "تعديل طبق",
            "حدث خطأ ما"
          );
        },
      });
  }

  calculateSubTotal(): void {
    this.subTotal = 0;
    this.orderDetails.forEach((orderDetail: any) => {
      this.subTotal += orderDetail.total;
    });
    console.log(this.subTotal, "this.subTotal");

    this.calculateTotal();
  }

  calculateTotal(): void {
    if (
      this.orderForm.controls["order_type_id"].value == 0 &&
      this.selectedBillingAddress
    ) {
      console.log(this.subTotal, "this.subTotal");
      console.log(this.selectedBillingAddress, "this.selectedBillingAddress");

      this.ordersService
        .calculateTotal({
          area_id: +this.selectedBillingAddress?.area_id,
          branch_id: this.orderForm.controls["branch_id"].value,
          Subtotal: this.subTotal,
        })
        .subscribe({
          next: (response: any) => {
            if (!response.Confirgrations?.delivery_cost) {
              this.orderForm.controls["billing_address_id"].setValue(null);
              this.genericService.showNotification(
                "error",
                "لا نوصل لهذا العنوان",
                ""
              );
            } else {
              this.delivery_cost = response.Confirgrations?.delivery_cost;
              this.total = +response.Confirgrations?.Total.toFixed(2);
            }
          },
          error: (error) => {},
        });
    } else {
      this.total = +(this.subTotal * (1 + this.taxes / 100)).toFixed(2);
    }
  }

  getBranches(): void {
    this.graphQLService
      .query(
        gql`
      {
        branches(vendor_idEq:${this.vendor_id}){
          id
          name
        }
      }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.branches = response.data.branches;
        },
        error: (error) => {},
      });
  }
  getTowns(): void {
    this.graphQLService
      .query(
        gql`
    {
      towns(vendor_idEq:${this.vendor_id}){
        id
        name
      }
    }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.towns = response.data.towns;
        },
        error: (error) => {},
      });
  }
  getPaymentTypes(): void {
    this.graphQLService
      .query(
        gql`
          {
            paymenttypes {
              id
              name
            }
          }
        `
      )
      .subscribe({
        next: (response: any) => {
          this.paymentTypes = response.data.paymenttypes;
        },
        error: (error) => {},
      });
  }
  order(): void {
    if (this.orderValidation()) {
      let variables = {
        order_details: this.orderDetails,
        branch_id: this.orderForm.controls["branch_id"].value,
        paymenttype_id: this.orderForm.controls["paymenttype_id"].value,
        order_status_id: this.orderForm.controls["order_status_id"].value,
        taxes: this.taxes,
        total: this.total,
        user_id: [0, 1].includes(this.orderForm.controls["order_type_id"].value)
          ? this.orderForm.controls["user_id"].value
          : undefined,
        billing_address_id:
          this.orderForm.controls["order_type_id"].value == 0
            ? this.orderForm.controls["billing_address_id"].value
            : undefined,
        area_id:
          this.orderForm.controls["order_type_id"].value == 0
            ? this.orderForm.controls["area_id"].value
            : undefined,
        order_type_id: this.orderForm.controls["order_type_id"].value,
        table_id:
          this.orderForm.controls["order_type_id"].value == 2 &&
          this.orderForm.controls["table_id"].value
            ? this.orderForm.controls["table_id"].value
            : undefined,
        waiter_id:
          this.orderForm.controls["order_type_id"].value == 2 &&
          this.orderForm.controls["waiter_id"].value
            ? this.orderForm.controls["waiter_id"].value
            : undefined,
        delivery_id:
          this.orderForm.controls["order_type_id"].value == 0 &&
          this.orderForm.controls["delivery_id"].value
            ? this.orderForm.controls["delivery_id"].value
            : undefined,
        platform_id: 6,
      };
      this.graphQLService.query(ADD_ORDER_QUERY, variables).subscribe({
        next: (response: any) => {
          if (response.data.orders) {
            this.genericService.showNotification(
              "success",
              "الطلبات",
              "تمت إضافة الطلب بنجاح"
            );
            this.router.navigateByUrl("/orders-admin/all");
          } else {
            this.genericService.showNotification(
              "error",
              "الطلبات",
              "حدث خطأ ما"
            );
          }
        },
        error: (error) => {
          this.genericService.showNotification(
            "error",
            "الطلبات",
            "حدث خطأ ما"
          );
        },
      });
    }
  }
  orderValidation(): boolean {
    console.log(this.orderForm.value);

    if (!this.orderForm.controls["branch_id"].value) {
      this.genericService.showNotification("error", "اضافة طلب", "اختر الفرع");
      return false;
    }
    if (
      !this.orderForm.controls["area_id"].value &&
      this.orderForm.get("order_type_id").value == 0
    ) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "اختر المنطقة"
      );
      return false;
    } else if (!this.orderForm.controls["paymenttype_id"].value) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "حدد طريقة الدفع"
      );
      return false;
    } else if (this.orderForm.controls["order_type_id"].value == null) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "حدد  نوع الطلب"
      );
      return false;
    } else if (
      !this.orderForm.controls["user_id"].value &&
      this.orderForm.get("order_type_id").value == 0
    ) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "اختر العميل "
      );
      return false;
    } else if (
      !this.orderForm.controls["billing_address_id"].value &&
      this.orderForm.get("order_type_id").value == 0
    ) {
      this.genericService.showNotification(
        "error",
        "اضافة طلب",
        "اختر عنوان التوصيل "
      );
      return false;
    }
    return true;
  }

  deleteOrderDetail(index: number, orderDetail: any): void {
    console.log(orderDetail?.id, "order_Detail_id");
    if (this.isOrderEdit && orderDetail?.id) {
      this.deleteOrderDetailGraph(orderDetail?.id, index);
    } else {
      this.orderDetails.splice(index, 1);
      this.orderDetailsData.splice(index, 1);
    }
    this.calculateSubTotal();
  }

  deleteOrderDetailGraph(id, index) {
    this.graphQLService.query(DELETE_ORDER_DETAIL_QUERY, { id }).subscribe({
      next: (response: any) => {
        this.genericService.showNotification(
          "success",
          "تفاصيل الطلب",
          "تم مسح تفصيل الطلب بنجاح"
        );
        this.orderDetails.splice(index, 1);
        this.orderDetailsData.splice(index, 1);
        this.calculateSubTotal();
      },
      error: (error) => {
        this.genericService.showNotification(
          "success",
          "تفاصيل الطلب",
          "حدث خطأ ما"
        );
      },
    });
  }
  selectUser(): void {
    this.selectedUser = undefined;
    this.selectedBillingAddress = undefined;

    if (this.orderForm.controls["user_id"].value) {
      this.graphQLService
        .query(
          gql`
      {
        users(id:${this.orderForm.controls["user_id"].value}){
          id
          name
          username
          mobile
          email
          first_name
          last_name
          user_group_id
        }
      }
    `
        )
        .subscribe({
          next: (response: any) => {
            if (response.data.users.length) {
              this.selectedUser = response.data?.users[0];
            }
            this.getBillingAddresses();
          },
          error: (error) => {},
        });
    }
  }

  getBillingAddresses(): void {
    if (this.selectedUser?.id) {
      this.graphQLService
        .query(
          gql`
      {
        billingAddress(user_idEq:${this.selectedUser.id}){
          areas {
            id
            name
          }
          address
          building_number
          floor_number
          apartment_number
          id
        }
      }
    `
        )
        .subscribe({
          next: (response: any) => {
            this.billingAddresses = response.data.billingAddress;
          },
          error: (error) => {},
        });
    }
  }

  editItem(orderDetail: any, orderDetailsData: any, index: number) {
    console.log(orderDetail);
    console.log(orderDetailsData);

    this.orderDetailId = orderDetail.id;
    this.amount = orderDetail.amount;
    this.editItemIndex = index;
    this.notes = orderDetail.notes;
    this.selectedItem = orderDetailsData.item;

    console.log(
      this.selectedItem.menu_options_topics,
      "selectedItem.menu_options_topics"
    );

    if (this.selectedItem.menu_options_topics?.length) {
      let options = [];
      this.orderDetails[index].order_details_options.forEach((option: any) => {
        options.push(option.menu_options_id);
        console.log(option, "order_details_options");
      });

      this.selectedItem.menu_options_topics.forEach(
        (topic: any, topicIndex: number) => {
          if (topic.max_option_checks == 1) {
            this.selectedItem.menu_options_topics[topicIndex].isFocused = true;
          }
          topic.menu_options.forEach((option: any, optionIndex: number) => {
            if (options.includes(option.id)) {
              this.selectedItem.menu_options_topics[topicIndex].menu_options[
                optionIndex
              ].isChecked = true;
            }
          });
        }
      );
    }

    this.addToOrderDetailsValidation();
    this.calculateItemTotal();

    this.tabIndex = 3;
    this.isItemEdit = true;
    this.showAddItemModal();

    console.log(orderDetail);
  }
  selectBillingAddress(): void {
    this.selectedBillingAddress = undefined;
    if (this.orderForm.controls["billing_address_id"].value) {
      this.graphQLService
        .query(
          gql`
      {
        billingAddress(idEq:${this.orderForm.controls["billing_address_id"].value}){
          areas {
            id
            name
          }
          address
          building_number
          floor_number
          apartment_number
          first_name
          last_name
          phone
          area_id
          id
          latitude
          longitude
          notes
        }
      }
    `
        )
        .subscribe({
          next: (response: any) => {
            this.selectedBillingAddress = response.data.billingAddress[0];
            console.log(
              this.selectedBillingAddress,
              "this.selectedBillingAddress"
            );

            if (this.isOrderEdit) {
              this.calculateSubTotal();
            }
          },
          error: (error) => {},
        });
    } else {
      if (this.isOrderEdit) {
        this.calculateSubTotal();
      }
    }
  }

  handleEditOrderData(order_id) {
    this.graphQLService
      .query(
        gql`
          {
            orders (id : ${+order_id}){
              ${ORDER_dATA}
            }
          }
        `
      )
      .subscribe({
        next: (response: any) => {
          let order: any = response?.data?.orders[0];
          this.orderForm.patchValue({
            ...order,
            canceled_reason: Number(order.canceled_reason),
          });
          this.orderDetails = order?.order_details;
          this.orderNotes = order.notes;
          this.orderDetails.forEach((detail: any) => {
            this.orderDetailsData.push({
              menuCategoryName:
                detail.menu_categories_items.menu_categories.name,
              item: detail.menu_categories_items,
            });
          });

          this.selectUser();
          this.selectBillingAddress();
        },
        error: (error) => {},
      });
  }

  editOrder() {
    console.log(this.orderForm.value);

    if (this.orderValidation()) {
      let variables = {
        id: +this.order_id,
        branch_id: this.orderForm.controls["branch_id"].value,
        paymenttype_id: this.orderForm.controls["paymenttype_id"].value,
        taxes: this.taxes,
        order_status_id: this.orderForm.controls["order_status_id"].value,
        total: this.total,
        user_id: [0, 1].includes(this.orderForm.controls["order_type_id"].value)
          ? this.orderForm.controls["user_id"].value
          : undefined,
        billing_address_id:
          this.orderForm.controls["order_type_id"].value == 0
            ? this.orderForm.controls["billing_address_id"].value
            : undefined,
        area_id:
          this.orderForm.controls["order_type_id"].value == 0
            ? this.orderForm.controls["area_id"].value
            : undefined,
        notes: this.orderNotes,
        order_type_id: this.orderForm.controls["order_type_id"].value,
        table_id:
          this.orderForm.controls["order_type_id"].value == 2 &&
          this.orderForm.controls["table_id"].value
            ? this.orderForm.controls["table_id"].value
            : undefined,
        waiter_id:
          this.orderForm.controls["order_type_id"].value == 2 &&
          this.orderForm.controls["waiter_id"].value
            ? this.orderForm.controls["waiter_id"].value
            : undefined,
        delivery_id:
          this.orderForm.controls["order_type_id"].value == 0 &&
          this.orderForm.controls["delivery_id"].value
            ? this.orderForm.controls["delivery_id"].value
            : undefined,
        canceled_reason: this.orderForm.controls["canceled_reason"].value
          ? this.orderForm.controls["canceled_reason"].value.toString()
          : undefined,
      };

      console.log(this.orderDetails);

      this.graphQLService.query(EDIT_ORDER_QUERY, variables).subscribe({
        next: (response: any) => {
          if (response.data.orders) {
            this.genericService.showNotification(
              "success",
              "الطلبات",
              "تمت تعديل الطلب بنجاح"
            );
            this.closeEditOrderModal.emit();
          } else {
            this.genericService.showNotification(
              "error",
              "الطلبات",
              "حدث خطأ ما"
            );
          }
        },
        error: (error) => {
          this.genericService.showNotification(
            "error",
            "الطلبات",
            "حدث خطأ ما"
          );
        },
      });
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem("order_id");
  }
}
