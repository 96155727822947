import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';


@Injectable({
  providedIn: 'root'
})
export class RestaurantApprovalAssginemtService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(`${environment.endpoint}/restaurantApprovalAssginemt/index`).toPromise();
  }

  add(obj) {
    return this.http.post<any>(`${environment.endpoint}/restaurantApprovalAssginemt/add`, obj).toPromise();
  } 
  
  edit(user) {
    return this.http.post(`${environment.endpoint}/restaurantApprovalAssginemt/edit/${0}`, user).toPromise();
  }  

  delete(id) {
    return this.http.get(`${environment.endpoint}/restaurantApprovalAssginemt/delete/${id}`).toPromise();
  }  

  GetAllApproveRestuarant() {
    return this.http.get(`${environment.endpoint}/restaurantApprovalAssginemt/GetAllApproveRestuarant`).toPromise();
  }

}

