import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { filter } from "rxjs/operators";
// import { getMessaging } from 'firebase-admin/messaging';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  data: string = `
  id
  area_id
  photo
  created
  created_by
  modified
  modified_by
  seen
  text
  title
  user_group_id
  user_id
  vendor_id
  `;
  constructor(private http: HttpClient, private apollo: Apollo) {}

  GetNotifications() {
    return this.http
      .get<any>(`${environment.endpoint}/notifications.json`)
      .toPromise();
  }

  // get(params = {}) {
  //   return this.http.get(`${environment.endpoint}/notification/index`, {params: params}).toPromise();
  // }
  get(page: number, limit: number, filter?: string) {
    return this.apollo.watchQuery({
      query: gql`
      {
        notifications(pages:[${page + "," + limit}]${
        filter ? "," + filter : ""
      }){
          id
          user_id
          text
          title
          vendor_id
          photo
          created
          
        }
      }
      `,
    }).valueChanges;
  }
  add(obj) {
    return this.http
      .post(`${environment.endpoint}/notification/add`, obj)
      .toPromise();
  }

  sendToDevice(title, message, user_id,photo) {
    return this.http
      .post(`${environment.endpoint}/notification/sendToDevice`, {
        title,
        message,
        user_id,
        photo
      })
      .toPromise();
  }
  sendToAll(title, message,photo) {
    return this.http
      .post(`${environment.endpoint}/Notification/sendToAllUsers`, {
        title,
        message,
        photo
       })
      .toPromise();
  }

  edit(obj, id) {
    return this.http
      .post(`${environment.endpoint}/notification/edit/${id}`, obj)
      .toPromise();
  }

  delete(id) {
    return this.http
      .get(`${environment.endpoint}/notification/delete/${id}`)
      .toPromise();
  }

  sendNotificationFS(to: string, title, body, image, resturant_id?) {
    let data = {
      to,
      mutable_content: true,
      data: {
        resturant_id,
        "attachment-url": image,
      },
      notification: {
        click_action: "NotificationCategory",
        body,
        title,
        sound: "default",
      },
    };
    return this.http
      .post("https://fcm.googleapis.com/fcm/send", data, {
        headers: {
          Authorization:
            "key=AAAAcaajcHI:APA91bHJnCKOTcPn2yzNIPaSDw_FIhMqQf9amU-Tf4pCuUeDfNMWvoq_NyVoVPrMWqXhVLv41YgcBzdUuktoJU9VritE_YLKWhxd8nrEcFSK9U9tiymsCP3qG2EogioETZumGUWKtWdR",
          "Content-Type": "application/json",
        },
      })
      .toPromise();
  }

  getNotificationsWithGraphQL(filter: string, page: number, limit: number) {
    return this.apollo.watchQuery({
      query: gql`
    {
      notificationss (pages:[${page - 1 + "," + limit}]${
        filter ? "," + filter : ""
      }) {
        ${this.data}
      }
    }`,
    }).valueChanges;
  }

  sendToTopic(title: string, message: string, topic: string,photo) {
    return this.http
      .post(`${environment.endpoint}/notification/sendToTopic`, {
        title,
        message,
        topic,
        photo
      })
  }
}
