import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { options } from "src/app/options";
import { map } from "rxjs/operators";
import { Apollo, gql } from "apollo-angular";
import { GraphQLService } from "../graph/graphql.service";
import { DocumentNode } from "graphql";

@Injectable({
  providedIn: "root",
})
export class DeliversService {
  vendor_id = JSON.parse(localStorage.getItem("user")).restaurants.id;
  constructor(
    private http: HttpClient,
    private graphQLService: GraphQLService
  ) {}

  get(data) {
    return this.http
      .post(`${environment.endpoint}/delivers/indexForBranch`, data)
      .toPromise();
  }
  getForDropdown() {
    return this.graphQLService.query(
      gql`
      {
        delivers(vendor_idEq:${this.vendor_id}){
          id
          name
        }
      }
      `
    );
  }
  getReports(body) {
    return this.http
      .post(`${environment.endpoint1}/reports/deliveryReport`, body)
      .toPromise();
  }
  getRequests() {
    return this.http
      .get(`${environment.endpoint}/delivers/indexForApproval`)
      .toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/delivers/add`, obj);
  }

  edit(user, id) {
    return this.http
      .post(`${environment.endpoint}/delivers/edit/${id}`, user)
      .toPromise();
  }

  editRequest(request, id) {
    return this.http
      .post(`${environment.endpoint}/delivers/edit/${id}`, request)
      .toPromise();
  }

  delete(id) {
    return this.http
      .get(`${environment.endpoint}/delivers/delete/${id}`)
      .toPromise();
  }
  downloadAttachments(id) {
    return this.http
      .get<any>(`${environment.endpoint}/delivers/downloadAttachmens/${id}`)
      .pipe(map((d) => d.data as string))
      .toPromise();
  }

  addDeliveryGraphQL(query: DocumentNode, variable: any) {
    return this.graphQLService.query(query, variable);
  }
  getDeliveryGraphQL(query: DocumentNode) {
    return this.graphQLService.query(query);
  }
}
