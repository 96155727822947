<nz-breadcrumb [nzAutoGenerate]="true" style="color: white">
  <nz-breadcrumb-item>
    <i nz-icon nzType="home"></i>
  </nz-breadcrumb-item>

  <nz-breadcrumb-item style="color: white"> تفاصيل المطعم </nz-breadcrumb-item>
</nz-breadcrumb>

<a
  routerLink="/rest-attachment/{{ id }}"
  class="btn btn-danger"
  style="margin: 10px 0"
>
  ملفات المطعم
</a>

<mat-tab-group
  dynamicHeight
  [(selectedIndex)]="selectedTabIndex"
  (click)="save_resturant_tab_index()"
  [color]="'white'"
>
  <!-- tab #1 -->
  <mat-tab label="معلومات المتجر" style="color: black">
    <app-restuarants-details></app-restuarants-details>
  </mat-tab>

  <!-- tab #2 -->
  <mat-tab label="تخصصات المتجر" style="color: black">
    <!--disabled-->
    <div class="example-large-box mat-elevation-z4">
      <app-restaurant-cuisines></app-restaurant-cuisines>
    </div>
  </mat-tab>

  <!-- tab #3 -->
  <mat-tab
    [disabled]="MenuDisabled"
    label="قائمة الطعام والمنتجات"
    style="color: black"
  >
    <!--disabled-->

    <div class="example-large-box mat-elevation-z4">
      <app-MainMenuindex></app-MainMenuindex>
    </div>

    <!-- <nz-modal
        [nzStyle]="{ direction:'ltr','text-align':'right'}"
        [(nzVisible)]="ShowApproveDialog"
        nzTitle="تاكيد التفعيل"
        nzOkText="تاكيد"
        nzCancelText="الغاء"
        (nzOnOk)="ApproveMenuFunc()"
        (nzOnCancel)="handleCancelApprove()"
        >
        {{this.RestuarantDetails.restuarant?.Department?.name}}هل ترغب في موافقة ادارة ال
    </nz-modal>

    <nz-modal
    [nzStyle]="{direction:'ltr','text-align':'right'}"
    [(nzVisible)]="ShowRefuseDialog"
    nzTitle="رفض التفعيل"
    nzOkText="تاكيد"
    nzCancelText="الغاء"
    (nzOnOk)="this.RestuarantDetails.RefuseFunc()"
    (nzOnCancel)="handleCancelApprove()"
    >
    هل ترغب في رفض الطلب

    من فضلك حدد السبب
    <br>

    <div class="input-group-lg col-6 mx-auto">
    <nz-select [(ngModel)]="this.RestuarantDetails.selectedRefuseReasonId"    style="width: 100%;">
      <nz-option *ngFor="let reason of this.RestuarantDetails.RefuseReasons; let i = index" [nzLabel]="reason.name" [nzValue]="reason.id"></nz-option>
    </nz-select>
    </div>
    </nz-modal> -->
  </mat-tab>
</mat-tab-group>
