import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';


@Injectable({
  providedIn: 'root'
})
export class EnternalNotificationsService {

  constructor(private http: HttpClient) { }

  get(params) {
    const isManager = JSON.parse(localStorage.getItem("department_positions"))
    return this.http.post(`${environment.endpoint}/EternalNotifications/getNotifyByroleOrUser`,
    isManager ? {isManager : 1} : {}, {params} ).toPromise();
  }

  eternalNotifications() {
    return this.http.get(`${environment.endpoint}/EternalNotifications/index`).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/EternalNotifications/add`, obj).toPromise();
  }

  edit(user, id) {
    return this.http.post(`${environment.endpoint}/EternalNotifications/edit/${id}`, user).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/EternalNotifications/delete/${id}`).toPromise();
  }


}
