<div class="card">
  <div class="card-body">
    <div class="d-flex align-items-center">
      <div class="m-r-10">
        <span class="btn btn-circle btn-lg {{color}}">
          <i class="{{icon}}"></i>
        </span>
      </div>
      <div>{{title}}</div>
      <div class="ml-auto">
        <h2 class="m-b-0 font-light">{{ value }}
        </h2>
      </div>
    </div>
  </div>
</div>
