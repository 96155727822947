<form [formGroup]="orderForm">
  <div class="p-fluid bg-white1 row px-1 py-3">
    <div class="col-md-12 mb-3">
      <h2 class="m-0 text-black-alpha-90">البيانات الأساسية</h2>
    </div>
    <div class="field col-md-4 mb-4">
      <span class="p-float-label">
        <p-dropdown
          [options]="branches"
          formControlName="branch_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="branch_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="branch_id">الفرع</label>
      </span>
    </div>

    <div class="field col-md-4 mb-4">
      <span class="p-float-label">
        <p-dropdown
          [options]="orderTypes"
          formControlName="order_type_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="order_type_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="order_type_id">نوع الطلب</label>
      </span>
    </div>
    <div
      class="field col-md-4 mb-4"
      *ngIf="orderForm.get('order_type_id').value == 0"
    >
      <span class="p-float-label">
        <p-dropdown
          [options]="towns"
          formControlName="area_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="area_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="area_id">المنطقة</label>
      </span>
    </div>
    <div class="field col-md-4 mb-4" *ngIf="isOrderEdit">
      <span class="p-float-label">
        <p-dropdown
          [options]="orderStatus"
          formControlName="order_status_id"
          optionLabel="name"
          optionValue="id"
          (ngModelChange)="handleOrderStatusChange($event)"
          styleClass="w-100"
          inputId="order_status_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="order_status_id">حالة الطلب</label>
      </span>
    </div>
    <div class="field col-md-4 mb-4" *ngIf="showCancelBuutton">
      <span class="p-float-label">
        <p-dropdown
          [options]="cancelReasons"
          formControlName="canceled_reason"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="canceled_reason"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="order_status_id">سبب الالغاء</label>
      </span>
    </div>
    <div class="field col-md-4 mb-4">
      <span class="p-float-label">
        <p-dropdown
          [options]="paymentTypes"
          formControlName="paymenttype_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="paymenttype_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="paymenttype_id">طريقة الدفع</label>
      </span>
    </div>

    <div
      class="field col-md-4 mb-4"
      *ngIf="
        orderForm.get('branch_id').value &&
        orderForm.get('order_type_id').value == 0 &&
        isOrderEdit
      "
    >
      <span class="p-float-label">
        <p-dropdown
          [options]="deliveries"
          formControlName="delivery_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="delivery_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="delivery_id">الموصل</label>
      </span>
    </div>

    <div
      class="field col-md-6 mt-4"
      *ngIf="
        orderForm.get('branch_id').value &&
        orderForm.get('order_type_id').value == 2
      "
    >
      <span class="p-float-label">
        <p-dropdown
          [options]="waiters"
          formControlName="waiter_id"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          inputId="waiter_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        ></p-dropdown>
        <label for="waiter_id">الويتر</label>
      </span>
    </div>
    <div
      class="col-md-6 mt-4"
      *ngIf="
        orderForm.get('branch_id').value &&
        orderForm.get('order_type_id').value == 2
      "
    >
      <span class="p-float-label">
        <p-dropdown
          [options]="tables"
          formControlName="table_id"
          optionValue="id"
          optionLabel="number"
          styleClass="w-100"
          inputId="table_id"
          [showClear]="true"
          [autoDisplayFirst]="false"
        >
          <ng-template let-table pTemplate="item">
            <p class="m-0">
              {{ table.number + (table.isAvail == true ? "متاح" : "مشغول") }}
            </p>
          </ng-template>
        </p-dropdown>
        <label for="table_id">الطاولة</label>
      </span>
    </div>
  </div>
</form>
