import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NzModalRef } from 'ng-zorro-antd';
import { EditComponent } from './add/edit/edit.component';
import { IndexComponent } from './index/index.component';
import { MyVendorsRepInvComponent } from './my-vendors-rep-inv/my-vendors-rep-inv.component';
import { MyVendorsReportComponent } from './my-vendors-report/my-vendors-report.component';


const routes: Routes = [ {
  path: '',
  children: [
      {
          path: 'all',
          component: IndexComponent,
          data: {
              title: 'المطاعم',
          },
          resolve: {
              //offers: AllOffersResolver,
          }
      },
      {
          path: 'report',
          component: MyVendorsReportComponent,
          data: {
              title: 'تقارير المطاعم',
          },

      },
      {
          path: 'add',
          component: EditComponent,
          data: {
              title: 'اضافة المطاعم',
          }

      },
      {
          path: 'edit',
          component: EditComponent,
          data: {
              title: 'تعديل المطاعم',
          },
      },
      {
          path: 'rep-inv/:id/:name',
          component: MyVendorsRepInvComponent,
          data: {
              title: 'تقرير و فواتير المطعم',
          },
      }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyVendorsRouterModule { }
