import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NzModalService, NzModalRef } from "ng-zorro-antd";
import { UserGroup } from "src/core/enums/user.group.enum";
import { GenericService } from "src/core/services/generic/generic.service";
import { UsersService } from "src/core/services/users/users.service";
import { UsersFormComponent } from "src/app/main/users/users-form/users-form.component";

@Component({
  selector: "app-branches-directors",
  templateUrl: "./branches-directors.component.html",
  styleUrls: ["./branches-directors.component.scss"],
})
export class BranchesDirectorsComponent implements OnInit {
  branchId;
  loading = true;
  users: any;
  heads = ["الاسم", "رقم الهاتف", "الوظيفة", "Actions"];
  userGroup = UserGroup;
  page = 1;
  limit = 5;

  constructor(
    public service: UsersService,
    public NZmodalService: NzModalService,
    public modal: NzModalRef,
    public generic: GenericService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  async delteUser(id, index) {
    this.loading = true;
    const data = await this.service.deleteUser(id);
    this.getUsers();
    this.loading = false;
    this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
  }

  openAddDirectorModal() {
    const modalRef = this.NZmodalService.create({
      nzTitle: " مديرين  للفرع",
      nzContent: UsersFormComponent,
      nzWidth: "800px",
      nzFooter: null,
    });
    modalRef.afterClose.subscribe((result) => {
      this.getUsers();
    });
    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.GroupId = 4;
      instance.BranchId = this.branchId;
    }, 2000);
  }

  async getUsers() {
    this.loading = true;

    setTimeout(async () => {
      const users = await this.service.getUsersBygroup({
        user_group_id: 4,
        branch_id: this.branchId,
      });
      this.users = users as any;
      this.loading = false;
    }, 1);
  }

  editUser(user) {
    const obj = JSON.stringify(user);
    const modalRef = this.NZmodalService.create({
      nzTitle: " مديرين  للفرع",
      nzContent: UsersFormComponent,
      nzWidth: "800px",
      nzFooter: null,
    });
    modalRef.afterClose.subscribe((result) => {
      this.getUsers();
      console.log(result);
    });

    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.GroupId = 4;
      instance.BranchId = this.branchId;
      instance.UserObject(obj);
    }, 1000);
  }

  onPageChange(page) {
    this.page = page;
    this.getUsers();
  }

  onSizeChange(limit) {
    this.limit = limit;
    this.getUsers();
  }
}
