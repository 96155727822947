import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { DropdownModule } from "primeng/dropdown";
import { PaginatorModule } from "primeng/paginator";
import { DataViewModule } from "primeng/dataview";
import { ProgressBarModule } from "primeng/progressbar";
import { CalendarModule } from "primeng/calendar";
import { AccordionModule } from "primeng/accordion";

import { ScrollPanelModule } from "primeng/scrollpanel";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { FileUploadModule } from "primeng/fileupload";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PasswordModule } from "primeng/password";
import { ToolbarModule } from "primeng/toolbar";
import { InputMaskModule } from "primeng/inputmask";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ConfirmationService } from "primeng/api";
import { ImageModule } from "primeng/image";
import { InputSwitchModule } from "primeng/inputswitch";
import { CardModule } from "primeng/card";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TagModule } from "primeng/tag";
import { DividerModule } from "primeng/divider";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { AutoCompleteModule } from "primeng/autocomplete";
import { MultiSelectModule } from "primeng/multiselect";
import { TooltipModule } from 'primeng/tooltip';

const primengModules = [
  OverlayPanelModule,
  DropdownModule,
  PaginatorModule,
  DataViewModule,
  ProgressBarModule,
  CalendarModule,
  ScrollPanelModule,
  DialogModule,
  InputTextModule,
  FileUploadModule,
  TableModule,
  AccordionModule,
  ToastModule,
  ConfirmDialogModule,
  PasswordModule,
  ToolbarModule,
  InputMaskModule,
  ConfirmPopupModule,
  ImageModule,
  InputSwitchModule,
  CardModule,
  InputTextareaModule,
  TagModule,
  DividerModule,
  MessagesModule,
  MessageModule,
  AutoCompleteModule,
  MultiSelectModule,
  TooltipModule
];
@NgModule({
  declarations: [],
  imports: [CommonModule, primengModules],
  exports: [primengModules],
  providers: [ConfirmationService],
})
export class PrimengModule {}
