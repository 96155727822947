import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserGroup } from "src/core/enums/user.group.enum";
import { BindingService } from "src/core/services/binding/binding.service";
import { MenuCategories } from "src/core/services/cities/citiesModel";
import { GenericService } from "src/core/services/generic/generic.service";
import { MinMenuService } from "src/core/services/min-menu/min-menu.service";
import * as XLSX from "xlsx";
import * as lang from "src/settings/lang";
import * as $ from "jquery";
import { FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-category-modal",
  templateUrl: "./add-category-modal.component.html",
  styleUrls: ["./add-category-modal.component.css"],
})
export class AddCategoryModalComponent implements OnInit {
  @Input() Show = true;
  @Input() CateData = new MenuCategories();
  @Output("closeDV") closeDV: EventEmitter<any> = new EventEmitter();

  RestId = 0;
  userGroup = UserGroup;
  NZmodalService: any;
  imageName = "اختر الصورة";
  image: any = "";
  ImgSrc: any;
  Image: any;
  loading;
  isEdit: boolean = false;
  imgInput: any;
  form!: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: MinMenuService,
    private generic: GenericService,
    private binding: BindingService
  ) {}

  ngOnInit() {
    this.RestId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    console.log(this.CateData);
    if (this.CateData == null) {
      this.CateData = new MenuCategories();
    } else {
      this.isEdit = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes["CateData"] && !changes["CateData"].firstChange) {
      console.log(this.CateData);
      this.ImgSrc = environment.photoPath + "/" + this.CateData.photo;
    }
  }
  handleCancel() {
    this.Show = false;
    this.resetForm();
    this.resetImage();
    this.binding.changeTitle(false);
  }

  async save() {
    let formData = new FormData();
    formData.append("restaurant_id", this.RestId.toString());
    formData.append("name", this.CateData.name);
    formData.append("name_en", this.CateData.name_en);
    formData.append(
      "is_offer",
      this.CateData.is_offer ? this.CateData.is_offer.toString() : "0"
    );
    if (this.CateData.id) formData.append("id", this.CateData.id.toString());
    if (this.image) formData.append("img", this.image);

    console.log(this.CateData);

    const data = await this.service.addCategoryMenu(formData);
    if (data.id != null || data == 1) {
      this.Show = false;
      this.resetForm();
      this.resetImage();
      this.closeDV.emit(false);

      this.generic.showNotification(
        "success",
        "تمت العملية",
        "تمت اضافة القسم بنجاح"
      );

      this.binding.changeTitle(true);
    }
  }
  resetImage() {
    this.imageName = "اختر الصورة";
    this.image = "";
    this.ImgSrc = undefined;
    this.Image = undefined;
    this.imgInput = "";
  }

  onFileChangee(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const ab: ArrayBuffer = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(ab);

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];

      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.addFromXls(data);
      console.log(data);
    };
    reader.readAsArrayBuffer(target.files[0]);
  }

  async addFromXls(data: any[]) {
    const obj: any = {};

    for (let i = 1; i < data.length; i++) {
      obj.name = data[i][0];
      obj.name_en = data[i][1];
      obj.restaurant_id = this.RestId;

      const add = await this.service.addCategoryMenu(obj);

      if (add) {
        this.generic.showNotification(
          "success",
          lang.ar.addNewTitle,
          lang.ar.addNewMsg
        );
        this.Show = false;

        this.binding.changeTitle(true);
      } else {
        this.generic.showNotification(
          "warning",
          "اضافة",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }
    }
  }
  getImageName(files: FileList) {
    let fileName = $("#photo")
      .val()
      .toString()
      .replace(/C:\\fakepath\\/i, "");
    this.imageName = fileName;
    this.image = files.item(0);

    this.Image = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.Image);
    reader.onload = (_event) => {
      this.ImgSrc = reader.result;
    };
  }

  deleteImg() {
    this.ImgSrc = null;
  }

  resetForm(): void {
    this.CateData = {
      id: null,
      name: null,
      name_en: null,
      photo: null,
      towns: null,
      active: null,
      restaurant_id: null,
      hover: null,
      is_offer: null,
    };
  }
}
