import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { NzMessageService, NzNotificationService } from "ng-zorro-antd";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { GeneralStatus } from "src/core/enums/generalStatus.enum";
import { UserGroup } from "src/core/enums/user.group.enum";
import { CategoriesModel } from "src/core/models/MenuModel";
import { environment } from "src/environments/environment";
import { CategoriesService } from "src/core/services/categories/categories.service";
import { CitiesService } from "src/core/services/cities/cities.service";
import { CuisinesService } from "src/core/services/cuisines/cuisines.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { Cuisines } from "src/core/services/offers/OffersModel";
import { RestaurantGeneralStatusService } from "src/core/services/restaurant-general-status/restaurant-general-status.service";
import { RestaurantStatusService } from "src/core/services/restaurant-status/restaurant-status.service";
import { RestaurantTypesService } from "src/core/services/restaurant-type/restaurant-type.service";
import { Restuarant } from "src/core/services/restaurants/RestaurantModel";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { WalletsService } from "src/core/services/Wallets/Wallets.service";
import { WalletTypesService } from "src/core/services/WalletTypes/WalletTypes.service";
import Swal from "sweetalert2";
import { Apollo, gql } from "apollo-angular";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  restaurants: Restuarant[] = [];
  cuisines: Cuisines[] = [];
  categories: CategoriesModel[] = [];
  selectedCat;
  selectedcuisines;
  URL = environment.photoPath;
  offerloader = false;
  loading: boolean = false;
  filterLoading;
  form: FormGroup;
  filterForm: FormGroup;
  tabs: any[] = [];
  cities: any = [];
  restaurantGeneralStatus: any = [];
  restaurantStatus: any = [];
  restaurantTypes: any = [];
  userGroup = UserGroup;
  departmentEnum = DepartmentsEnum;
  generalStatus = GeneralStatus;
  currentTab: number;
  imageSrc: any = "../../assets/images/user.png";
  baseUrl = environment.photoPath;
  CoverSrc: any = "assets/images/noimage.jpeg";
  page = 1;
  limit = 5;
  current_tab_active;
  active_tab = 0;
  loader;
  user_id_value: number;

  constructor(
    private fb: FormBuilder,
    private generic: GenericService,
    private notification: NzNotificationService,
    private Categoriesservice: CategoriesService,
    private citiesService: CitiesService,
    private service: RestaurantsService,
    private navigate: Router,
    private restaurantGeneralStatusService: RestaurantGeneralStatusService,
    private restaurantStatusService: RestaurantStatusService,
    private restaurantTypesService: RestaurantTypesService
  ) {}

  async ngOnInit() {
    if (localStorage.getItem("user_id")) {
      this.user_id_value = Number(localStorage.getItem("user_id"));
    } else {
      this.user_id_value = 0;
    }

    this.RestaurantGeneralStatus();

    this.form = this.fb.group({
      name: [null],
      category_id: [null],
      restaurant_general_status_id: [null],
      restaurants_status_id: [null],
      restaurants_types_id: [null],
      sales_id: [null],
      created: [null],
    });

    this.filterForm = this.fb.group({
      wallet_type_id: [null],
      value: [null],
    });

    /*if (localStorage.getItem("RestaurantId") == "null"){
      const data = await this.service.getRestaurant()
      this.restaurants = data

    }else {
      const data = await this.service.getMyRestaurant(localStorage.getItem("RestaurantId"))
      this.restaurants = [data]
    }*/

    const cuisines = await this.Categoriesservice.getCuisines();
    this.cuisines = cuisines;

    const categories = await this.Categoriesservice.getCategories();
    this.categories = categories;

    const cities = await this.citiesService.get();
    this.cities = cities;

    this.restaurantGeneralStatus =
      await this.restaurantGeneralStatusService.get();
    this.restaurantStatus = await this.restaurantStatusService.get();
    this.restaurantTypes = await this.restaurantTypesService.get();

    let temp = localStorage.getItem("CURRENT_RES_TAB");

    if (temp) {
      this.current_tab_active = temp;
      console.log("temp ", temp, temp == "0");
      if (temp == "0") this.onTabChange(this.tabs[+temp].id);
    } else {
      this.current_tab_active = "0";
      localStorage.setItem("CURRENT_RES_TAB", "0");
    }

    this.check_tab_index();
  }

  check_tab_index() {
    let index = localStorage.getItem("myvendor_tab_index");

    if (index) {
      this.active_tab = Number(index);
    } else {
      this.active_tab = 0;
    }
  }

  openLg(id, RestId) {
    this.navigate.navigate(["/branches/all/" + RestId]);
  }

  cancel(): void {}

  edit(i) {
    localStorage.setItem(
      "restaurant_id_for_edit",
      this.restaurants["results"][i].id
    );
    this.navigate.navigateByUrl("/restaurants/edit");
  }

  async confirm(RestId): Promise<void> {
    const data = await this.service.delete({ id: RestId });

    if (data != 1) {
      this.restaurants.forEach((element, index) => {
        if (element.id == RestId) {
          this.restaurants.splice(index, 1);
        }
      });
      this.notification.error("error", data.toString());
    } else {
      this.notification.success("Done", "تم المسح");
    }
  }

  addOffer(f) {}

  async delete(id, index) {
    this.loading = true;
    this.service.delete(id).then((res) => {
      this.restaurants.splice(index, 1);
      this.loading = false;
      this.notification.success("حذف", "تمت العملية بنجاح");
    });
  }

  async filter() {
    let props: any[] = [
      Boolean(this.form.get("name").value) && {
        "restaurants.name": this.form.get("name").value,
      },
      Boolean(this.form.get("category_id").value) && {
        "restaurants.category_id": this.form.get("category_id").value,
      },
      Boolean(this.form.get("restaurant_general_status_id").value) && {
        "restaurants.restaurant_general_status_id": this.form.get(
          "restaurant_general_status_id"
        ).value,
      },
      Boolean(this.form.get("restaurants_status_id").value) && {
        "restaurants.restaurants_status_id": this.form.get(
          "restaurants_status_id"
        ).value,
      },
      Boolean(this.form.get("restaurants_types_id").value) && {
        "restaurants.restaurants_types_id": this.form.get(
          "restaurants_types_id"
        ).value,
      },
      Boolean(this.form.get("sales_id").value) && {
        "restaurants.sales_id": this.form.get("sales_id").value,
      },
      Boolean(this.form.get("created").value) && {
        "restaurants.created": this.generic.formatDate(
          this.form.get("created").value,
          "filter"
        ),
      },
    ];

    props = props.filter((val) => Boolean(val));
    let obj = { restaurant_general_status_id: this.currentTab };
    props.forEach((val) => (obj = { ...obj, ...val }));
    console.log(obj);
    const data: any = await this.service.FilterRestaurant(obj);
    this.restaurants = data;
  }

  filterGraph() {
    let filter = "";
    let datePipe = new DatePipe("en-US");
    let dateNow = new Date();

    Object.keys(this.form.value).forEach((data) => {
      let value: any = this.form.controls[data].value;
      if (this.form.controls[data].value) {
        if (data == "created") {
          filter +=
            'DateFilter: ["' +
            datePipe.transform(value, "yyyy-MM-dd") +
            '","' +
            datePipe.transform(dateNow, "yyyy-MM-dd") +
            '"],';
        } else if (data == "name") {
          filter += "," + data + 'Like: "' + value + '"';
        } else {
          value = isNaN(value) ? `"${value}"` : value;
          filter += "," + data + "Eq: " + value;
        }
      }
    });

    this.getRestaurantsGraphQl(filter);
  }

  async RestaurantGeneralStatus() {
    this.tabs = await this.service.RestaurantGeneralStatus();
    if (this.tabs.length) {
      this.tabs = this.tabs.reverse();
      this.currentTab = this.tabs[0].id;
      console.log("sad");

      this.onTabChange(this.currentTab);
    }
  }

  resReport(name: string, id: number, type: string) {
    if (type == "report")
      window.open(`/#/restaurants/rep-inv/${id}/${name}`, "_blank");
    if (type == "details") window.open(`/#/restuarantDetails/${id}`, "_blank");
  }

  async onTabChange(tab) {
    this.reset();
    this.currentTab = tab;

    console.log("tab number : ", tab);

    this.getRestaurantsGraphQl("");
  }

  reset() {
    this.form.reset();
    this.filterGraph();
  }
  BackToDepartment(i) {
    if (
      this.restaurants[i].departments_id == this.departmentEnum.quality_check
    ) {
      this.restaurants[i].restaurant_general_status_id =
        this.generalStatus.SetMenuStatus;
    } else if (
      this.restaurants[i].departments_id == this.departmentEnum.Training ||
      this.restaurants[i].departments_id == this.departmentEnum.activate
    ) {
      this.restaurants[i].restaurant_general_status_id =
        this.generalStatus.ActivationStatus;
    }

    this.EditRest(i);
  }
  Archive(i) {
    this.restaurants[i].archived = 1;

    this.EditRest(i);
  }
  async EditRest(i) {
    //if (new validations(document).vlidate()){
    var formData: any = new FormData();

    formData.append("data", JSON.stringify(this.restaurants[i]));

    const data = await this.service.add(formData);
    console.log(data);
    if (data.id > 0 || data > 0) {
      Swal.fire({
        title: "Success!",
        text: "Data has been Upadated",
        icon: "success",
        confirmButtonColor: "#0162e8",
      });
    } else {
      // this.toastService.showToast('error', "لم يتم التسجيل","لم يتم التسجيل")
    }
  }

  async onPageChange(page) {
    this.page = page;
    const filter =
      this.current_tab_active == 6
        ? ""
        : ",restaurant_general_status_idEq:" +
          this.tabs[+this.current_tab_active].id;

    this.getRestaurantsGraphQl(filter);

    // const filter =
    //   this.current_tab_active == 6
    //     ? {}
    //     : {
    //         restaurant_general_status_id:
    //           this.tabs[+this.current_tab_active].id,
    //       };

    // const data: any = await this.service.FilterRestaurant(filter, {
    //   page: this.page,
    //   limit: this.limit,
    // });
    // console.log("data ", data);
    // this.restaurants = data;
  }

  async onSizeChange(limit) {
    this.limit = limit;

    const filter =
      this.current_tab_active == 6
        ? ""
        : ",restaurant_general_status_idEq:" +
          this.tabs[+this.current_tab_active].id;

    this.getRestaurantsGraphQl(filter);

    // const filter =
    //   this.current_tab_active == 6
    //     ? {}
    //     : {
    //         restaurant_general_status_id:
    //           this.tabs[+this.current_tab_active].id,
    //       };
    // const data: any = await this.service.FilterRestaurant(filter, {
    //   page: this.page,
    //   limit: this.limit,
    // });
    // console.log("data ", data);

    // this.restaurants = data;
  }

  tab_changed(event) {
    console.log("fwew", event.index);

    this.active_tab = event.index;

    localStorage.setItem("myvendor_tab_index", String(event.index));
  }

  getRestaurantsGraphQl(filter: string) {
    filter =
      "restaurant_general_status_idEq:" +
      this.currentTab +
      ",user_idEq:" +
      this.user_id_value +
      filter;

    console.log(filter, "filter");

    this.service
      .getRestaurantsWithGraphQL(filter, this.page, this.limit)
      .subscribe({
        next: (data: any) => {
          this.restaurants = data.data.restaurants;
        },
      });
  }
}
