export enum UserGroup {
    Admin = 1,
    Restaurant = 2,
    Client = 3,
    Branch = 4,
    ShipCompany = 5,
    Operations = 7,
    Sales = 8,
    Content = 9,
    Departments = 10,
    SalesDepartment = 11,
    FinanceDepartment = 12,
    LegalDepartment = 13,
    SetMenuDepartment = 14,
    QualityCheckDepartment = 15,
    TrainingDepatment = 16,
    AccountManager = 17,
    Test = 18,
    Activate = 19
}