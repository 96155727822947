export class Order {
    id: number;
    amount: number;
    menu_categories_itemId: number;
    notes: string;
    order_details_options: any;
    total: number;
    price: number;
    name: string;

    constructor(obj: any) {
        this.amount = 1;
        this.menu_categories_itemId = obj.menu_categories_id;
        this.notes = "";
        this.order_details_options = [];
        this.total = obj.price;
        this.price = obj.price;
        this.name = obj.name;
        this.id = obj.id;
    }
}