import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      localStorage.getItem("token") != null &&
      (request.url.startsWith(environment.endpoint) ||
        request.url.startsWith(environment.endpointBranch) ||
        request.url.startsWith(environment.endpoint1) ||
        request.url.startsWith(environment.graphQl))
    ) {
      request = request.clone({
        headers: request.headers
          .set("Authorization", "Bearer " + localStorage.getItem("token"))
          .set(
            "vendor_code",
            JSON.parse(localStorage["user"])?.restaurants?.code
          )
          .set(
            "vendor_id",
            JSON.parse(localStorage["user"])?.restaurants?.id.toString()
          )
          .set("is_vendor", "true"),
      });
    }

    return next.handle(request);
  }
}
