export enum DepartmentsEnum {
    content = 5,
    sales = 6,
    finance = 7,
    legal = 8,
    quality_check = 9,
    Training = 10,
    acount_manager = 11,
    test_acount = 12,
    activate = 13,
    delivery = 16,
    markting = 21,
    customer_service = 18,
    SetMenu = 19,
    admin = 1

}