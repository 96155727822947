import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RestuarantsDetailsRoutingModule } from "./restuarants-details-routing.module";
import { SharedModule } from "src/core/module/shared.module";
import { RestuarantsDetailsComponent } from "./index/restuarants-details.component";
import { RestuarantsDetailsTabsComponent } from "../restuarants-details-tabs/restuarants-details-tabs.component";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { RestaurantCuisinesComponent } from "../restaurant-cuisines/restaurant-cuisines.component";
import { AddCuisines } from "../restaurant-cuisines/add/AddCuisines.component";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { ConfirmationService, MessageService } from "primeng/api";
import { EditComponent } from "../../add/edit/edit.component";
import { AddComponent } from "../../../branches/add/add.component";
import { NzModalServiceModule } from "ng-zorro-antd";
import { UsersFormComponent } from "../../../users/users-form/users-form.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BranchesDirectorsComponent } from "src/app/main/branches/components/branches-directors/branches-directors.component";
import { AccountOrdersDetailsComponent } from "./account-orders-details/account-orders-details.component";
import { OrdersHelper } from "../../../orders/classes/orders-helper";
import { CardDetailsComponent } from "./Card-Details/card-details.component";

@NgModule({
  declarations: [
    RestuarantsDetailsTabsComponent,
    RestuarantsDetailsComponent,
    RestaurantCuisinesComponent,
    AddCuisines,
    BranchesDirectorsComponent,
    AccountOrdersDetailsComponent,
  ],
  entryComponents: [
    CardDetailsComponent,
    AddCuisines,
    EditComponent,
    AddComponent,
    UsersFormComponent,
    BranchesDirectorsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NzTabsModule,
    NzBreadCrumbModule,
    RestuarantsDetailsRoutingModule,
  ],
  providers: [
    MessageService,
    NzModalServiceModule,
    ConfirmationService,
    OrdersHelper,
  ],
})
export class RestuarantsDetailsModule {}
