<div style="position: relative;">
    <!-- <div class="row">
        <div class="col-md-12">
            <div class="card">
                <form [formGroup]="form" style="width: 100%;">
                    <div class="row">
                        <div class="col-md-3">
                            <nz-select
                                style="width: 100%;"
                                nzShowSearch
                                nzAllowClear
                                formControlName="branchId"
                                (ngModelChange)="getReport($event)"
                                [nzSize]="'large'">
                                <nz-option
                                    *ngFor="let branche of branches"
                                    [nzLabel]="branche.name"
                                    [nzValue]="branche.id"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> -->

    <!-- <div class="row text-center">
        <div class="col-12">
            <h3 class="invoice-restaurant" style="padding: 20px 0; margin: 0;">
                التفاصيل المالية:
                {{name}}
            </h3>
        </div>
    </div>     -->

    <div class="row">

        <div class="col-sm-6 col-md-4 col-lg-3">
            <!-- Card -->
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-info">
                                <i class="ti-wallet text-white"></i>
                            </span>
                        </div>
                        <div>
                            اجمالي المبيعات للأسبوع
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.Ordertotals  | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-info">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            طلبات الكاش
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.CashOrders | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <!-- Card -->
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg bg-success">
                                <i class="ti-wallet text-white"></i>
                            </span>
                        </div>
                        <div>
                            طلبات الفيزا
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.visaOrders | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Card -->
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            عدد الطلبات
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.ordercount | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            التخفيض
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.discount }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            العمولة
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.commission | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            إجمالي خصم المحفظة
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.WalletsDiscountTotal | round }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            اجمالي خصم العروض
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.OffersDiscountTotal | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                      <span class="badge position-absolute top-0 right-0 badge-orange">عميل</span>
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            عدد الطلبات الملغية
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.CountClientCancel | round }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                      <span class="badge position-absolute top-0 right-0 badge-orange">عميل</span>
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                          مبلغ الطلبات الملغية
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.TotalClientCancel | round }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                      <span class="badge position-absolute top-0 right-0 badge-orange">مطعم</span>
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                          عدد الطلبات الملغية
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.COUNTVendorCancel | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                      <span class="badge position-absolute top-0 right-0 badge-orange">مطعم</span>
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-success">
                                <i class="mdi mdi-currency-usd text-white"></i>
                            </span>
                        </div>
                        <div>
                            مبلغ الطلبات الملغية
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{count.TotalVendorCancel | round}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="userId == departmentEnum.admin">
          <div class="col-sm-6 col-md-4 col-lg-3" *ngFor="let com of commission">
              <div class="card">
                  <div class="card-body">
                      <div class="d-flex align-items-center">
                          <div class="m-r-10">
                              <span class="btn btn-circle btn-lg btn-success">
                                  <i class="mdi mdi-currency-usd text-white"></i>
                              </span>
                          </div>
                          <div>
                              {{com.name}}
                          </div>
                          <div class="ml-auto">
                              <h2 class="m-b-0 font-light">{{com.commission | round}}</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </ng-container>


    </div>

    <div class="row" *ngIf="payments.length">
        <div class="col-md-12">
            <h4>طرق الدفع</h4>
        </div>

        <div class="col-md-6" *ngFor="let p of payments, let i = index">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-warning">
                                <i class="fas fa-money-bill-alt text-white"></i>
                            </span>
                        </div>
                        <div>
                            {{p.paymenttype.name}}
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light" style="direction: ltr;">{{p.Ordertotals }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <nz-alert
                nzType="info"
                nzShowIcon
                nzMessage="ملاحظة: يقوم النظام بعمل فوترة تلقائية كل 15 يوم حسب المدة الزمنية المعدة مسبقا او عند بلوغ الحد الاقصى."></nz-alert>
        </div>
    </div>   -->

    <!-- <div class="row" style="margin-top: 20px;">
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-primary">
                                <i class="fas fa-arrow-alt-circle-up"></i>
                            </span>
                        </div>
                        <div>
                            فواتير غير مسددة
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{unpaidBills}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-primary">
                                <i class="fas fa-arrow-alt-circle-down"></i>
                            </span>
                        </div>
                        <div>
                            فواتير مستحقة
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{deserved.length}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-primary">
                                <i class="fas fa-money-bill-alt text-white"></i>
                            </span>
                        </div>
                        <div>
                            ارباح الشركة
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light">{{paidBills}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="row">
        <div class="col-12">
            <nz-alert
                nzType="error"
                nzShowIcon
                nzMessage="ملاحظة: يمكنك انشاء الفواتير من خلال اختيار المدة الزمنية و المتجر و من ثم احتساب المعاملات و اصدار الفواتير"></nz-alert>
        </div>
    </div>      -->

    <div class="row" *ngIf="expenses.length" style="margin-top: 20px;">
        <div class="col-md-12">
            <h4>مجموع المصروفات</h4>
        </div>

        <div class="col-md-6" *ngFor="let p of payments, let i = index">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="m-r-10">
                            <span class="btn btn-circle btn-lg btn-danger">
                                <i class="fas fa-money-bill-alt text-white"></i>
                            </span>
                        </div>
                        <div>
                            {{p.paymenttype.name}}
                        </div>
                        <div class="ml-auto">
                            <h2 class="m-b-0 font-light" style="direction: ltr;">{{p.Ordertotals }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- charts -->
    <!-- <div class="row" style="margin-top: 20px;">
        <div class="col-md-6 col-lg-6" > -->
            <!-- ============================================================== -->
            <!-- month sales chart -->
            <!-- ============================================================== -->
            <!-- <app-bar-chart
                [sales]="monthordersForchart || []"
                [color]="'#4798e8'"
                [title]="'مبيعات الشهر'"
                [type]="'line'"
                [selector]="'month-chart'"
                [orderLabelsKey]="'modified'"
                [orderTotalKey]="'Ordertotals'"></app-bar-chart> -->
            <!-- ============================================================== -->
            <!-- month sales chart -->
            <!-- ============================================================== -->
        <!-- </div>
        <div class="col-md-6 col-lg-6"> -->
            <!-- ============================================================== -->
            <!-- week sales chart -->
            <!-- ============================================================== -->
            <!-- <app-bar-chart
                [sales]="weekordersForchart || []"
                [color]="'#22c6ab'"
                [title]="'مبيعات الاسبوع'"
                [type]="'line'"
                [selector]="'week-chart'"
                [orderLabelsKey]="'id'"
                [orderTotalKey]="'Ordertotals'"></app-bar-chart> -->
            <!-- ============================================================== -->
            <!-- week sales chart -->
            <!-- ============================================================== -->
        <!-- </div>
    </div> -->

    <!-- <div class="row" *ngIf="!isRestaurant">
        <div class="col-md-12">
            <div class="card">
                <h3 style="margin-top: 20px; margin-right: 15px;">انشاء فاتورة</h3>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            اختر المدة الزمنية
                        </div>
                        <div class="col-md-6">
                            <nz-range-picker [nzDisabledDate]="disabledDate" [nzAllowClear]="false" [(ngModel)]="date"></nz-range-picker>
                            <button
                                class="btn btn-primary"
                                style="margin-top: 15px;"
                                [disabled]="!date.length"
                                (click)="getFiltredBills()">مسودة الفاتورة</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   -->

    <div class="row" *ngIf="filtred.length">
        <div class="col-md-12">
            <div class="card">
                <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;">
                    مسوده فاتورة {{generic.formatDate(date[0], 'filter')}} - {{generic.formatDate(date[1], 'filter')}}
                </h3>
                <div class="card-body">
                    <div class="table-responsive card custom-br custom-shadow">
                        <nz-table
                            [nzData]="filtred"
                            [nzScroll]="{ x: '240px' }"
                            class="table forms-table table-striped"
                            >
                            <thead>
                              <tr class="bg-light">
                                    <th *ngFor="let head of filtredHeads; let i = index">
                                        {{head}}
                                    </th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let allData of filtred; let i = index">
                                    <td>{{i + 1}}</td>
                                    <td>{{generic.formatDate(date[0], 'filter')}}</td>
                                    <td>{{generic.formatDate(date[1], 'filter')}}</td>
                                    <td>{{allData["ordercount"]}}</td>
                                    <td>
                                        {{(allData?.commission - allData?.visaOrders < 0)
                                            ? 'صادر'
                                            : 'وارد'
                                        }}
                                    </td>
                                    <td>{{(allData?.commission - allData?.visaOrders < 0) ? allData?.commission - allData?.visaOrders * -1 : allData?.commission - allData?.visaOrders}} جنية</td>
                                    <td>
                                        <button class="btn btn-info btn-block" (click)="createInvoice(allData)">انشاء فاتورة</button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;">فواتير مستحقة للشركة</h3>
                <div class="card-body">
                    <div class="table-responsive card custom-br custom-shadow">
                        <nz-table
                            [nzShowPagination]="false"
                            [nzData]="deserved"
                            [nzScroll]="{ x: '240px' }"
                            class="table forms-table table-striped"
                            >
                            <thead>
                              <tr class="bg-light">
                                  <th>#</th>
                                    <th *ngFor="let head of deservedHead; let i = index">
                                        {{head}}
                                    </th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of deserved; let i = index" style="cursor: pointer; direction: ltr;">
                                    <ng-container>
                                        <td style="vertical-align: middle;">{{i + 1}}</td>
                                        <td style="vertical-align: middle;">{{data?.id}}</td>
                                        <td style="vertical-align: middle;">{{data?.branches?.restaurant?.name}}</td>
                                        <td style="vertical-align: middle;">{{data?.branches?.name}}</td>
                                        <td style="vertical-align: middle;">{{GetFromDate(data.month)}}</td>
                                        <td style="vertical-align: middle;">{{data?.month}}</td>
                                        <td style="vertical-align: middle;">{{data?.ordercount}}</td>
                                        <td style="vertical-align: middle;">{{data?.Ordertotals | round}}</td>
                                        <td style="vertical-align: middle;">غير مسدد</td>
                                        <td style="vertical-align: middle;">
                                            {{(data?.commission - data?.visaOrders < 0)
                                                ? 'صادر'
                                                : 'وارد'
                                            }}
                                        </td>
                                        <td style="vertical-align: middle;">
                                            {{
                                                ((data?.commission - data?.visaOrders < 0) ? data?.commission - data?.visaOrders * -1 : data?.commission - data?.visaOrders) | round
                                            }}
                                        </td>
                                        <td style="vertical-align: middle;">
                                          <button class="btn btn-success" (click)="createDeservedInvoice(data)">
                                              <i class="fas fa-dollar-sign"></i>
                                          </button>
                                          <button class="btn btn-success" (click)="openDetailsModal(data?.branches)">
                                              <i class="fas fa-eye"></i>
                                          </button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;">الفواتير الصادرة</h3>
                <div class="card-body">
                    <div class="table-responsive card custom-br custom-shadow">
                        <nz-table
                            [nzShowPagination]="false"
                            [nzData]="bills"
                            [nzScroll]="{ x: '240px' }"
                            class="table forms-table table-striped"
                            >
                            <thead>
                              <tr class="bg-light">
                                  <th>#</th>
                                    <th *ngFor="let head of billsHead; let i = index">
                                        {{head}}
                                    </th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of bills; let i = index" style="cursor: pointer; direction: ltr;">
                                    <ng-container>
                                        <td style="vertical-align: middle;">{{i + 1}}</td>
                                        <td style="vertical-align: middle;">{{data?.id}}</td>
                                        <td style="vertical-align: middle;">{{data?.ResName}}</td>
                                        <td style="vertical-align: middle;">{{data?.BranchName}}</td>
                                        <td style="vertical-align: middle;">{{data?.date_from}}</td>
                                        <td style="vertical-align: middle;">{{data?.date_to}}</td>
                                        <td style="vertical-align: middle;">{{data?.OrderCounts}}</td>
                                        <td style="vertical-align: middle;">
                                            {{
                                                (data?.confirmed)
                                                    ? 'مسدد'
                                                    : 'غير مسدد'
                                            }}
                                        </td>
                                        <td style="vertical-align: middle;">
                                            وارد
                                        </td>
                                        <td style="vertical-align: middle;">{{data?.total}}</td>
                                        <td style="vertical-align: middle;">
                                          <button class="btn btn-info" style="margin-left: 10px;" (click)="printPageArea(bills[i])">
                                            <i class="fas fa-print"></i>
                                          </button>
                                            <button class="btn btn-warning" (click)="captureScreen(data)">
                                              <i class="far fa-file-pdf"></i>
                                            </button>
                                            <button class="btn btn-success" (click)="confirmBill(data.id,i)" *ngIf="userId == departmentEnum.admin && data?.confirmed == 0">
                                              <i class="fas fa-check-circle"></i>
                                            </button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="loader" *ngIf="loader">
        <div class="loader-container w-100">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </div>
</div>


<div id="contentToConvert" class="d-none">
    <div class="row text-center">
        <h3 class="invoice-restaurant">
            التفاصيل المالية:
            {{name}}
        </h3>
    </div>

    <div class="row">
        <div id="js_invoice" class="card w-100" style="font-size: 18px;">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-12">
                        <img src="assets/images/logo-light-icon.png" style="margin-bottom: 15px;">
                    </div>
                    <div class="col-md-4 text-left text-muted">فاتورة#{{invoiceFor?.id}}</div>
                    <div class="col-md-4 text-center">{{invoiceFor?.month}}</div>
                    <div class="col-md-4 text-right">
                        الحالة:
                        <span class="text-muted">غير مسددة</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
              <div class="row w-100">
                <div class="col-md-4">
                    <h4>من:</h4>
                    <span class="d-block">{{currentInvoice?.BranchName || ''}}</span>
                    <span class="d-block text-muted">{{currentInvoice?.address || ''}}</span>
                    <span class="d-block text-muted">
                        البريد الالكتروني:
                        {{currentInvoice?.email || ''}}
                    </span>
                    <span class="d-block text-muted">
                        الهاتف:
                        {{currentInvoice?.phone || ''}}
                    </span>
                </div>
                <div class="col-md-4" *ngIf="configrations.length">
                    <h4>الى:</h4>
                    <span class="d-block">شركة ساتا فود</span>
                    <span class="d-block text-muted">{{configrations[3].value}}</span>
                    <span class="d-block text-muted">
                        البريد الالكتروني:
                        {{configrations[1].value}}
                    </span>
                    <span class="d-block text-muted">
                        الهاتف:
                        {{configrations[2].value}}
                    </span>
                </div>
                <div class="col-md-4">
                    <h4></h4>
                    <span class="d-block text-muted">نرجو منكم سداد مبلغ الفاتورة في اسرع وقت ممكن باستخدام الوسائل المتفق عليها</span>
                    <span class="d-block">
                        {{invoiceFor.total }}
                        جنية
                    </span>
                    <span class="d-block">
                        عن الفترة:
                    </span>
                    <span class="d-block text-muted">
                        من:
                        {{from}}
                        -
                        الى:
                        {{to}}
                    </span>
                </div>
              </div>

              <div class="row w-100" style="margin-top: 50px;">
                <div class="col-md-6">المدة من: {{from}}</div>
                <div class="col-md-6">الى: {{to}}</div>
              </div>


              <!-- <div class="table-responsive card custom-br custom-shadow" style="margin-top: 15px;">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>مدفوع نقدا</td>
                            <td>{{invoiceFor?.CashOrders | round}}</td>

                            <td>مدفوع بطاقة</td>
                            <td>{{invoiceFor?.visaOrders | round}}</td>
                        </tr>
                        <tr>
                            <td>اجمالي الطلبات</td>
                            <td>{{invoiceFor?.total_orders | round}}</td>

                            <td> عدد الطلبات</td>
                            <td>{{invoiceFor?.total_orders_count | round}}</td>
                        </tr>
                        <tr>
                            <td>ملغي العميل</td>
                            <td>{{invoiceFor?.cancel_client_order | round}}</td>

                            <td> ملغي المتجر </td>

                            <td>{{invoiceFor?.cancel_vendor_orders | round}}</td>
                        </tr>

                        <tr>
                            <td> مستحق ملغي العميل  </td>
                            <td>{{invoiceFor?.NetCancelClientOrdertotal | round}}</td>

                            <td>مستحق ملغي المتجر </td>

                            <td>{{invoiceFor?.NetCancelVendorOrdertotals | round}}</td>
                        </tr>
                        <tr>
                            <td>عمولة الشركة قبل الملغي</td>
                            <td>{{invoiceFor?.commission | round}}</td>

                            <td> ضريبة عمولة الشركة </td>

                            <td>{{invoiceFor?.netComissionTaxes | round}}</td>
                        </tr>
                        <tr>
                            <td>المبلغ بدون ضرائب</td>
                            <td>{{invoiceFor?.netComission | round}}
                            </td>



                            <td> المبلغ بالضريبة</td>
                            <td>
                                {{invoiceFor?.netComissionPlusTaxes | round}}
                            </td>
                        </tr>

                        <tr>
                            <td>المطلوب دفعه</td>
                            <td>{{invoiceFor.netComissionPlusTaxes | round}}</td>

                            <td>حالة الدفع</td>
                            <td>غير مسددة</td>
                        </tr>

                    </tbody>
                </table>
            </div> -->

              <div class="table-responsive card custom-br custom-shadow" style="margin-top: 15px;">
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>مدفوع نقدا</td>
                            <td>{{invoiceFor?.CashOrders | round  }}</td>
                        </tr>
                        <tr>
                            <td>مدفوع بطاقة</td>
                            <td>{{invoiceFor?.visaOrders | round }}</td>
                        </tr>
                        <tr>
                            <td>العمولة</td>
                            <td>{{invoiceFor?.commission | round  }}</td>
                        </tr>
                        <tr>
                            <td>نوع الفاتورة</td>
                            <td>
                                {{invoiceType}}
                            </td>
                        </tr>
                        <tr>
                            <td>المطلوب دفعه</td>
                            <td>{{invoiceFor.total | round  }}</td>
                        </tr>
                        <tr>
                            <td>حالة الدفع</td>
                            <td> {{invoiceFor?.confirmed ? 'مسدد' : 'غير مسدد'}} </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col-12">
                        <span>
                            اجمالي المطلوب:
                        </span>
                        <span class="text-muted float-right" style="font-size: 22px;  font-weight: bold;">{{invoiceFor.total }} جنية</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
