import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { gql } from "apollo-angular";
import { startWith, pairwise } from "rxjs/operators";
import { AreasCreate } from "src/core/services/cities/citiesModel";
import { GenericService } from "src/core/services/generic/generic.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";

@Component({
  selector: "app-order-form-billiing-address",
  templateUrl: "./order-form-billiing-address.component.html",
  styleUrls: ["./order-form-billiing-address.component.scss"],
})
export class OrderFormBilliingAddressComponent implements OnInit {
  @Input("orderForm") orderForm: FormGroup;
  @Input("billingAddresses") billingAddresses: any;
  @Input("selectedUser") selectedUser: any;
  @Input("selectedBillingAddress") selectedBillingAddress: any;
  @Input("towns") towns: any;

  @Output("selectBillingAddress") selectBillingAddressEmitter =
    new EventEmitter();
  @Output("getBillingAddresses") getBillingAddressesEmitter =
    new EventEmitter();

  addBillingAddressModalVisibility: boolean;
  billingAddressForm: FormGroup;
  chooseLocationModalVisibility: boolean;
  geocoder = new google.maps.Geocoder();
  map: google.maps.Map;
  lat = 29.40550529;
  lng = 31.3737457;
  coordinate;
  updateLoader;
  zoom = 8;
  options;
  Filtredaddress = "";
  ArrayAreas = new AreasCreate();
  isBillingAddressEdit: boolean;

  constructor(
    private graphQLService: GraphQLService,
    private formBuilder: FormBuilder,
    private genericService: GenericService
  ) {
    this.createBillingAddressForm();
  }

  ngOnInit(): void {}
  selectBillingAddress(): void {
    this.selectBillingAddressEmitter.emit();
  }
  showAddBillingAddressModal(status: string): void {
    if (status == "add") {
      this.billingAddressForm.patchValue({
        first_name: this.selectedUser.first_name,
        last_name: this.selectedUser.last_name,
        phone: this.selectedUser.mobile,
      });
    } else if (status == "edit") {
      this.isBillingAddressEdit = true;
      this.selectedBillingAddress.area_id =
        +this.selectedBillingAddress.area_id;
      this.lat = this.selectedBillingAddress.latitude;
      this.lng = this.selectedBillingAddress.longitude;
      this.billingAddressForm.patchValue(this.selectedBillingAddress);
    }
    this.addBillingAddressModalVisibility = true;
  }
  createBillingAddressForm(): void {
    this.billingAddressForm = this.formBuilder.group({
      area_id: [null, Validators.required],
      address: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null, Validators.required],
      user_id: [null],
      apartment_number: [null],
      floor_number: [null],
      building_number: [null],
      latitude: [null],
      longitude: [null],
      notes: [""],
    });
  }
  hideChooseLocationModal(): void {
    this.chooseLocationModalVisibility = false;
    this.billingAddressForm.controls["longitude"].setValue(this.lng);
    this.billingAddressForm.controls["latitude"].setValue(this.lat);
  }
  showChooseLocationModal(): void {
    this.chooseLocationModalVisibility = true;
  }
  markerDragEnd(m: any) {
    let lat = m.coords.lat;
    let lng = m.coords.lng;
    this.geocoder.geocode(
      {
        location: {
          lat: lat,
          lng: lng,
        },
      },
      (result, status) => {
        this.options = result;
        this.lat = result[0].geometry.location.lat();
        this.lng = result[0].geometry.location.lng();
      }
    );
  }

  changeAddress(event) {
    this.geocoder.geocode(
      {
        address: this.coordinate,
      },
      (result, status) => {
        console.log(result, "result");
        if (result) {
          this.lat = result[0].geometry.location.lat();
          this.lng = result[0].geometry.location.lng();
        }
      }
    );

    this.Filtredaddress = "";
  }

  addBillingAddress(): void {
    let values = this.billingAddressForm.value;
    values.area_id = values.area_id.toString();
    values.user_id = this.selectedUser.id;

    this.graphQLService
      .query(
        gql`
          {
            billingAddress(Function: "Add") {
              areas {
                id
                name
              }
              address
              building_number
              floor_number
              apartment_number
              first_name
              last_name
              phone
              area_id
              id
            }
          }
        `,
        values
      )
      .subscribe({
        next: (response: any) => {
          if (response.data.billingAddress[0]) {
            // this.selectedBillingAddress = response.data.billingAddress[0];
            // this.orderForm.controls["billing_address_id"].setValue(
            //   this.selectedBillingAddress.id
            // );
            this.hideAddBillingAddressModal();
            this.genericService.showNotification(
              "success",
              "اضافة عنوان للتوصيل",
              "تمت إضافة عنوان توصيل جديد"
            );
          } else {
            this.genericService.showNotification(
              "error",
              "اضافة عنوان للتوصيل",
              "حدث خطا ما"
            );
          }
        },
        error: (error) => {},
      });
  }

  editBillingAddress() {
    let values = this.billingAddressForm.value;
    values.area_id = values.area_id.toString();
    values.user_id = this.selectedUser.id;
    values.id = this.selectedBillingAddress.id;

    this.graphQLService
      .query(
        gql`
          {
            billingAddress(Function: "Edit") {
              areas {
                id
                name
              }
              address
              building_number
              floor_number
              apartment_number
              first_name
              last_name
              phone
              area_id
              notes
              id
            }
          }
        `,
        values
      )
      .subscribe({
        next: (response: any) => {
          if (response.data.billingAddress[0]) {
            this.selectedBillingAddress = response.data.billingAddress[0];
            this.hideAddBillingAddressModal();
            this.genericService.showNotification(
              "success",
              "تعديل عنوان للتوصيل",
              "تم تعديل عنوان توصيل"
            );
          } else {
            this.genericService.showNotification(
              "error",
              "تعديل عنوان للتوصيل",
              "حدث خطا ما"
            );
          }
        },
        error: (error) => {
          this.genericService.showNotification(
            "error",
            "تعديل عنوان للتوصيل",
            "حدث خطا ما"
          );
        },
      });
  }

  hideAddBillingAddressModal(): void {
    this.addBillingAddressModalVisibility = false;
    this.createBillingAddressForm();
    this.getBillingAddressesEmitter.emit();
  }
}
