import { Iinputs } from "../Interfaces/inputInterface";
import * as moment from "moment/moment";
export class OutputData {
  outputObj: any = {};
  constructor(inputs: Iinputs[]) {

    inputs.forEach((el) => {
      switch (el.type) {
        case "search":
        case "text":
        case "tel":
          this.outputObj[el.name] = "";
          break;

        case "number":
          this.outputObj[el.name] = undefined;
          break;

        case "date":
          if (
            (["date_to", "startto", "end_date", "enddate"].includes(el.name) ||
              el.isStartDateToday == true) &&
            el.ignoreStartDate != false
          ) {
            this.outputObj[el.name] =moment()
            .clone()

            .format("YYYY-MM-DD");;
            ;
          }
          else if (
            ["date_from", "startfrom", "start_date", "startdate"].includes(
              el.name
            ) &&
            el.ignoreStartDate != false
          ) {
            this.outputObj[el.name] = moment().startOf("month")  .clone()        .format("YYYY-MM-DD");
            ;
          }
          break;
        case "month":
          this.outputObj[el.name] = moment().startOf("month").toDate();
          break;
        case "dropdown":
          if (el.hideClear) this.outputObj[el.name] = null;
          break;
        case "multiSelect":
          this.outputObj[el.name] = null;
          break;
      }
    });

    return this.outputObj;
  }
}
