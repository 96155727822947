import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "dishsCount" })
export class DishsCountPipe implements PipeTransform {
  transform(value: any[]): any {
    let count = 0;
    value.forEach((data) => {
      count += data.amount;
    });
    return count;
  }
}
