<div class="mb-2">
  <app-generic-filter
    [inputs]="filterInputs"
    (filter)="filter($event)"
    (onDropDownChange)="handleFilterDropdownChange($event)"
  ></app-generic-filter>
</div>
<div class="row">
  <div class="col-lg-3 col-md-6 col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="m-r-10">
            <span class="btn btn-circle btn-lg btn-info">
              <i class="fas fa-info"></i>
            </span>
          </div>
          <div>عدد الطلبات</div>
          <div class="ml-auto">
            <h2 class="m-b-0 font-light">{{ data?.total || 0 }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="m-r-10">
            <span class="btn btn-circle btn-lg btn-success">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
          <div>اجمالي ما تم توصيله</div>
          <div class="ml-auto">
            <h2 class="m-b-0 font-light">{{ total | number : "1.0-2" }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="m-r-10">
            <span class="btn btn-circle btn-lg btn-danger">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
          <div>اجمالي الملغي</div>
          <div class="ml-auto">
            <h2 class="m-b-0 font-light">{{ cancel | number : "1.0-2" }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="m-r-10">
            <span class="btn btn-circle btn-lg btn-warning">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
          <div>اجمالي الغير موصل</div>
          <div class="ml-auto">
            <h2 class="m-b-0 font-light">
              {{ commission | number : "1.0-2" }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-responsive card custom-br custom-shadow pc-design">
  <nz-table
    [nzFrontPagination]="false"
    [nzData]="data.results"
    [nzLoading]="loading"
    [nzPageSize]="30"
    [nzPageIndex]="1"
    [nzScroll]="{ x: '240px' }"
    (nzPageIndexChange)="get($event)"
    class="table forms-table table-striped"
    *ngIf="data && data.total >= 1"
  >
    <thead>
      <tr class="bg-light">
        <th *ngFor="let head of heads">
          <p class="text-nowrap">
            {{ head }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let allData of data.results; let i = index">
        <!-- <td>{{ i + 1 }}</td> -->
        <td>{{ allData?.id }}</td>
        <td>{{ allData?.platforms?.name }}</td>

        <td>
          {{ allData?.users?.username || "" }}
        </td>
        <ng-container *ngIf="allData.order_type_id == 1">
          <td>
            {{
              (allData?.users?.first_name || "") +
                "  " +
                (allData?.users?.last_name || "")
            }}
          </td>
          <td>
            {{ allData?.users?.mobile || "--" }}
          </td>
        </ng-container>
        <ng-container *ngIf="allData.order_type_id != 1">
          <td>
            {{
              (allData?.billing_address?.first_name || "") +
                "  " +
                (allData?.billing_address?.last_name || "")
            }}
          </td>
          <td>
            {{ allData?.billing_address?.phone || "--" }}
          </td>
        </ng-container>
        <td *ngIf="!allData.takeway">
          {{
            allData?.billing_address?.address
              ? allData?.billing_address?.address
              : "الاستلام في الفرع"
          }}
        </td>
        <td *ngIf="allData.takeway">
          <span class="badge badge-success"> استلام من الفرع </span>
        </td>
        <td>
          {{
            allData.order_type_id == 0
              ? allData?.billing_address?.areas?.name
              : allData?.areas?.name
          }}
        </td>
        <td>
          <p class="text-nowrap m-0">
            {{ allData?.branches?.name }}
          </p>
        </td>
        <td>
          <p class="text-nowrap m-0">
            {{ allData?.order_type_id | orderTypePipe }}
          </p>
        </td>
        <td>
          <p class="text-nowrap m-0">
            {{
              allData.order_type_id != 0 && allData?.order_status?.id == 4
                ? "تم الاستلام"
                : allData?.order_status?.name
            }}
          </p>
        </td>
        <!-- <td>
          <p class="m-0 text-nowrap">
            {{
              (allData?.waiter?.first_name || "") +
                "--" +
                (allData?.waiter?.last_name || "")
            }}
          </p>
        </td> -->
        <!-- <td>{{ allData?.table?.number || "--" }}</td> -->
        <td>{{ allData?.drivers?.name || "--" }}</td>
        <td>{{ allData?.delivery_comment }}</td>
        <td>{{ allData?.paymenttype?.name }}</td>
        <td>{{ allData?.order_details | dishsCount }}</td>
        <td>{{ allData?.sub_total || "--" }}</td>
        <td>{{ allData?.wallet_discount }}</td>
        <td>{{ allData?.offer_discount }}</td>
        <td>{{ allData?.voucher_discount }}</td>
        <td>{{ allData?.taxes }}</td>
        <td>{{ allData?.delivery_serivce || "--" }}</td>
        <td>{{ allData?.total }}</td>
        <td>{{ allData?.notes }}</td>
        <td>{{ allData?.canceled_reason }}</td>
        <td>
          <p class="text-nowrap m-0">
            {{ allData?.created | date : "medium" : "GMT" }}
          </p>
        </td>

        <td>
          <div class="d-flex gap-2 justify-content-center">
            <ng-container
              *ngIf="allData?.order_status?.id < 4; else orderStatusUnchangable"
            >
              <button
                class="btn"
                [ngClass]="{
                  'btn-success': allData?.order_status?.id == 3,
                  'btn-info': allData?.order_status?.id != 3
                }"
                (click)="changeOrderStatus(allData)"
              >
                {{ currentOrderStatusName(allData) }}
              </button>

              <!-- <button class="btn btn-danger" (click)="cancelOrder(allData)">
                الغاء
              </button> -->
            </ng-container>
            <ng-template #orderStatusUnchangable>
              <p
                class="bg-black-alpha-40 text-nowrap rounded-top p-1 m-0 text-dark"
              >
                لا يمكن تغيير حالة الطلب
              </p>
            </ng-template>
          </div>
        </td>
        <td>
          <div class="d-flex gap-2 justify-content-center">
            <button
              (click)="
                getOrderDetails(
                  allData['order_details'],
                  allData['billing_address'],
                  allData['users'],
                  i
                )
              "
              class="btn btn-info"
            >
              تفاصيل
            </button>
            <button class="btn btn-success" (click)="edit(allData)">
              تعديل
            </button>
            <a target="_blank" routerLink="/orderReceipt/{{ allData.id }}">
              <button class="btn btn-success">طباعة</button>
            </a>
          </div>
        </td>
      </tr>
    </tbody>

    <!--<button
            class="btn btn-block btn-info"
            style="margin-top: 20px;"
            matRipple
            nz-button
            [nzSize]="'large'"
            *ngIf="canLoadMore"
            (click)="loadMore()">
            تحميل المزيد
        </button>-->
  </nz-table>
  <nz-table [nzData]="data.results" *ngIf="data?.total == 0"></nz-table>
  <br />
  <nz-pagination
    *ngIf="data?.total"
    [nzPageIndex]="page"
    [nzTotal]="data?.total"
    nzShowSizeChanger
    [nzPageSizeOptions]="[5, 10, 15, 20, 50]"
    [nzPageSize]="limit"
    (nzPageIndexChange)="onPageChange($event)"
    (nzPageSizeChange)="onSizeChange($event)"
  >
  </nz-pagination>
</div>
<div class="table-responsive card custom-br custom-shadow mobile-design">
  <nz-table
    [nzFrontPagination]="false"
    [nzData]="data.results"
    [nzLoading]="loading"
    [nzPageSize]="30"
    [nzPageIndex]="1"
    [nzScroll]="{ x: '240px' }"
    (nzPageIndexChange)="get($event)"
    class="table forms-table table-striped"
    *ngIf="data && data.total >= 1"
  >
    <thead>
      <tr class="bg-light">
        <th *ngFor="let head of Mobileheads">
          <p class="text-nowrap">
            {{ head }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let allData of data.results; let i = index">
        <!-- <td>{{ i + 1 }}</td> -->
        <td
          (click)="
            getOrderDetails(
              allData['order_details'],
              allData['billing_address'],
              allData['users'],
              i
            )
          "
        >
          {{ allData?.id }}
        </td>
        <td>
          {{ allData?.users?.username || "" }}
        </td>
        <ng-container *ngIf="allData.order_type_id == 1">
          <td>
            {{
              (allData?.users?.first_name || "") +
                "--" +
                (allData?.users?.last_name || "")
            }}
          </td>
          <td>
            {{ allData?.users?.mobile || "--" }}
          </td>
        </ng-container>
        <ng-container *ngIf="allData.order_type_id != 1">
          <td>
            {{
              (allData?.billing_address?.first_name || "") +
                "--" +
                (allData?.billing_address?.last_name || "")
            }}
          </td>
          <td>
            {{ allData?.billing_address?.phone || "--" }}
          </td>
        </ng-container>
        <td *ngIf="!allData.takeway">
          {{
            allData?.billing_address?.address
              ? allData?.billing_address?.address
              : "الاستلام في الفرع"
          }}
        </td>
        <td *ngIf="allData.takeway">
          <span class="badge badge-success"> استلام من الفرع </span>
        </td>
        <td>
          {{
            allData.order_type_id == 0
              ? allData?.billing_address?.areas?.name
              : allData?.areas?.name
          }}
        </td>

        <td>{{ allData?.drivers?.name || "--" }}</td>

        <td>{{ allData?.total }}</td>
        <td>{{ allData?.notes }}</td>

        <!-- <td>
                    <button (click)="showAdress(allData['billing_address'], $event)" class="btn btn-rounded btn-block btn-info">
                        تفاصيل العنوان
                    </button>
            </td> -->

        <td>
          <div class="d-flex gap-2 justify-content-center">
            <ng-container
              *ngIf="allData?.order_status?.id < 4; else orderStatusUnchangable"
            >
              <button
                class="btn"
                [ngClass]="{
                  'btn-success': allData?.order_status?.id == 3,
                  'btn-info': allData?.order_status?.id != 3
                }"
                (click)="changeOrderStatus(allData)"
              >
                {{ currentOrderStatusName(allData) }}
              </button>

              <!-- <button class="btn btn-danger" (click)="cancelOrder(allData)">
                الغاء
              </button> -->
            </ng-container>
            <ng-template #orderStatusUnchangable>
              <p
                class="bg-black-alpha-40 text-nowrap rounded-top p-1 m-0 text-dark"
              >
                لا يمكن تغيير حالة الطلب
              </p>
            </ng-template>
          </div>
        </td>
        <td>
          <div class="d-flex gap-2 justify-content-center">
            <button
              (click)="
                getOrderDetails(
                  allData['order_details'],
                  allData['billing_address'],
                  allData['users'],
                  i
                )
              "
              class="btn btn-info"
            >
              تفاصيل
            </button>
            <button class="btn btn-success" (click)="edit(allData)">
              تعديل
            </button>
            <a target="_blank" routerLink="/orderReceipt/{{ allData.id }}">
              <button class="btn btn-success">طباعة</button>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-table [nzData]="data.results" *ngIf="data.total == 0"></nz-table>
  <br />
  <nz-pagination
    *ngIf="data?.total"
    [nzPageIndex]="page"
    [nzTotal]="data?.total"
    nzShowSizeChanger
    [nzPageSizeOptions]="[5, 10, 15, 20, 50]"
    [nzPageSize]="50"
    (nzPageIndexChange)="onPageChange($event)"
    (nzPageSizeChange)="onSizeChange($event)"
  >
  </nz-pagination>
</div>
<app-popup-details
  [data]="details"
  [BillingData]="billingData"
  [OrderId]="OrderId"
  [title]="'تفاصيل الطلب'"
  [header]="popupHeader"
  [isVisible]="isVisible"
  (closeDV)="successOperation()"
></app-popup-details>

<app-adress-popup
  [data]="billingData"
  [isVisible]="isBillingVisible"
  (close)="isBillingVisible = $event"
></app-adress-popup>

<nz-modal
  [(nzVisible)]="selectdeliveryForOrderModal"
  nzTitle="اختر الموصل"
  [nzFooter]="null"
  (nzOnCancel)="hideSelectDeliveryForOrderModal()"
>
  <div class="row">
    <div class="col-md-6 mb-2 d-flex">
      <nz-select
        style="width: 150px"
        [(ngModel)]="delivery_id"
        placeholder="الموصل"
        nzShowSearch
        nzAllowClear
      >
        <nz-option
          *ngFor="let delivery of deliveries"
          [nzLabel]="delivery.name"
          [nzValue]="delivery.id"
        ></nz-option>
      </nz-select>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <button
        nz-button
        class="btn btn-primary ant-btn btn-success ant-btn-default ant-btn-lg w-100"
        (click)="changeOrderStatus(selectedOrder)"
        [disabled]="!delivery_id"
      >
        تغيير
      </button>
    </div>
  </div>
</nz-modal>

<p-dialog
  [styleClass]="'w-75'"
  [draggable]="false"
  [modal]="true"
  [dismissableMask]="true"
  header="تعديل الطلب"
  [(visible)]="editOrderVisiblity"
  (onHide)="editOrderVisiblity = false"
>
  <ng-container *ngIf="editOrderVisiblity">
    <app-order-admin-form
      (closeEditOrderModal)="closeEditOrderModal()"
    ></app-order-admin-form>
  </ng-container>
</p-dialog>
