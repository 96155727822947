<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <form [formGroup]="form" style="width: 100%" autocomplete="off">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <div class="float-left">
                  <nz-form-label nzRequired>
                    <label for="type"> نوع الاشعار </label>
                  </nz-form-label>
                </div>
                <nz-select
                  style="width: 100%"
                  nzShowSearch
                  nzAllowClear
                  nzPlaceHolder="اختر نوع"
                  id="type"
                  formControlName="messageType"
                  [nzSize]="'large'"
                  (ngModelChange)="resetDropdown($event)"
                >
                <!-- { name: 'عميل', id: 1 }, -->
                  <nz-option
                    *ngFor="
                      let a of [
                        { name: 'منطقة', id: 2 },
                        { name: 'الى الجميع', id: 3 }
                      ]
                    "
                    [nzLabel]="a.name"
                    [nzValue]="a.id"
                  ></nz-option>
                </nz-select>
              </div>
            </div>

            <div class="col-md-6" *ngIf="form.get('messageType').value == 1">
              <div class="form-group mb-3">
                <nz-form-label>
                  <label for="user_id">المستخدم</label>
                </nz-form-label>
                <div class="input-group-lg">
                  <nz-select
                    style="width: 100%"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="المستخدم"
                    id="user_id"
                    formControlName="user_id"
                    [nzSize]="'large'"
                  >
                    <nz-option
                      *ngFor="let u of users"
                      [nzLabel]="u.username"
                      [nzValue]="u.id"
                    ></nz-option>
                  </nz-select>
                </div>
              </div>
            </div>

            <div class="col-md-6" *ngIf="form.get('messageType').value == 2">
              <div class="form-group mb-3">
                <div class="float-left">
                  <nz-form-label>
                    <label for="town_id"> المنطقة </label>
                  </nz-form-label>
                </div>
                <nz-select
                  style="width: 100%"
                  nzShowSearch
                  nzAllowClear
                  nzPlaceHolder="المنطقة"
                  id="town_id"
                  formControlName="town_id"
                  [nzSize]="'large'"
                >
                  <nz-option
                    *ngFor="let town of towns"
                    [nzLabel]="town.name"
                    [nzValue]="town.id"
                  ></nz-option>
                </nz-select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mb-3">
                <div class="float-left">
                  <nz-form-label nzRequired>
                    <label for="photo"> صورة الاشعار </label>
                  </nz-form-label>
                </div>
                <div class="input-group-lg">
                  <nz-input-group>
                    <label for="photo" class="btn btn-block" matRipple>
                      <i class="mdi mdi-upload"></i>
                      {{ imageName }}
                    </label>
                    <input
                      type="file"
                      class="form-control-file"
                      accept="image/*,.xlsx,.xls"
                      id="photo"
                      name="image"
                      (change)="getImageName($event.target.files)"
                    />
                    <div class="w-25 m-auto" *ngIf="ImgSrc">
                      <button
                        type="submit"
                        class="close m-2"
                        nz-popconfirm
                        nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
                        nzPopconfirmPlacement="top"
                        (nzOnConfirm)="deleteImg()"
                      >
                        <span style="color: red">&times;</span>
                      </button>
                      <img [src]="ImgSrc" class="notification-img" />
                    </div>
                  </nz-input-group>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="title"> العنوان </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <input
                    class="form-control"
                    nz-input
                    type="text"
                    formControlName="title"
                    id="title"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mb-3">
                <nz-form-label nzRequired>
                  <label for="text"> الرسالة </label>
                </nz-form-label>
                <div class="input-group-lg">
                  <textarea
                    class="form-control"
                    formControlName="text"
                    id="text"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <button
                class="btn btn-block"
                [class.btn-info]="!obj"
                [class.btn-success]="obj"
                matRipple
                nz-button
                [nzSize]="'large'"
                [disabled]="
                  !(
                    this.form.valid ||
                    (this.form.get('town_id').value ||
                      this.form.get('user_id').value)
                  ) || !ImgSrc
                "
                (click)="obj ? edit() : add()"
              >
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="isAdd"></i>
                {{ !obj ? "اضافة" : "تعديل" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
