import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input('srcUrl') srcUrl : string = "";
  @Input('downloadLink') downloadLink : string = "";
  @Input('removeLink') removeLink : string = "";

  constructor() { }

  ngOnInit() {
  }
  remove():void{

  }
  download():void{
    
  }
}
