import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { gql } from "apollo-angular";
import { GenericService } from "src/core/services/generic/generic.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { environment } from "src/environments/environment";
import {
  BRANCHES_PHOTOS_ADD_WITH_PHOTO_ARRAY_QUERY,
  BRANCHES_PHOTOS_DELETE_QUERY,
} from "../restuarants-details/queries/restaurant-details.queries";

@Component({
  selector: "app-branches-photos",
  templateUrl: "./branches-photos.component.html",
  styleUrls: ["./branches-photos.component.scss"],
})
export class BranchesPhotosComponent implements OnInit {
  @Input("branch_id") branch_id: number;
  branchesPhotos: any;
  vendor_id: number = JSON.parse(localStorage.getItem("user"))?.restaurants?.id;
  GrUrl: any = environment.graphQl;
  baseUrl = environment.photoPath;
  files: any = [];
  constructor(
    private graphQLService: GraphQLService,
    private genericService: GenericService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["branch_id"] && !changes["branch_id"].firstChange) {
      this.getBranchesPhotos();
    }
  }
  getBranchesPhotos(): void {
    this.graphQLService
      .query(
        gql`
    {
      gallery(branch_idEq:${this.branch_id}){
        id
        photo
      }
    }`
      )
      .subscribe({
        next: (response: any) => {
          this.branchesPhotos = response.data.gallery;
        },
        error: (error) => {},
      });
  }
  uploadBranchesPhotos(event: any): void {
    let files = Object.entries(event.files);

    files.forEach(([index, value]) => {
      const formData = new FormData();
      formData.append("query", BRANCHES_PHOTOS_ADD_WITH_PHOTO_ARRAY_QUERY);
      formData.append("photo", value as any);

      formData.append(
        "variables",
        JSON.stringify({
          photoKey: "photo",
          branch_id: +this.branch_id,
          vendor_id: +this.vendor_id,
        })
      );

      this.httpClient.post(`${this.GrUrl}`, formData).subscribe({
        next: (response: any) => {
          if (+index == files.length - 1) {
            this.files = [];
            this.getBranchesPhotos();
            this.genericService.showNotification(
              "success",
              "اضافة صور للفرع",
              "تمت العملية بنجاح"
            );
          }
        },
        error: (e) => {},
      });
    });
  }
  deleteImg(photo_id: number): void {
    this.graphQLService
      .query(BRANCHES_PHOTOS_DELETE_QUERY, { id: photo_id })
      .subscribe({
        next: (response: any) => {
          this.genericService.showNotification(
            "success",
            "مسح صورة للفرع",
            "تمت العملية بنجاح"
          );
          this.getBranchesPhotos();
        },
        error: (error) => {},
      });
  }
}
