import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GraphQLService } from "../graph/graphql.service";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class CancelReasonsService {
  constructor(private graphQLService: GraphQLService) {}

  Get() {
    return this.graphQLService.query(
      gql`
        {
          cancelReasons {
            id
            name
          }
        }
      `
    );
  }
}
