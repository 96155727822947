import { FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BranchesService } from "src/core/services/branches/branches.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { OrdersAdminService } from "src/core/services/orders-admin/orders-admin.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { gql } from "apollo-angular";
import { ORDERSdATA } from "src/app/main/orders/classes/orders.model";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-print-index",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.css"],
})
export class PrintComponent implements OnInit {
  orderDetails: any;
  orderId: any;
  subTotal: number;
  URL = environment.photoPath;
  constructor(
    private activatedRoute: ActivatedRoute,
    private graphQLService: GraphQLService
  ) {}

  ngOnInit() {
    this.orderId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    this.getOrder();
  }
  async getOrder() {
    this.graphQLService
      .query(
        gql`
      {
        orders(idEq:${this.orderId}){
          ${ORDERSdATA}
        }
      }
    `
      )
      .subscribe({
        next: (response: any) => {
          this.orderDetails = response.data.orders[0];
        },
        error: (error) => {},
      });
  }

  print() {
    setTimeout(() => {
      let data = document.getElementById("contentToConvert");
      html2canvas(data as any).then((canvas) => {
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        const pdfData = new jsPDF("p", "mm", "a4");
        const position = 0;

        pdfData.addImage(
          contentDataURL,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        pdfData.autoPrint();
        window.open(URL.createObjectURL(pdfData.output("blob")), "_blank");
      });
    }, 10);
  }
}
