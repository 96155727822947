import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reports-cards',
  templateUrl: './reports-cards.component.html',
  styleUrls: ['./reports-cards.component.scss']
})
export class ReportsCardsComponent implements OnInit {
  @Input() value: any;
  @Input() icon: string;
  @Input() title: string;
  @Input() color: string;
  constructor() { }

  ngOnInit(): void {
  }

}
