<section class="restuarant-info">
    <div class="container-fluid">
      <div class="row">
<div class="col-12 mb-8">

    <div class="card custom-br custom-shadow border-0">
      <div class="card-body">

        <div class="row">
          <div class="col-md-4 text-center">
            <div class="restuarant-icon mx-auto py-3 custom-br"
              [ngStyle]="restuarant?.cover != '' ?  {'background-image': 'url('+baseUrl + '/'+restuarant?.cover + ')'} : {'background-image': 'url('+CoverSrc+')'}">

              <div style="position: relative;">
                <img [src]="
                  restuarant?.logo == '' ? imageSrc : baseUrl +'/'+ restuarant?.logo
                " alt="" class="img-fluid custom-shadow d-block" />

              </div>

             

             

            </div>
            
            <div class="restuarant-name">
              <h6>
                <a href="" class="text-light font-weight-bold">
                  {{restuarant?.name}}({{restuarant?.name_en}})
                </a>

              </h6>
              <p class="text-secondary fs-6">
                <span *ngFor="let c of restuarant?.cuisines; let i = index">
                  {{c.name}}
                  <span *ngIf="i != restuarant.cuisines.length - 1" style="margin: 5px;">-</span>
                </span>
              </p>

            </div>
            <div class="restuarant-attendance">
              <div class="row">
                <button *ngIf="savelogoOrCover" (click)="EditRestaurant()"
                  class="btn btn-primary col-4 mx-auto">حفظ</button>

              </div>
            </div>
          </div>

          <div class="col-md-8" style="    margin-bottom: 25px;">
            <div class="restuarant-info">

              <div class="row  text-center text-nowrap">
                <div class="col-lg-3">
                  <span class="label">القسم الرئيسي</span>
                  <p class="px-3 mb-0">
                    {{restuarant?.category?.name}}
                  </p>
                </div>
                <div class="col-lg-3">
                  <span class="label">{{
                    "تاريخ الانضمام"
                    }}</span>
                  <p class="px-3 mb-0">
                    {{
                    restuarant?.created | date : 'yyyy-M-dd'

                    }}
                  </p>
                </div>
                <div class="col-lg-3 px-3 mb-0">
                  <span class="label">الضريبة</span>
                  <p class="px-3 mb-0">
                    {{
                    restuarant?.taxes
                    ? restuarant?.taxes + '%'
                    : "لا يوجد"
                    }}
                  </p>
                </div>
                <div class="col-lg-3">
                  <span class="label">البطاقة الضريبية</span>
                  <p class="px-3 mb-0">
                    {{
                    restuarant?.taxes_number
                    ? restuarant?.taxes_number
                    : "لا يوجد"
                    }}
                  </p>
                </div>
                <div class="col-lg-3">
                  <span class="label">موظف المبيعات</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.Sales_Employee
                    ? restuarant?.Sales_Employee.username
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">انتهاء السجل التجاري</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.expr_liences
                    ? (restuarant?.expr_liences | date : 'yyyy-M-dd' )
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">انتهاء البطاقة الضريبية</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.expr_taxescarr
                    ? (restuarant?.expr_taxescarr | date : 'yyyy-M-dd')
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">رقم السجل التجاري</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.license_number
                    ? restuarant?.license_number
                    : 'لا يوجد'
                    }}
                  </p>
                </div>


                <div class="col-lg-3">
                  <span class="label">حالة المطعم</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.RestaurantStatus
                    ? restuarant?.RestaurantStatus.name
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">فئة المطعم</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.RestaurantTypes
                    ? restuarant?.RestaurantTypes.name
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">إسم القسم</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.category?.name
                    ? restuarant?.category.name
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">فئة المتجر </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.RestaurantTypes?.name
                    ? restuarant?.RestaurantTypes.name
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">استلام من الفرع</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.has_takeway
                    ? 'يوجد'
                    : 'لا يوجد'
                    }}
                  </p>
                </div>


                <div class="col-lg-3">
                  <span class="label">تاريخ انتهاء السجل التجاري </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.expr_liences
                    ? (restuarant?.expr_liences | date:'yyyy-m-dd')
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">البطاقة الضريبية </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.taxes_number
                    ? restuarant?.taxes_number
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">البطاقة الشخصية </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.personal_card
                    ? restuarant?.personal_card
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">انتهاء البطاقة الشخصية  </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.personal_card_exp
                    ? restuarant?.personal_card_exp
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">بداية الخصم </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.discount_from
                    ? (restuarant?.discount_from | date : 'yyyy-M-dd')
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">نهاية الخصم </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.discount_to
                    ? (restuarant?.discount_to | date : 'yyyy-M-dd')
                    : 'لا يوجد'
                    }}
                  </p>
                </div>

                <div class="col-lg-3">
                  <span class="label">موظف المبيعات  </span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.Sales_Employee?.name
                    ? restuarant?.Sales_Employee?.name
                    : 'لا يوجد'
                    }}
                  </p>
                </div>



                <div class="col-12">
                  <span class="label">نسبة الخصم</span>

                  <p class="px-3 mb-0">
                    {{
                    restuarant?.percentage
                    ? restuarant?.percentage
                    : 'لا يوجد'
                    }}
                  </p>
                </div>


              </div>


            </div>
          </div>

        </div>
      </div>
     
    </div>
  </div>
  </div>
  </div>
  </section>