import { Directive, HostListener} from '@angular/core';
@Directive({
  selector: '[phoneNumber]'
})
export class PhoneNumberDirective{
  constructor() { }
   @HostListener('keydown', ['$event']) public onKeydown(event: KeyboardEvent): void {
     let value: string[] = (event.target as HTMLInputElement).value.split("");
      for (let i = 0; i < value.length; i++) {
        let test = /[0-9]/.test(value[i]);
        if (!test || value.length > 11) {
          value.pop();
          i--;
        }
      }
      (event.target as HTMLInputElement).value = value.join("");
      console.log(value.join(""), 'value');
  }
}
