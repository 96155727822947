import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';


@Injectable({
  providedIn: 'root'
})
export class RestaurantGeneralStatusService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(`${environment.endpoint}/RestaurantGeneralStatus/index`).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/RestaurantGeneralStatus/add`, obj).toPromise();
  } 
  
  edit(user, id) {
    return this.http.post(`${environment.endpoint}/RestaurantGeneralStatus/edit/${id}`, user).toPromise();
  }  

  delete(id) {
    return this.http.get(`${environment.endpoint}/RestaurantGeneralStatus/delete/${id}`).toPromise();
  }  

}
