const url = "https://imake-app.com:1111";
const url1 = "https://imake-app.com:3999";
const url2 = "https://imake-app.com:3102";

export const environment = {
  production: false,
  endpoint: `${url}/api`,
  endpoint1: `${url1}/api`,
  endpointBranch: `${url2}/api`,
  // photoPath: url1,
  // photoPath2: url2,
  // photoPath: `https://restaurant-satafood.s3.amazonaws.com`,
  photoPath: `https://makeappmall.s3.amazonaws.com`,
  graphQl : "https://imake-app.com:1112/graphql",
  socketUrl: `${url}`,
  firebase: {
    apiKey: "AIzaSyDnap2FvwZz6MLb1ZTFN2njlURluMFDqyo",
    authDomain: "satafast-ios.firebaseapp.com",
    projectId: "satafast-ios",
    storageBucket: "satafast-ios.appspot.com",
    messagingSenderId: "25410268673",
    appId: "1:25410268673:web:57ca21bde38a8365261a00",
    measurementId: "G-Y4Q57DXN91",
    vapidKey:
      "BMXwwF_6g9gA6utx-PhyuJEASq-S6RtURkU5URlKycR1VdKf0qslMf4GeM_pz4WcVWMvZ6sY4B9aC2EZ1C_QAHo",
  },
};

/*
const firebaseConfig = {
  apiKey: "AIzaSyDnap2FvwZz6MLb1ZTFN2njlURluMFDqyo",
  authDomain: "satafast-ios.firebaseapp.com",
  projectId: "satafast-ios",
  storageBucket: "satafast-ios.appspot.com",
  messagingSenderId: "25410268673",
  appId: "1:25410268673:web:57ca21bde38a8365261a00",
  measurementId: "G-Y4Q57DXN91"
};
*/

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
