<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)" data-sidebartype="mini-sidebar">
            <i class="sl-icon-menu font-20"></i>
        </a>
    </li>



</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
  <li class="nav-item nav-link" (click)="openNotification()">
      <i class="fa-bell fas line-height-3 text-2xl pointer"></i>
    <span class="bg-danger position-absolute text-center newNotification pointer" *ngIf="currentNotify">
        {{currentNotify}}
    </span>
  </li>
    <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close font-16"></i>
            </a>
        </form>
    </li> -->
    <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="flag-icon flag-icon-us font-18"></i>
        </a>
        <div class="dropdown-menu-right" aria-labelledby="navbarDropdown2" ngbDropdownMenu>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-us"></i> English</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-fr"></i> French</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-es"></i> Spanish</a>
            <a class="dropdown-item" href="#">
                <i class="flag-icon flag-icon-de"></i> German</a>
        </div>
    </li> -->

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">Steave Jobs</h4>
                    <p class=" m-b-0">varun@gmail.com</p>
                </div>
            </div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <div class="dropdown-divider"></div>
            <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div>
        </div>
    </li> -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
