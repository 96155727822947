import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { CategoriesService } from "src/core/services/categories/categories.service";
import {
  Restuarant,
  Users,
} from "src/core/services/restaurants/RestaurantModel";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { UsersService } from "src/core/services/users/users.service";
import { NzModalRef } from "ng-zorro-antd";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { validations } from "src/core/helper/validations";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.css"],
})
export class EditComponent implements OnInit {
  BranchObj = new Restuarant();
  User = new Users();
  RestuarantObj = new Restuarant();
  logofile;
  coverfile;
  adding;
  zonesList;
  image: any;
  logo: any;
  attachment: any[] = [];
  attachmentfiles: any[] = [];
  types;
  selectedUSer;
  status;
  username: string;
  sales;
  Isedit = false;
  departmentEnum = DepartmentsEnum;
  obj
  URL = environment.photoPath;
  constructor(
    private service: RestaurantsService,
    private location: Location,
    private Userservice: UsersService,
    private modal?: NzModalRef,
    private Catservice?: CategoriesService,
    private navigate?: Router,
    private activatedRoute?: ActivatedRoute,
    private generic?: GenericService
  ) {}

  async ngOnInit() {
    const data = await this.Catservice.getCategories();
    this.zonesList = data;

    const status = await this.service.getRestaurantStatus();
    this.status = status;
    const types = await this.service.getRestaurantTypes();
    this.types = types;

    const sales_man = await this.Userservice.getUsersBygroup({
      user_group_id: 8,
    });
    this.sales = sales_man;

    this.zonesList = data;
    this.RestuarantObj.category_id = this.zonesList[0].id;
    if (window.history.state.id) {
      this.BranchObj = window.history.state;
      this.Isedit = true;
      delete this.BranchObj.navigationId;
    }
  }
  uploadFile($event) {
    console.log($event.target.files[0]); // outputs the first file
    this.image = $event.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(this.image);

    reader.onload = (_event) => {
      this.coverfile = reader.result;
    };
  }
  uploadLogoFile($event) {
    this.logo = $event.target.files[0];

    var reader = new FileReader();
    reader.readAsDataURL(this.logo);

    reader.onload = (_event) => {
      this.logofile = reader.result;
    };
    console.log(this.logofile); // outputs the first file
  }
  uploadAttahcmentFile($event) {
    this.attachment = $event.target.files;
    for (let index = 0; index < $event.target.files.length; index++) {
      const element = $event.target.files[index];
      console.log(element); // outputs the first file

      var reader = new FileReader();

      reader.onload = (_event: any) => {
        this.attachmentfiles.push(_event.target.result);
      };
      reader.readAsDataURL(element);
    }
    console.log(this.attachmentfiles); // outputs the first file
  }
  async add() {
    this.adding = true;
    if (new validations(document).vlidate()) {
      var formData: any = new FormData();
      this.RestuarantObj.content_id = parseInt(localStorage.getItem("user_id"));
      this.RestuarantObj.departments_id = this.departmentEnum.content;

      console.log("User : ", this.User);
      console.log("UserId : ", this.User.id);
      if (this.User.id != null) {
        this.RestuarantObj.user_id = this.User.id;
      } else {
        formData.append("user", JSON.stringify(this.User));
      }
      formData.append("data", JSON.stringify(this.RestuarantObj));

      formData.append(
        "img",
        this.image != undefined ? this.image : this.RestuarantObj.cover
      );
      formData.append(
        "logo",
        this.logo != undefined ? this.logo : this.RestuarantObj.logo
      );

      for (let index = 0; index < this.attachment.length; index++) {
        formData.append("attachment", this.attachment[index]);
      }

      const data = await this.service.add(formData);
      if (data.id > 0 || data > 0) {
        this.generic.showNotification(
          "success",
          "تم الاضافة بنجاح",
          "تم الاضافة بنجاح"
        );
        if (this.Isedit == true) {
          this.modal.close();
        } else {
          this.navigate.navigate(["/restuarantDetails/" + data.id]);
        }
      }
    } else {
      this.generic.showNotification(
        "error",
        "يرجى ملء جميع البيانات",
        "يرجى ملء جميع البيانات"
      );
    }
  }

  prev: boolean = false;
  isVis: boolean = false;
  data: any[] = [];

  async checktaxes_number() {
    const taxes_numberobjs = {
      taxes_number: this.RestuarantObj.taxes_number,
    };

    const dataa: any = await this.service.FilterRestaurant(taxes_numberobjs);
    console.log(dataa);
    if (dataa.length) {
      this.data = dataa;
      this.isVis = true;
    }
  }

  async checkpersonal_carddata() {
    const personal_cardobj = {
      personal_card: this.RestuarantObj.personal_card,
    };

    const personal_carddata: any = await this.service.FilterRestaurant(
      personal_cardobj
    );
    console.log(personal_carddata);
    if (personal_carddata.length) {
      this.data = personal_carddata;
      this.isVis = true;
    }
  }
  async checklicense_number() {
    const obj = {
      license_number: this.RestuarantObj.license_number,
    };

    const data: any = await this.service.FilterRestaurant(obj);
    console.log(data);
    if (data.length) {
      this.data = data;
      this.isVis = true;
    }
  }

  async CancelDublication() {
    this.RestuarantObj = new Restuarant();
    this.isVis = false;
  }

  async getUser() {
    const user_id = this.data.filter((user) => user.id == this.selectedUSer)[0]
      .user_id;
    const data: any = await this.Userservice.view(user_id);
    console.log(data);
    if (data.length == 0) {
      this.generic.showNotification(
        "warning",
        "بحث",
        "لا يوجد مستخدم مرتبط بهذا المتجر"
      );
    } else {
      this.username = data[0].username;
      this.User = data[0];
      this.RestuarantObj.user;
      this.isVis = false;
      this.data = [];
      this.selectedUSer = null;
    }
  }
}
