<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <!-- User Profile-->
    <li>
      <!-- User Profile-->
      <div class="user-profile m-t-20" ngbDropdown>
        <div class="user-pic">
          <img
            *ngIf="ResturantPhoto != 'null'"
            src="{{ URL }}/{{ ResturantPhoto }}"
            alt="users"
            class="rounded-circle img-fluid"
          />
        </div>
        <div class="user-content hide-menu m-t-10">
          <h5
            *ngIf="ResturantPhoto != 'null'"
            class="m-b-10 user-name font-medium"
          >
            {{ ResturantName }}
          </h5>
          <h5
            *ngIf="ResturantPhoto == 'null'"
            class="m-b-10 user-name font-medium"
          >
            {{ Username }}
          </h5>

          <a
            *appPermessions="[userGroup.Restaurant]"
            class="btn btn-circle btn-sm m-r-5"
            id="home"
            [routerLink]="['/restuarantDetails/' + vendor_id]"
          >
            <i class="ti-home"></i>
          </a>
          <a title="Logoutt" class="btn btn-circle btn-sm" (click)="logout()">
            <i class="ti-power-off"></i>
          </a>
          <div
            class="animated flipInY"
            aria-labelledby="Userdd"
            ngbDropdownMenu
          >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">
              <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a
            >
          </div>
        </div>
      </div>
      <!-- End User Profile-->
    </li>
    <!-- Search feild -->
    <li class="justify-content-center px-3 row">
      <input class="form-control col-8" type="text" [(ngModel)]="searchName" />
      <button (click)="search()" class="col-2 btn btn-success hide-menu">
        <i class="fas fa-search"></i>
      </button>
    </li>

    <!-- First level menu -->
    <li
      class="sidebar-item"
      [class.selected]="showMenu === sidebarnavItem.title"
      [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </div>

      <a
        (click)="one_of_tabs_clicked()"
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="
          sidebarnavItem.class === '' || sidebarnavItem.class === 'none'
            ? [sidebarnavItem.path]
            : null
        "
        [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{ sidebarnavItem.title }}</span>
      </a>

      <!-- Second level menu -->
      <ul
        style="width: 90%"
        aria-expanded="false"
        class="collapse first-level"
        *ngIf="sidebarnavItem.submenu.length > 0"
        [ngClass]="{ in: showMenu === sidebarnavItem.title }"
      >
        <li
          class="sidebar-item"
          *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
          [class.active]="showSubMenu === sidebarnavSubItem.title"
          [routerLinkActive]="
            sidebarnavSubItem.submenu.length > 0 ? '' : 'active'
          "
        >
          <a
            class="sidebar-link"
            [routerLink]="
              sidebarnavSubItem.submenu.length > 0
                ? null
                : [sidebarnavSubItem.path]
            "
            [routerLinkActive]="
              sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'
            "
            [ngClass]="[sidebarnavSubItem.class]"
            *ngIf="!sidebarnavSubItem.extralink"
            (click)="addActiveClass(sidebarnavSubItem.title)"
          >
            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
            <span class="hide-menu">{{ sidebarnavSubItem.title }}</span>
          </a>

          <!-- Third level menu -->
          <ul
            aria-expanded="false"
            class="collapse second-level px-4"
            *ngIf="sidebarnavSubItem.submenu?.length > 0"
            [ngClass]="{ in: showSubMenu === sidebarnavSubItem.title }"
          >
            <li
              class="sidebar-item"
              *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
              routerLinkActive="active"
              [ngClass]="[sidebarnavSubsubItem.class]"
            >
              <a
                (click)="one_of_tabs_clicked()"
                class="sidebar-link"
                [routerLink]="[sidebarnavSubsubItem.path]"
                *ngIf="!sidebarnavSubsubItem.extralink"
                [routerLinkActive]="
                  sidebarnavSubsubItem.submenu?.length > 0
                    ? ''
                    : 'router-link-active'
                "
              >
                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                <span class="hide-menu"
                  >- {{ sidebarnavSubsubItem.title }}</span
                >
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
