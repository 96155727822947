import { Socket } from "ngx-socket-io";
import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { saveAs } from "file-saver";
import {
  branches,
  Restuarant,
  Users,
} from "src/core/services/restaurants/RestaurantModel";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { NzModalService, NzMessageService } from "ng-zorro-antd";
import { EditComponent } from "../../../add/edit/edit.component";
import { AddComponent } from "../../../../branches/add/add.component";
import { BranchesService } from "src/core/services/branches/branches.service";
import { UsersFormComponent } from "../../../../users/users-form/users-form.component";
import { BranchesDirectorsComponent } from "src/app/main/branches/components/branches-directors/branches-directors.component";
import { restaurant_general_status } from "../model/restaurant_general_status.enum";
import { EnternalNotificationsService } from "src/core/services/enternal-notifications/enternal-notifications.service";
import { Order } from "../model/order.model";
import { OrderAdminIndexComponent } from "src/app/main/orders/components/orders-admin/order-admin-index/order-admin-index.component";
import { BacklogsService } from "src/core/services/backlogs/backlogs.service";
import { VerificationQuestionsService } from "src/core/services/VerificationQuestions/VerificationQuestions.service";
import { OrdersService } from "src/core/services/orders/orders.service";
import { UserGroup } from "src/core/enums/user.group.enum";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { GeneralStatus } from "src/core/enums/generalStatus.enum";
import { GenericService } from "src/core/services/generic/generic.service";
import { RestaurantApprovalAssginemtService } from "src/core/services/restaurantApprovalAssginemt/restaurant-approval-assginemt.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { gql } from "apollo-angular";
import {
  BRANCHES_PHOTOS_ADD_WITH_PHOTO_ARRAY_QUERY,
  BRANCHES_PHOTOS_DELETE_QUERY,
} from "../queries/restaurant-details.queries";
import { HttpClient } from "@angular/common/http";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "app-restuarants-details",
  templateUrl: "./restuarants-details.component.html",
  styleUrls: ["./restuarants-details.component.scss"],
})
export class RestuarantsDetailsComponent implements OnInit {
  display!: boolean;
  dialogHeader!: string;
  branchStatusModel: boolean = false;
  branchDetails;
  width: string = "50vw";
  titleColor!: string;
  baseUrl = environment.photoPath;
  restuarant!: Restuarant;
  branches: branches[] = [];
  selectedBranches: branches;
  mapOfExpandData: { [key: string]: boolean } = {};
  CoverSrc: any;
  imageSrc: any = "../../assets/images/user.png";
  card_personal_photo;
  commercial_photo;
  logo;
  cover;
  contract_photo;
  menu_photo;
  saveAttachmentButton = false;
  taxes_card_photo;
  ResId;
  isVis: boolean = false;
  ans: any[] = [];
  ActivatedBranchId;
  questions: any[] = [];
  selectedRefuseReasonId;
  messageForBackLogs;
  ShowBackLogDialog;
  msgs: any[] = [];
  resturantStatus: any[] = [];
  RefuseReasons: any[] = [];
  savelogoOrCover = false;
  EnattachmentFileds = [
    "card_personal_photo",
    "commercial_photo",
    "contract_photo",
    "menu_photo",
    "taxes_card_photo",
  ];
  ShowRefuseDialog: boolean = false;
  ShowApproveDialog: boolean = false;
  attachmentFileds = [
    "بطاقة شخصية",
    "سجل تجاري",
    "العقد",
    "المنيو",
    "بطاقه ضريبيه",
  ];
  whatUser: any = localStorage.getItem("user_group_id");
  userDepartment_id = localStorage.getItem("department_id");
  isManager: any = localStorage.getItem("department_positions");

  noTraining: boolean = false;
  yesTraining: boolean = false;
  userGroup = UserGroup;
  departmentEnum = DepartmentsEnum;
  generalStatus = GeneralStatus;
  adding = false;
  CheckIfAddedMenuVar = false;
  branchesPhotosVisibility: boolean;
  selectedBranch: number;
  constructor(
    private service: RestaurantsService,
    private activatedRoute: ActivatedRoute,
    public NZmodalService: NzModalService,
    private navigate: Router,
    private notificationService: EnternalNotificationsService,
    private branchesService: BranchesService,
    private verificationQuestionsService: VerificationQuestionsService,
    private socket: Socket,
    private backlogsService: BacklogsService,
    private OrdersService: OrdersService,
    private message: NzMessageService,
    private approveService: RestaurantApprovalAssginemtService,
    private generic: GenericService,
    private graphQLService: GraphQLService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.ResId = this.activatedRoute.snapshot.paramMap.get("id");
    localStorage.setItem("RoutRestaurants", this.ResId);
    this.getResteuratnDetails();
    this.getMenuItemsByResturant(this.ResId);
    this.getQuestions();
    this.getRestaurantStatus();
    this.CheckIfIssetMenu();
    this.socket.on("SendTestOrder", (data) => {
      console.log(data);
      if (data != null) {
        if (data.order_information == 1) {
          this.generic.showNotification(
            "success",
            "تم موافقة الفرع",
            "قام الفرع بالموافقة علي الطلب"
          );
        } else {
          this.generic.showNotification(
            "error",
            "تم رفض الطلب",
            "قام الفرع برفض الطلب"
          );
        }
      }
    });
  }
  async CheckIfIssetMenu() {
    var issetMenu = await this.service.CheckIfAddedMenu(this.ResId);
    this.CheckIfAddedMenuVar = issetMenu;
  }
  addAns(e, ans) {
    const checked = e.target.checked;

    if (checked) {
      this.ans.push(ans);
    } else {
      const data = this.ans.filter((a) => a.id != ans.id);
      this.ans = data;
    }
  }
  activate() {
    this.restuarant.activated = 1;
    this.EditRestaurant();
  }
  async getQuestions() {
    console.log(this.whatUser);
    if (this.whatUser == "19") {
      const data: any = await this.verificationQuestionsService.get();
      this.questions = data;
      console.log(data);
    }
  }
  async getResteuratnDetails() {
    const data = await this.service.getMyRestaurant(this.ResId);

    this.restuarant = data;

    console.log(this.restuarant, "resturant details");

    this.branches = data.branches;
    this.branches.map((index, value) => {
      this.branches[value].expand = false;
    });
    console.log(this.branches);
    this.getRefuseReasons();

    console.log(this.restuarant.restaurant_general_status_id);
    //تفعيل زرار المنيو
    if (
      this.restuarant.restaurant_general_status_id ==
        restaurant_general_status.waitingComplete ||
      this.restuarant.restaurant_general_status_id ==
        restaurant_general_status.active ||
      this.restuarant.restaurant_general_status_id ==
        restaurant_general_status.refused ||
      this.restuarant.restaurant_general_status_id ==
        restaurant_general_status.waitingOperation
    ) {
      this.service.SetMenuDisabled(false);
    } else {
      this.service.SetMenuDisabled(true);
    }
    return new Promise((resolve) => resolve(data));
  }
  uploadLogoFile($event, FileName, index) {
    const fileNameSplit = $event.target.files[0].name.split(".");
    const fileName = fileNameSplit[fileNameSplit.length - 1].toLowerCase();
    const exclude = ["exe", "gif"];
    console.log("fileName ", fileName);
    if (exclude.includes(fileName)) {
      return this.generic.showNotification(
        "error",
        "ملف غير صالح",
        "هذا الملف غير صالح يرجى اختيار صورة"
      );
    }

    if (FileName != "logo" && FileName != "cover") {
      this.attachmentFileds[index] = $event.target.files[0].name;
    }
    var reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    console.log(index);
    reader.onload = (_event) => {
      var image = new Image();
      image.src = _event["target"]["result"] as any;
      image.onload = (s) => {
        const height = image.height;
        const width = image.width;

        if (FileName == "بطاقة شخصية") {
          this.card_personal_photo = $event.target.files[0];
        } else if (FileName == "logo") {
          this.logo = $event.target.files[0];
          this.restuarant.logo = "";

          this.imageSrc = reader.result;
        } else if (FileName == "cover") {
          this.cover = $event.target.files[0];
          this.restuarant.cover = "";

          this.CoverSrc = reader.result;
        } else if (FileName == "سجل تجاري") {
          this.commercial_photo = $event.target.files[0];
        } else if (FileName == "العقد") {
          this.contract_photo = $event.target.files[0];
        } else if (FileName == "المنيو") {
          this.menu_photo = $event.target.files[0];
        } else if (FileName == "بطاقه ضريبيه") {
          this.taxes_card_photo = $event.target.files[0];
        }
        if (FileName != "logo" && FileName != "cover") {
          this.saveAttachmentButton = true;
        } else {
          this.savelogoOrCover = true;
        }
      };
    };
  }

  async getRestaurantStatus() {
    const data = await this.service.getRestaurantStatus();
    this.resturantStatus = data as any;
  }

  downloadImg(url: string, name: number) {
    saveAs(this.baseUrl + "/" + url, this.attachmentFileds[name] + ".jpeg");
  }

  async UpdateAttachment() {
    var formData: any = new FormData();
    formData.append(
      "card_personal_photo",
      this.card_personal_photo != undefined ? this.card_personal_photo : null
    );
    formData.append(
      "commercial_photo",
      this.commercial_photo != undefined ? this.commercial_photo : null
    );
    formData.append(
      "contract_photo",
      this.contract_photo != undefined ? this.contract_photo : null
    );

    formData.append(
      "menu_photo",
      this.menu_photo != undefined ? this.menu_photo : null
    );
    formData.append(
      "taxes_card_photo",
      this.taxes_card_photo != undefined ? this.taxes_card_photo : null
    );

    const data = await this.service.UpdateAttchment(this.ResId, formData);
    this.saveAttachmentButton = false;
    this.getResteuratnDetails();
  }
  async ActivateBranches() {
    var postdata = { id: this.ActivatedBranchId, activated: 1 };
    const data = await this.branchesService.edit(postdata);
    this.AssignToDepratment();
    this.EditRestaurant();
    console.log(data);
    if (data == 1) {
      this.getResteuratnDetails();
      this.isVis = false;
      Swal.fire({
        title: "Success!",
        text: "Data has been Upadated",
        icon: "success",
        confirmButtonColor: "#0162e8",
      });
    }
  }
  async EditRestaurant() {
    //if (new validations(document).vlidate()){
    var formData: any = new FormData();

    formData.append("data", JSON.stringify(this.restuarant));

    formData.append("img", this.cover != undefined ? this.cover : null);
    formData.append("logo", this.logo != undefined ? this.logo : null);

    const data = await this.service.add(formData);
    console.log(data);
    if (data.id > 0 || data > 0) {
      Swal.fire({
        title: "Success!",
        text: "Data has been Upadated",
        icon: "success",
        confirmButtonColor: "#0162e8",
      });
      this.SendNotification();
      if (this.isManager != 1) {
        await this.ApproveRestaurantByEmployee();
      }
      this.savelogoOrCover = false;
    } else {
      // this.toastService.showToast('error', "لم يتم التسجيل","لم يتم التسجيل")
    }
  }

  async ApproveRestaurantByEmployee() {
    await this.approveService.edit({
      confirm: "1",
      restaurant_id: this.restuarant.id,
      user_id: localStorage.getItem("user_id"),
    });
  }
  async SendNotification() {
    if (
      this.restuarant.restaurant_general_status_id ==
        this.generalStatus.RefuseStatus ||
      this.restuarant.backlog_id == 1
    ) {
      const ConTentNotify = await this.notificationService.add({
        vendor_id: this.restuarant.id,
        department_id: this.restuarant.departments_id,
        user_id: this.restuarant.content_id,
        title: "  رفض  " + this.restuarant.name,
        message:
          "  تمت رفض  المتجر من ادارة" +
          "(" +
          this.restuarant.Department.name +
          ")",
      });
    } else {
      const ConTentNotify = await this.notificationService.add({
        vendor_id: this.restuarant.id,
        department_id: this.restuarant.departments_id - 1,
        user_id: this.restuarant.content_id,
        title: "  موافقه علي " + this.restuarant.name,
        message:
          "  تمت الموافقة علي المتجر من ادارة" +
          "(" +
          this.restuarant.Department.name +
          ")",
      });

      const departmentNotifi = await this.notificationService.add({
        vendor_id: this.restuarant.id,
        department_id: this.restuarant.departments_id,
        title: "  موافقه علي متجر" + this.restuarant.name,
        message:
          "  لديكم متجر يحتاج للفحص     " + "(" + this.restuarant.name + ")",
      });
    }
  }
  edit(i) {
    this.navigate.navigateByUrl("/branches/add/" + this.ResId, {
      state: this.branches[i],
    });
  }
  async delete(id, index) {
    this.branchesService.delete(id).then((res) => {
      this.getResteuratnDetails();
      Swal.fire({
        title: "Success!",
        text: "Branch has been Deleted",
        icon: "success",
        confirmButtonColor: "#0162e8",
      });
    });
  }

  openEditModal() {
    const modalRef = this.NZmodalService.create({
      nzTitle: "تعديل المعلومات الاساسية للمطعم",
      nzContent: EditComponent,
      nzFooter: null,
      nzWidth: "800px",
    });
    modalRef.afterClose.subscribe((result) => this.getResteuratnDetails());

    console.log(this.ResId);
    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      console.log("restuarant ", this.restuarant);
      instance.RestuarantObj = this.restuarant;
      instance.Isedit = true;
    }, 3000);
  }

  openAddBranchModal() {
    const modalRef = this.NZmodalService.create({
      nzTitle: "إضافة فرع جديد للمطعم",
      nzFooter: null,
      nzContent: AddComponent,
      nzWidth: "800px",
    });

    modalRef.afterClose.subscribe((result) => this.getResteuratnDetails());

    console.log(this.ResId);
    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.resId = this.ResId;
    }, 2000);
  }

  openDirectorModal(index) {
    const modalRef = this.NZmodalService.create({
      nzTitle: "مديرين الفرع",
      nzContent: BranchesDirectorsComponent,
      nzWidth: "800px",
      nzFooter: null,
    });
    console.log(this.branches[index]);
    modalRef.afterClose.subscribe((result) => this.getResteuratnDetails());

    console.log(this.ResId);
    setTimeout(() => {
      const instance = modalRef.getContentComponent();

      instance.branchId = this.branches[index].id;
    }, 1);
  }
  openEditBranchModal(index) {
    const modalRef = this.NZmodalService.create({
      nzTitle: "تعديل فرع للمطعم",
      nzContent: AddComponent,
      nzWidth: "800px",
      nzFooter: null,
    });
    modalRef.afterClose.subscribe((result) => this.getResteuratnDetails());

    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.resId = this.ResId;
      instance.BranchObj = this.branches[index];
      instance.isedit = true;
      instance.coordinate =
        this.branches[index].latitude + "," + this.branches[index].longitude;
    }, 2000);
  }
  editOwner() {
    const modalRef = this.NZmodalService.create({
      nzTitle: " مالك  المتجر",
      nzFooter: null,
      nzContent: UsersFormComponent,
      nzWidth: "800px",
    });
    modalRef.afterClose.subscribe((result) => this.getResteuratnDetails());

    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.GroupId = 5;
      instance.UserObject(JSON.stringify(this.restuarant.user));
    }, 1000);
  }

  async branchStatus() {
    const data = await this.branchesService.edit({
      id: this.branchDetails.id,
      status_id: this.branchDetails.status_id,
    });
    this.branchStatusModel = false;
    if (data == 1) {
      this.generic.showNotification(
        "success",
        "تم بنجاح",
        "تم تغير حالة الفرع بنجاح"
      );
      this.getResteuratnDetails();
    } else {
      this.generic.showNotification(
        "error",
        "حدث خطأ",
        "الرجاء المحاولة لاحقا"
      );
    }
  }

  SwitchToGoogleMaps(BranchDate) {
    window.open(
      `https://www.google.com/maps/dir/${BranchDate.latitude},${BranchDate.longitude}`
    );
  }
  async deleteCover() {
    await this.service.edit({ cover: "" }, this.restuarant.id);

    Swal.fire({
      title: "Success!",
      text: "Cover has been Deleted",
      icon: "success",
      confirmButtonColor: "#0162e8",
    });
    this.CoverSrc = "";
    this.restuarant.cover = "";
  }
  async deleteLogo() {
    await this.service.edit({ logo: "" }, this.restuarant.id);

    Swal.fire({
      title: "Success!",
      text: "Cover has been Deleted",
      icon: "success",
      confirmButtonColor: "#0162e8",
    });
    this.logo = {};
    this.restuarant.logo = "";
    this.imageSrc = "../../assets/images/user.png";
  }

  //  send btn
  async addBranchFirstNotification() {
    await this.getResteuratnDetails();
    let approve = true;

    if (!this.restuarant.branches.length) {
      this.message.error("يجب اضافة فرع");
      approve = false;
    }
    if (!this.restuarant.cuisines.length) {
      this.message.error("يجب اضافة تخصصات");
      approve = false;
    }
    if (this.restuarant.cover == "") {
      this.message.error("يجب  اضافة كافر");
      approve = false;
    }
    if (this.restuarant.logo == "") {
      this.message.error("يجب اضافة لوجو");
      approve = false;
    }
    if (!this.restuarant.restaurant_attachments) {
      this.message.error("يجب اضافة ملفات المطعم");
      approve = false;
    }
    if (approve) this.ShowApproveDialog = true;
  }

  async ApproveFunc() {
    // انتقال المطعم الي الادارة التالية بعد موافقة الادارة الحالية
    if (this.restuarant.departments_id == this.departmentEnum.SetMenu) {
      if (!this.CheckIfAddedMenuVar) {
        this.message.error("يجب اضافة اطباق في المنيو");
        this.ShowApproveDialog = false;

        return;
      }
    }
    this.SwitchGeneratStatus();
    this.AssignToDepratment();
    await this.EditRestaurant();
    this.ShowApproveDialog = false;
    if (this.userGroup.Admin == this.whatUser) {
      this.navigate.navigateByUrl("/restaurants/all");
    } else {
      this.navigate.navigateByUrl("/department-approve/all");
    }
  }
  SwitchGeneratStatus() {
    if (this.restuarant.departments_id == this.departmentEnum.content) {
      // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة القانونية
      this.restuarant.restaurant_general_status_id =
        this.generalStatus.UnderConfirmation;
    } else if (this.restuarant.departments_id == this.departmentEnum.legal) {
      // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة القانونية
      this.restuarant.restaurant_general_status_id =
        this.generalStatus.SetMenuStatus;
    } else if (
      this.restuarant.departments_id == this.departmentEnum.quality_check
    ) {
      // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة القانونية
      this.restuarant.restaurant_general_status_id =
        this.generalStatus.ActivationStatus;
    } else if (this.restuarant.departments_id == this.departmentEnum.activate) {
      // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة الاكتيفت
      this.restuarant.restaurant_general_status_id =
        this.generalStatus.ActiveStatus;
    }
  }
  AssignToDepratment() {
    if (this.restuarant.departments_id == this.departmentEnum.content) {
      if (this.restuarant.discount_percentage > 0) {
        //لو كان المطعم عنده خصم هيروح للsales

        this.restuarant.departments_id = this.departmentEnum.sales;
      } // لو مش عنده خصم اضافي هيروح للماليات
      else {
        this.restuarant.departments_id = this.departmentEnum.finance;
      }
    } else if (this.restuarant.departments_id == this.departmentEnum.sales) {
      this.restuarant.departments_id = this.departmentEnum.finance;
    } else if (this.restuarant.departments_id == this.departmentEnum.finance) {
      this.restuarant.departments_id = this.departmentEnum.legal;
    } else if (this.restuarant.departments_id == this.departmentEnum.legal) {
      this.restuarant.departments_id = this.departmentEnum.SetMenu;
    } else if (this.restuarant.departments_id == this.departmentEnum.SetMenu) {
      this.restuarant.departments_id = this.departmentEnum.quality_check;
    } else if (
      this.restuarant.departments_id == this.departmentEnum.quality_check
    ) {
      this.restuarant.departments_id = this.departmentEnum.Training;
    } else if (this.restuarant.departments_id == this.departmentEnum.Training) {
      this.restuarant.departments_id = this.departmentEnum.activate;
    } else if (this.restuarant.departments_id == this.departmentEnum.activate) {
      this.restuarant.departments_id = this.departmentEnum.acount_manager;
    }
  }
  handleCancelApprove() {
    this.ShowRefuseDialog = false;
    this.ShowApproveDialog = false;
    this.ShowBackLogDialog = false;
  }

  async getRefuseReasons() {
    const RefuseReasons = await this.service.GetRefuseReasons(
      this.restuarant.departments_id
    );
    this.RefuseReasons = RefuseReasons;
  }
  RefuseFunc() {
    // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة القانونية
    this.restuarant.restaurant_general_status_id = 5;

    this.restuarant.comment = this.messageForBackLogs;
    // انتقال المطعم الي الادارة التالية بعد موافقة الادارة الحالية
    console.log(this.selectedRefuseReasonId);
    this.restuarant.refuse_approve_reasons_id = this.selectedRefuseReasonId;
    this.EditRestaurant();
    this.ShowRefuseDialog = false;
    if (this.userGroup.Admin == this.whatUser) {
      this.navigate.navigateByUrl("/restaurants/all");
    } else {
      this.navigate.navigateByUrl("/department-approve/all");
    }
  }
  async RefuseAndBackLogFunc() {
    // تغيير الحالة العامة للمطعم في حالة كانت الادارة هي اخر ادارة الادارة القانونية
    // this.restuarant.restaurant_general_status_id = 5

    // انتقال المطعم الي الادارة التالية بعد موافقة الادارة الحالية
    const backlog = this.backlogsService.add({
      restaurant_id: this.restuarant.id,
      user_id: localStorage.getItem("user_id"),
      message: this.messageForBackLogs,
    });
    this.restuarant.comment = this.messageForBackLogs;
    this.restuarant.backlog_id = 1;

    this.EditRestaurant();
    this.ShowBackLogDialog = false;
    this.ShowRefuseDialog = false;
    this.navigate.navigateByUrl("/backlogs/all");
  }

  //#region // Test
  isTest: boolean = false;
  menuItems: any[] = [];
  cart: any[] = [];
  orderTest = {
    billing_address_id: 108,
    branch_id: 0,
    notes: "",
    test_orders: 1,
    order_details: [],
    paymenttype_id: 1,
    taxes: 14,
    total: 171,
    user_id: 4939,
  };
  async getMenuItemsByResturant(id) {
    const data: any = await this.service.getMenuItemsByResturant(this.ResId);
    for (let i = 0; i < data.length; i++) {
      const test = data[i].menu_categories_items.map((data) => new Order(data));
      data[i].menu_categories_items = test;
    }
    this.menuItems = data;
  }

  openTest(id) {
    this.orderTest.branch_id = id;
    this.isTest = true;
  }

  quantity(num, obj: any) {
    obj.amount = obj.amount + num;
    obj.total = obj.amount * obj.price;
    this.checkIsCart(obj);
  }

  checkIsCart(item) {
    const index = this.cart.findIndex((i) => i.id == item.id);
    if (index != -1) this.cart[index] = item;
  }

  addCart(e, item) {
    const isAdd = e.target.checked;

    if (isAdd) {
      const { price, ...rest } = item;
      delete rest.id;
      delete rest.name;
      this.cart.push(rest);
      console.log(item);
    } else {
      const filter = this.cart.filter((c) => c.id != item.id);
      this.cart = filter;
    }
  }

  closeTest() {
    this.isTest = false;
    this.cart = [];
  }

  async addOrder() {
    console.log(this.selectedBranches);
    this.orderTest.order_details = this.cart;
    console.log(this.orderTest);
    var orderData = await this.OrdersService.AddTestOrder(
      this.orderTest
    ).subscribe(async (res) => {
      this.orderTest.notes = "";
      await this.socket.emit(
        "JoinBranchRoomToMakeTestOrder",
        this.selectedBranches.code
      );

      this.socket.emit("makeNewOrder", JSON.stringify(res));
    });
  }
  //#endregion

  reason?: string;
  @ViewChild(RestuarantsDetailsComponent)
  public RestuarantDetails: RestuarantsDetailsComponent;
  notTraining() {}

  approveTraining() {
    if (this.restuarant.departments_id == this.departmentEnum.Training) {
      this.restuarant.departments_id = this.departmentEnum.activate;
    }
    this.RestuarantDetails.EditRestaurant();
  }
  OpenActivationModalForBranch(branch_id) {
    this.isVis = true;
    this.ActivatedBranchId = branch_id;
  }

  showBranchesPhotos(branch_id: number): void {
    this.branchesPhotosVisibility = true;
    this.selectedBranch = branch_id;
  }

  hideBranchesPhotos(): void {
    this.branchesPhotosVisibility = false;
    this.selectedBranch = 0;
  }
}
