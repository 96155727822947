import { NgModule } from "@angular/core";
import {MatRippleModule} from '@angular/material/core';

@NgModule({
    declarations: [],
    imports: [],
    exports: [MatRippleModule],
    providers: []
})

export class MatModule {}