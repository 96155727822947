<div class="card">
  <h6>{{ title }}</h6>
  <div class="img">
    <label [for]="'logo' + keyForInput" class="btn btn-block" matRipple>
      <button
        type="submit"
        class="close"
        nz-popconfirm
        nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="deleteImg(cover.id)"
      >
        <span>&times;</span>
      </button>
      <img [src]="cover ? cover.src : default" />
      <input
        type="file"
        multiple
        class="form-control-file"
        accept="image/*,.xlsx,.xls"
        [id]="'logo' + keyForInput"
        name="image"
        (change)="getImage($event.target.files)"
      />
    </label>
  </div>

  <div class="img-preview">
    <img
      *ngFor="let img of imgs"
      class="shadow-lg p-1 mb-2 rounded"
      [src]="img.src"
      (click)="cover = img"
    />
  </div>

  <div class="progress form-group" style="height: 25px" *ngIf="progress > 0">
    <div
      class="progress-bar progress-bar-striped bg-success"
      role="progressbar"
      [style.width.%]="progress"
    >
      <span style="font-size: 15px">{{ progress }}%</span>
    </div>
  </div>
  <button
    *ngIf="canUpload"
    (click)="upload()"
    class="btn btn-success btn-block"
  >
    تحميل
  </button>
  <!-- <button
    (click)="images = []; imgs = []"
    class="btn btn-danger btn-block"
  >
    حذف
  </button> -->
</div>
