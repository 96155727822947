import { AuthGuard } from "src/core/services/guard/auth-guard.service";
import { Injectable } from "@angular/core";
import { CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Injectable({
  providedIn: "root",
})
export class CanAccessGuardService implements CanActivateChild {
  constructor(
    private router: Router,
    private notification: NzNotificationService,
    private authGuard: AuthGuard
  ) {}

  canActivateChild(route, state: RouterStateSnapshot) {
    return this.authGuard.canActivateChild(route, state).then((auth) => {
      if (!auth) {
        return false;
      }
      return true;
      const menuData = localStorage.getItem("MenuData");

      const user = JSON.parse(localStorage.getItem("user_group_id"));
      const path: any = state.url;

      const routes = JSON.parse(menuData) || [];

      const currentPath = [];
      routes.forEach((route: any) => {
        if (route.role == user) {
          if (route.path.startsWith("/") && route.path.startsWith(path)) {
            currentPath.push(route.path);
          }
          route.submenu.forEach((sub) => {
            if (
              (sub.path.startsWith("/") ? sub.path.split("/")[1] : sub.path) ==
              path.split("/")[1]
            ) {
              currentPath.push(sub);
            }
            sub.submenu.forEach((subsub) => {
              if (
                (subsub.path.startsWith("/")
                  ? subsub.path.split("/")[1]
                  : subsub.path) == path.split("/")[1]
              ) {
                currentPath.push(subsub);
              }
            });
          });
        }
      });
      if (currentPath.length > 0) {
        return true;
      }

      this.notification.warning(
        "Access Denied",
        "You are not authorized to access that location"
      );

      this.router.navigate(["/authentication/404"]);
      return false;
    });
  }
}
