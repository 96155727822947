<!-- <div class="table-responsive card custom-br custom-shadow"> -->
<!-- <nz-table
        [nzFrontPagination]="false"
        [nzData]="notifications?.results"
        [nzLoading]="loading"
        [nzPageSize]="20"
        [nzPageIndex]="1"
        [nzScroll]="{ x: '240px' }"
        (nzPageIndexChange)="get($event)"
        class="table forms-table table-striped"
        *ngIf="notifications?.results?.length >= 1">
        <thead>
          <tr class="bg-light">
            <th *ngFor="let head of heads; let i = index">
                {{head}}
            </th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let allData of notifications.results; let i = index">
                <td>{{i + 1}}</td>
                <td>{{allData["title"]}}</td>
                <td>{{allData["text"]}}</td>
                <td>
                    <img src="{{URL}}/{{allData['photo']}}" style="width: 150px;" />
                </td>
                <td> -->
<!-- <button
                        class="btn btn-success"
                        (click)="edit(allData)"><i nz-icon nzType="edit" nzTheme="outline"></i></button>
          <button
            nz-popconfirm
            nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
            nzPopconfirmPlacement="top"
            (nzOnConfirm)="delete(allData['id'], i)"
            class="btn btn-danger"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table> -->

<!-- <p-toolbar styleClass="mb-4 gap-2">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="New"
        icon="pi pi-plus"
        class="p-button-success mr-2"
        (click)="openNew()"
      ></button>
      <button
        pButton
        pRipple
        [label]="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        (click)="deleteSelectedProducts()"
        [disabled]="!selectedProducts || !selectedProducts.length"
      ></button>
    </ng-template>

    </nz-table>
    <nz-pagination
    [nzPageIndex]="page"
    [nzTotal]="data?.total"
    nzShowSizeChanger
    [nzPageSizeOptions]="[5, 10, 15, 20, 50]"
    [nzPageSize]="limit"
    (nzPageIndexChange)="onPageChange($event)"
    (nzPageSizeChange)="onSizeChange($event)"
  >
  </nz-pagination>



    <nz-table [nzData]="data" *ngIf="data?.length == 0"></nz-table>

    <ng-template pTemplate="right">
      <p-fileUpload
        mode="basic"
        accept="image/*"
        [maxFileSize]="1000000"
        label="Import"
        chooseLabel="Import"
        class="mr-2 inline-block"
      ></p-fileUpload>
      <button
        pButton
        pRipple
        label="Export"
        icon="pi pi-upload"
        class="p-button-help"
      ></button>
    </ng-template>
  </p-toolbar> -->
<div class="d-flex gap-2 flex-column">
  <div class="mb-3">
    <app-generic-filter
      [inputs]="filterInputs"
      (filter)="filter($event)"
    ></app-generic-filter>
  </div>

  <p-table
    #dt
    [value]="notifications?.results"
    [rows]="10"
    [globalFilterFields]="['name']"
    responsiveLayout="scroll"
    [rowHover]="true"
    dataKey="id"
    [currentPageReportTemplate]="
      'Showing {first} to {last} of ' + notificationsTotal + ' entries'
    "
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>#</th>
        <th>العنوان</th>
        <th>الرسالة</th>
        <th>الصورة</th>
        <th>تاريخ الإنشاء</th>
        <!-- <th pSortableColumn="modified">
          تاريخ التعديل <p-sortIcon field="modified"></p-sortIcon>
        </th> -->
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-notification let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>
          {{ notification.title }}
        </td>
        <td>{{ notification.text }}</td>
        <td>
          <img src="{{ URL }}/{{ notification['photo'] }}" width="65" />
        </td>
        <td>{{ notification.created | date }}</td>
        <!-- <td>{{ notification.modified | date : "medium" }}</td> -->

        <td>
          <div>
            <button
              nz-popconfirm
              nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
              nzPopconfirmPlacement="top"
              (nzOnConfirm)="delete(notification['id'], i)"
              class="btn btn-danger"
            >
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
    <!-- no data -->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="20">
          <div
            class="d-flex flex-column justify-content-center align-items-center py-6 gap-3 empty-table"
          >
            <i class="pi pi-inbox"></i>
            <p>No Data</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-paginator
  *ngIf="notifications?.total"
  [rows]="limit"
  [totalRecords]="notifications?.total"
  (onPageChange)="paginate($event)"
  [rowsPerPageOptions]="[5, 10, 20, 30]"
></p-paginator>
