import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestuarantsDetailsTabsComponent } from '../restuarants-details-tabs/restuarants-details-tabs.component';
import { AccountOrdersDetailsComponent } from './account-orders-details/account-orders-details.component';
import { RestuarantsDetailsComponent } from './index/restuarants-details.component';


const routes: Routes = [
  {
    path: ':id',
    component: RestuarantsDetailsTabsComponent,
    data: {
        title: 'تفاصيل المطعم ',
    }               
  },
  {
    path: 'account-details/:id',
    component: AccountOrdersDetailsComponent,
    data: {
        title: 'طلبات الفرع',
    }              
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RestuarantsDetailsRoutingModule { }
