import { Routes } from "@angular/router";
import { FinanceIndexComponent } from "./finance-report-index/financeIndexComponent.component";


export const FinanceReportRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'all',
                component: FinanceIndexComponent,
                data: {
                    title: 'التقارير المالية',
                },                              
            }           
        ]
    }
]