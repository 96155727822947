import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "orderTypePipe" })
export class OrderTypePipe implements PipeTransform {
  transform(value: any): any {
    if (value == 0) {
      return "توصيل";
    } else if (value == 1) {
      return "استلام من المطعم";
    } else if (value == 2) {
      return "صالة";
    }
    return "--";
  }
}
