import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';


@Injectable({
  providedIn: 'root'
})
export class MainmenuService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(`${environment.endpoint}/mainmenu/index`).toPromise();
  }

  indexbyrole() {
    return this.http.get(`${environment.endpoint}/mainmenu/indexbyrole`).toPromise();
  }


  search(name:string) {
    return this.http.post<any[]>(`${environment.endpoint}/mainMenu/indexbyroleSearch`,{name}).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/mainmenu/add`, obj).toPromise();
  }

  edit(user, id) {
    return this.http.post(`${environment.endpoint}/mainmenu/edit/${id}`, user).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/mainmenu/delete/${id}`).toPromise();
  }

}
