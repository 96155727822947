<div class="row">
  <div class="col-md-12">
      <div class="card">
          <form [formGroup]="form" style="width: 100%;">
              <div class="row align-items-baseline">
                  <div class="col-md-3">
                      <nz-date-picker
                          formControlName="startfrom"
                          [nzSize]="'large'"
                          nzAllowClear="true" ></nz-date-picker>
                  </div>
                  <div class="col-md-3">
                      <nz-date-picker
                          formControlName="startto"
                          [nzSize]="'large'"
                          nzAllowClear="true" ></nz-date-picker>
                  </div>
                  <div class="col">
                      <button
                          class="btn btn-block btn-info"
                          matRipple
                          nz-button
                          [nzSize]="'large'"
                          type="button"
                          (click)="filter()">Filter</button>
                  </div>
                  <div class="col">
                      <button
                          class="btn btn-block btn-secondary"
                          matRipple
                          nz-button
                          [nzSize]="'large'"
                          type="button"
                          (click)="reset()">Reset</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</div>






<div class="row">


      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        اجمالي الطلبات
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.Ordertotals || 0) | round}}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info" (click)="details('cash')">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        دفع كاش
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{  (count.monthorders?.CashOrders || 0) | round }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info" (click)="details('visa')">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        دفع فيزا
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.visaOrders || 0) | round }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                      تم التوصيل
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{  (count.monthorders?.DeliveriedOrders || 0)  | round }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        الطلبات الملغية
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.CanceledOrders || 0)  | round }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info" (click)="details('cash')">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        عدد الطلبات كاش
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.COUNTCashOrders || 0) }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info" (click)="details('visa')">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        عدد الطلبات فيزا
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.COUNTvisaOrders || 0) }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        عدد الطلبات تم توصيلها
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.COUNTDeliveriedOrders || 0) }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-orange" *ngIf="!isFiltered">شهري</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        عدد الطلبات الملغية
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.monthorders?.COUNTCanceledOrders || 0) }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>



      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-light-danger">اسبوعي</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        اجمالي الطلبات
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.weekorders?.Ordertotals || 0)  | round}}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-light-danger">اسبوعي</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        كاش
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.weekorders?.CashOrders || 0)  | round}}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-light-danger">اسبوعي</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        فيزا
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.weekorders?.visaOrders || 0)  | round }}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-light-danger">اسبوعي</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        تم التوصيل
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.weekorders?.DeliveriedOrders || 0)  | round}}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center">
                  <span class="badge position-absolute top-0 right-0 badge-light-danger">اسبوعي</span>
                    <div class="m-r-10">
                        <span class="btn btn-circle btn-lg btn-info">
                            <i class="fas fa-info"></i>
                        </span>
                    </div>
                    <div>
                        الطلبات الملغية
                    </div>
                    <div class="ml-auto">
                        <h2 class="m-b-0 font-light">{{ (count.weekorders?.CanceledOrders || 0)  | round}}</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>


</div>


<div class="row">
  <div class="col-md-6 col-lg-6">
      <!-- ============================================================== -->
      <!-- month sales chart -->
      <!-- ============================================================== -->
      <app-bar-chart
          [sales]="charts.monthordersForchart"
          [color]="'#4798e8'"
          [title]="'طلبات الشهر'"
          [type]="'line'"
          [selector]="'month-chart'"
          [orderLabelsKey]="'created'"
          [orderTotalKey]="'Ordertotals'"></app-bar-chart>
      <!-- ============================================================== -->
      <!-- month sales chart -->
      <!-- ============================================================== -->
  </div>
  <div class="col-md-6 col-lg-6">
      <!-- ============================================================== -->
      <!-- week sales chart -->
      <!-- ============================================================== -->
      <app-bar-chart
          [sales]="charts.weekordersForchart"
          [color]="'#22c6ab'"
          [title]="'طلبات الاسبوع'"
          [type]="'line'"
          [selector]="'week-chart'"
          [orderLabelsKey]="'id'"
          [orderTotalKey]="'Ordertotals'"></app-bar-chart>
      <!-- ============================================================== -->
      <!-- week sales chart -->
      <!-- ============================================================== -->
  </div>
</div>



<div class="row">
      <div class="col-6">
        <div class="card">
            <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;"> الطلبات </h3>
            <div class="card-body">
                <div class="table-responsive card custom-br custom-shadow">
                    <nz-table
                        [nzShowPagination]="false"
                        [nzData]="orderDetails || []"
                        [nzScroll]="{ x: '240px' }"
                        class="table forms-table table-striped"
                        >
                        <thead>
                          <tr class="bg-light">
                              <th>#</th>
                                <th *ngFor="let head of OrderDetailsHead; let i = index">
                                    {{head}}
                                </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of orderDetails || []; let i = index" style="cursor: pointer; direction: ltr;">
                                <ng-container>
                                    <td style="vertical-align: middle;">{{i + 1}}</td>
                                    <td style="vertical-align: middle;">{{order?.menu_categories_items.name}}</td>
                                    <td style="vertical-align: middle;">{{order?.itemamount}}</td>
                                    <td style="vertical-align: middle;">{{order?.itemtotal}}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>
      <div class="col-6">
        <div class="card">
            <h3 style="margin-top: 20px; margin-right: 15px; text-align: center;">طرق الدفع</h3>
            <div class="card-body">
                <div class="table-responsive card custom-br custom-shadow">
                    <nz-table
                        [nzShowPagination]="false"
                        [nzData]="orderByPayment  || []"
                        [nzScroll]="{ x: '240px' }"
                        class="table forms-table table-striped"
                        >
                        <thead>
                          <tr class="bg-light">
                              <th>#</th>
                                <th *ngFor="let head of OrderByPaymentHead; let i = index">
                                    {{head}}
                                </th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of orderByPayment || []; let i = index" style="cursor: pointer; direction: ltr;">
                                <ng-container>
                                    <td style="vertical-align: middle;">{{i + 1}}</td>
                                    <td style="vertical-align: middle;">{{order?.paymenttype?.name}}</td>
                                    <td style="vertical-align: middle;">{{order?.Ordertotals | round }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
  <div class="col-md-6 col-lg-6">
      <!-- ============================================================== -->
      <!-- month sales chart -->
      <!-- ============================================================== -->
      <app-bar-chart
          [sales]="charts.getMostOrderedCategory"
          [color]="'#4798e8'"
          [title]="'الأكثر طلبا الشهرية'"
          [selector]="'month-details-chart'"
          [type]="'pie'"
          [orderLabelsKey]="'CatName'"
          [orderTotalKey]="'total'"></app-bar-chart>
      <!-- ============================================================== -->
      <!-- month sales chart -->
      <!-- ============================================================== -->
  </div>
  <div class="col-md-6 col-lg-6">
      <!-- ============================================================== -->
      <!-- week sales chart -->
      <!-- ============================================================== -->
      <app-bar-chart
          [sales]="charts.getMostOrderedCategoryWeek"
          [color]="'#22c6ab'"
          [title]="'الأكثر طلبا اسبوعيا'"
          [selector]="'week-details-chart'"
          [type]="'pie'"
          [orderLabelsKey]="'CatName'"
          [orderTotalKey]="'total'"></app-bar-chart>
      <!-- ============================================================== -->
      <!-- week sales chart -->
      <!-- ============================================================== -->
  </div>
</div>

<div class="row">
  <div class="col-12">
    <a routerLink="/orders-admin/all" class="btn btn-success">
      تفاصيل الطلبات
    </a>
  </div>
</div>




