import { Component, OnDestroy } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
// import { SlimLoadingBarService } from "ng2-slim-loading-bar";
import { environment } from "src/environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { GenericService } from "src/core/services/generic/generic.service";
import { UsersService } from "src/core/services/users/users.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnDestroy {
  private sub: any;
  constructor(
    // private slimLoader: SlimLoadingBarService,
    private router: Router,
    private generic: GenericService,
    private usersService: UsersService
  ) {
    // Listen the navigation events to start or complete the slim bar loading

    this.sub = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          if (event.url == "/") {
            if (localStorage.getItem("token")) {
              this.router.navigateByUrl("/welcome");
            } else {
              this.router.navigateByUrl("/authentication/login");
            }
          } else if (event.url == "/authentication/login") {
            console.log("token", localStorage.getItem("token"));
            if (localStorage.getItem("token")) {
              this.router.navigateByUrl("/welcome");
            }
          }
          // this.slimLoader.start();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          // this.slimLoader.complete();
        }
      },
      (error: any) => {
        // this.slimLoader.complete();
      }
    );
  }

  ngOnInit(): void {
    this.requestPermission();
    this.listen();
  }

  requestPermission() {
    const messaging = getMessaging();
    // getToken(messaging,
    //  { vapidKey: environment.firebase.vapidKey}).then(
    //    (token) => {
    //     // const user_id = localStorage.getItem('user_id')
    //     //  if (token) {
    //     //   console.log('token ', user_id)
    //     //   if(user_id && user_id != 'null'){
    //     //     this.usersService.setToken({token,user_id})
    //     //   }
    //     //  } else {
    //     //    console.log('No registration token available. Request permission to generate one.');
    //     //  }
    //  }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    // });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.generic.showNotification(
        "success",
        payload.notification.title,
        payload.notification.body
      );
    });
  }

  ngOnDestroy(): any {
    this.sub.unsubscribe();
  }
}
