import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { gql } from "apollo-angular";
import {
  USER_ADD_QUERY,
  USER_EDIT_QUERY,
} from "src/app/main/users/queries/users.queries";
import { GenericService } from "src/core/services/generic/generic.service";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { UsersService } from "src/core/services/users/users.service";

@Component({
  selector: "app-order-form-client-info",
  templateUrl: "./order-form-client-info.component.html",
  styleUrls: ["./order-form-client-info.component.scss"],
})
export class OrderFormClientInfoComponent implements OnInit {
  @Input("orderForm") orderForm: FormGroup;
  @Input("users") users: any;
  @Input("branches") branches: any;
  @Input("selectedUser") selectedUser: any;

  @Output("selectUser") selectUserEmitter = new EventEmitter();
  @Output("getUsers") getUsersEmitter = new EventEmitter();

  addUserModalVisibility: boolean;
  userForm: FormGroup;
  isUsed: any = { email: false, mobile: false, username: false };
  vendor_id: number = JSON.parse(localStorage.getItem("user")).restaurants.id;
  isEditUser: boolean;
  userOptions: any;

  constructor(
    private graphQLService: GraphQLService,
    private genericService: GenericService,
    private formBuilder: FormBuilder,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    this.createUserForm();
  }
  createUserForm(): void {
    this.userForm = this.formBuilder.group({
      name: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      username: [null, Validators.required],
      email: [null, Validators.required],
      mobile: [null, Validators.required],
      address: [null, Validators.required],
      // branch_id: [null, Validators.required],
      password: ["codesroots@123"],
      user_group_id: 3,
      vendor_id: this.vendor_id,
      active: 1,
    });
  }
  selectUser(): void {
    this.selectUserEmitter.emit();
  }
  hideAddUserModal(): void {
    this.addUserModalVisibility = false;
    this.createUserForm();
  }
  async addUser() {
    if (this.userForm.valid) {
      try {
        const data: any = await this.userService.addUser(this.userForm.value);
        if (data.id) {
          this.selectedUser = data;
          this.orderForm.controls["user_id"].setValue(this.selectedUser.id);
          this.hideAddUserModal();
          this.getUsersEmitter.emit();
          this.genericService.showNotification(
            "success",
            "اضافة عميل",
            "تمت إضافة عميل بنجاح"
          );
        } else {
          this.genericService.showNotification(
            "error",
            "اضافة عميل",
            "حدث خطا ما"
          );
        }
      } catch (e) {
        this.genericService.showNotification(
          "error",
          "اضافة عميل",
          "حدث خطا ما"
        );
      }
      // this.graphQLService.query(USER_ADD_QUERY, this.userForm.value).subscribe({
      //   next: (response: any) => {
      //     if (response.data.users.length) {
      //     } else {
      //     }
      //   },
      //   error: (error) => {},
      // });
    }
  }
  async editUser() {
    if (this.userForm.valid) {
      let values = this.userForm.value;
      values.user_group_id = 3;
      values.id = this.selectedUser.id;
      delete values.password;

      try {
        const response: any = await this.userService.editUser(
          values,
          this.selectedUser.id
        );
        if (response.data == 1) {
          this.selectedUser = values;
          this.orderForm.controls["user_id"].setValue(this.selectedUser.id);
          this.hideAddUserModal();
          this.getUsersEmitter.emit();
          this.genericService.showNotification(
            "success",
            "تعديل عميل",
            "تمت تعديل العميل "
          );
        } else {
          this.genericService.showNotification(
            "error",
            "تعديل عميل",
            "حدث خطا ما"
          );
        }
      } catch (e) {
        this.genericService.showNotification(
          "error",
          "تعديل عميل",
          "حدث خطا ما"
        );
      }
      // this.graphQLService.query(USER_EDIT_QUERY, values).subscribe({
      //   next: (response: any) => {
      //     if (response.data.users.length) {
      //     } else {
      //     }
      //   },
      //   error: (error) => {},
      // });
    }
  }

  validateUniq(type) {
    let value = this.userForm.controls[type].value;

    if (value) {
      this.graphQLService
        .query(
          gql`
          {
            users(${type}Like:"${value}"){
              id
              ${type}
            }
          }
        `
        )
        .subscribe((response: any) => {
          if (
            response.data.users.length &&
            response.data.users[0][type] != this.selectedUser[type]
          ) {
            this.isUsed[type] = true;
          } else {
            this.isUsed[type] = false;
          }
        });
    } else {
      this.isUsed[type] = false;
    }
  }
  showAddUserModal(status): void {
    if (status == "add") {
    } else if (status == "edit") {
      this.userForm.patchValue(this.selectedUser);
      this.isEditUser = true;
    }
    this.addUserModalVisibility = true;
  }
  getUsersSuggestions(mobile) {
    //if user selected
    console.log(mobile);

    if (mobile?.id) {
      this.selectUser();
    } else {
      this.userService.getUsersSuggestions(mobile).subscribe({
        next: (response: any) => {
          this.userOptions = response.data.users;
        },
        error: (error) => {},
      });
    }
  }
}
