import { SharedModule } from "src/core/module/shared.module";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { NotificationsFormComponent } from "./notifications-form/notifications-form.component";
import { NotificationsIndexComponent } from "./notifications-index/notifications-index.component";
import { NotificationsRoutes } from "./notifications.routing";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [NotificationsIndexComponent, NotificationsFormComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(NotificationsRoutes),
    SharedModule,
  ],
  exports: [],
  providers: [],
})
export class NotificationsModule {}
