import { AreasService } from "src/core/services/areas/areas.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { NotificationsService } from "src/core/services/notifications/notifications.service";
import * as $ from "jquery";
import { UsersService } from "src/core/services/users/users.service";
import { TownsService } from "src/core/services/towns/towns.service";
import { data } from "jquery";
import { GraphQLService } from "src/core/services/graph/graphql.service";
import { USERS__QUERY } from "src/app/main/users/queries/users.queries";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-notifications-form",
  templateUrl: "./notifications-form.component.html",
  styleUrls: ["./notifications-form.component.css"],
})
export class NotificationsFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isAdd: boolean = false;
  imageName = "اختر الصورة";
  image: any = "";
  obj: any = null;
  id: any;
  areas: any[] = [];
  users: any[] = [];
  user_id: any;
  Image: File;
  ImgSrc: string | ArrayBuffer;
  towns: any;
  imgBaseUrl = environment.photoPath + '/';

  constructor(
    private fb: FormBuilder,
    private service: NotificationsService,
    private generic: GenericService,
    private userService: UsersService,
    private navigate: Router,
    private townsService: TownsService,
    private graphQLService: GraphQLService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getdataForDropdown();

    this.obj = localStorage.getItem("item");

    if (this.obj) {
      const obj = JSON.parse(this.obj);
      this.id = obj.id;
      this.form.reset(obj);
    }

    let user = localStorage.getItem("user_id");

    if (user) {
      this.user_id = Number(localStorage.getItem("user_id"));
    } else {
      this.user_id = null;
    }

    this.form.valueChanges.subscribe({
      next: (data) => {
        console.log(data, "form data");
        console.log(this.form.valid, "form valid");
      },
    });
  }

  createForm() {
    this.form = this.fb.group({
      vendor_id: [JSON.parse(localStorage["user"]).restaurants.id],
      title: [null, Validators.required],
      text: [null, Validators.required],
      messageType: [null, Validators.required],
      town_id: [null],
      user_id: [null],
    });
  }
  getData() {
    this.graphQLService.query(USERS__QUERY).subscribe({
      next: (response: any) => {
        this.users = response.data.users;
      },
    });
  }
  async getdataForDropdown() {
    let data: any = await Promise.all([this.townsService.get()]);
    this.towns = data[0];
    this.getData();
  }

  getImageName(files: FileList) {
    console.log("sad");

    let fileName = $("#photo")
      .val()
      .toString()
      .replace(/C:\\fakepath\\/i, "");
    this.imageName = fileName;
    this.image = files.item(0);

    this.Image = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.Image);
    reader.onload = (_event) => {
      this.ImgSrc = reader.result;
    };
  }

  async add() {
    if (
      this.form.valid &&
      (this.form.get("town_id") || this.form.get("user_id"))
    ) {
      const values = this.form.getRawValue();
      if (values.messageType == 1) {
        var userId = values.user_id;
        delete values.town_id;
      } else if (values.messageType == 3) {
        delete values.user_id;
        delete values.town_id;
      } else if (values.messageType == 2) {
        delete values.user_id;
      }
      let messageType = values.messageType;
      delete values.messageType;

      const formData: any = new FormData();
      formData.append("data", JSON.stringify(values));

      if (this.image) formData.append("img", this.image);

      this.isAdd = true;

      this.service.add(formData).then((data: any) => {
        console.log(data, "data");
        this.isAdd = false;
        if (data.id) {
          let photo = this.imgBaseUrl + data.photo;
          if (userId) {
            this.sendNotificationToDevice(values, userId,photo);
          } else if (messageType == 3) {
            this.sendNotificationToAll(values,photo);
          } else if (messageType == 2) {
            this.sendToTopic(
              values.title,
              values.text,
              values.town_id.toString(),photo
            );
          }
        } else {
          this.generic.showNotification(
            "error",
            "اضافة",
            "حدث خطأ بالرجاء المحاولة في وقت لاحق"
          );
        }
      });
    }
  }

  async edit() {
    if (this.form.valid) {
      const values = this.form.getRawValue();

      console.log("data : ", values);
      console.log("user : ", this.id);

      // delete values.zone;
      // delete values.userId;
      // delete values.messageType;

      const formData: any = new FormData();

      formData.append("data", JSON.stringify(values));

      if (this.image) formData.append("img", this.image);

      this.isAdd = true;

      const data = await this.service.edit(formData, this.id);

      if (data == 1) {
        this.isAdd = false;
        console.log(data);
        this.generic.showNotification("success", "تعديل", "تمت العملية بنجاح");
      } else {
        this.isAdd = false;
        this.generic.showNotification(
          "error",
          "تعديل",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }

      // .then(
      //   (data : any) => {
      //     this.isAdd = false;
      //     console.log(data);
      //     this.generic.showNotification('success', 'تعديل', 'تمت العملية بنجاح');
      //   },
      //   () => {
      //     this.isAdd = false;
      //     this.generic.showNotification('error', 'تعديل', 'حدث خطأ بالرجاء المحاولة في وقت لاحق');
      //   }
      // )
    }
  }

  resetDropdown(type: number) {
    console.log(type);

    switch (type) {
      case 1:
        if (this.form.get("town_id").value) {
          console.log("passed if condition");

          this.form.patchValue({ town_id: null });
        }
        break;
      case 2:
        if (this.form.get("user_id").value) {
          console.log("passed if condition 2");
          this.form.patchValue({ user_id: null });
        }
        break;
      case 3:
        this.form.patchValue({ town_id: null });
        break;
    }
  }
  deleteImg() {
    this.ImgSrc = "";
    this.imageName = "اختر الصورة";
  }

  sendNotificationToDevice(values: any, userId: number,photo) {
    this.service.sendToDevice(values.title, values.text, userId,photo).then(
      (data: any) => {
        this.isAdd = false;

        this.generic.showNotification("success", "اضافة", "تمت العملية بنجاح");
        this.form.reset();
        this.navigate.navigateByUrl("/notifications/all");
      },
      (e) => {
        this.isAdd = false;
        this.generic.showNotification(
          "error",
          "اضافة",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }
    );
  }
  sendNotificationToAll(values: any,photo) {
    this.service.sendToAll(values.title, values.text,photo).then(
      (data: any) => {
        this.isAdd = false;

        this.generic.showNotification("success", "اضافة", "تمت العملية بنجاح");
        this.form.reset();
        this.navigate.navigateByUrl("/notifications/all");
      },
      (e) => {
        this.isAdd = false;
        this.generic.showNotification(
          "error",
          "اضافة",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }
    );
  }
  sendToTopic(title: string, message: string, topic: string,photo) {
    this.service.sendToTopic(title, message, topic,photo).subscribe({
      next: (data: any) => {
        this.isAdd = false;

        this.generic.showNotification("success", "اضافة", "تمت العملية بنجاح");
        this.form.reset();
        this.navigate.navigateByUrl("/notifications/all");
      },
      error: (e) => {
        this.isAdd = false;
        this.generic.showNotification(
          "error",
          "اضافة",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      },
    });
  }
  ngOnDestroy(): void {
    localStorage.removeItem("item");
  }
}
