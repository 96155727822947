import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { GenericService } from "src/core/services/generic/generic.service";
import { NotificationsService } from "src/core/services/notifications/notifications.service";
import { Iinputs } from "src/core/shared/generic-filter/Interfaces/inputInterface";
import { ITableConfiguration } from "src/core/shared/generic-table/Interfaces/TableConfiguration";
import { ITableActions } from "src/core/shared/generic-table/Interfaces/TableActions";

@Component({
  selector: "app-notifications-index",
  templateUrl: "./notifications-index.component.html",
  styleUrls: ["./notifications-index.component.css"],
})
export class NotificationsIndexComponent implements OnInit {
  notifications: any;
  notificationsTotal: number = 0;
  data: any;
  heads = ["#", "العنوان", "الرسالة", "الصورة", "Actions"];
  pages: any = {};
  loading: boolean;
  URL = environment.photoPath;
  page = 1;
  limit = 5;
  canLoadMore: boolean = true;
  filterData: any;

  filterInputs: Iinputs[] = [
    {
      type: "text",
      name: "title",
      placeholder: "العنوان",
    },
    {
      type: "date",
      name: "date_from",
      placeholder: "تاريخ من",
      ignoreStartDate: false,

    },
    {
      type: "date",
      name: "date_to",
      placeholder: "تاريخ الى",
      ignoreStartDate: false,

    },
  ];

  values: ITableConfiguration[] = [
    { title: "العنوان", key: "title", type: "text" },
    { title: "الرسالة", key: "text", type: "text" },
    { title: "الصورة", key: "photo", type: "image" },
    { title: "تاريخ الإنشاء", key: "created", type: "date" },
    { title: "تاريخ التعديل", key: "modified", type: "date" },
  ];

  actions: ITableActions = {
    delete: true,
    edit: true,
    print: true,
  };

  constructor(
    private generic: GenericService,
    private service: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {
  }
  filter(values: any) {
    this.filterData = "";
    // values.vendor_id = this.vendor_id;
    Object.keys(values).forEach((key) => {
      let value: any = values[key];
      if (value) {
        if (key == "date_from" || key == "date_to") {
          this.filterData += `
            DateFilter: ["${values.date_from || ""}", "${
            values.date_to || ""
          }","created"]
            `;
          delete values.date_to;
          delete values.date_from;
        } else if (key == "title" || key == "phone" || key == "address") {
          this.filterData += `
          ${key}Like: "${value}"`;
        } else {
          this.filterData += `
          ${key}Eq: ${value}
          `;
        }
      }
    });
    this.page = 1;

    this.get();
  }
  async get() {
    await this.service.get(this.page, this.limit, this.filterData).subscribe({
      next: (result: any) => {
        this.notifications = result.data.notifications;
        console.log(result.data.notifications);
      },
      error: (error) => {},
    });
  }

  edit(item: any) {
    const obj = JSON.stringify(item);
    localStorage.setItem("item", obj);
    this.router.navigate([`notifications/edit`]);
  }

  async delete(id: number) {
    console.log(id);

    this.loading = true;
    await this.service.delete(id).then(
      async () => {
        await this.get();
        this.loading = false;
        this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
      },
      () => {
        this.loading = false;
        this.generic.showNotification(
          "success",
          "حذف",
          "حدث خطأ بالرجاء المحاولة في وقت الحق"
        );
      }
    );
  }

  onPageChange(page: number) {
    this.page = page;
    this.get();
  }
  onSizeChange(limit: number) {
    this.limit = limit;
    this.page = 1;

    this.get();
  }

  paginate(event: any) {
    console.log(event);
    this.page = event.page + 1;
    this.limit = event.rows;
    this.get();
  }
}
