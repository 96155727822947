import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "itemStoreTypes" })
export class ItemStoreTypesPipe implements PipeTransform {
  transform(value: any, exponent = 1): any {
    if (value == 0) {
      return "سحب";
    } else if (value == 1) {
      return "ايداع";
    } else if (value == 2) {
      return "مرتجع";
    }
    return;
  }
}
