<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="title"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
  [nzStyle]="{ top: '20px' }"
  nzClassName="lang-ar"
>
  <div class="row" *ngIf="BillingData">
    <div class="col-md-4">
      <strong>الاسم الاول :</strong>
      {{ BillingData?.first_name ? BillingData?.first_name : BillingData?.username }}
    </div>
    <div class="col-md-4">
      <strong>الاسم الاخير :</strong>
      {{ BillingData?.last_name ? BillingData?.last_name : "--" }}
    </div>
    <div class="col-md-4">
      <strong>رقم الهاتف :</strong>
      {{ BillingData?.phone ? BillingData?.phone : BillingData?.mobile }}
    </div>
  </div>
  <div
    class="row"
    *ngIf="BillingData"
    style="margin-top: 10px; margin-bottom: 10px"
  >
    <div class="col-md-6">
      <strong>العنوان :</strong>
      {{ BillingData?.address ? BillingData?.address : "--" }}
    </div>
    <div class="col-md-6">
      <strong>الملاحظات :</strong>
      {{ BillingData?.notes ? BillingData?.notes : "--" }}
    </div>
    <div class="col-md-6">
      <strong>الدور :</strong>
      {{ BillingData?.floor_number ? BillingData?.floor_number : "--" }}
    </div>
    <div class="col-md-6">
      <strong>الشقة :</strong>
      {{ BillingData?.apartment_number ? BillingData?.apartment_number : "--" }}
    </div>
  </div>
  <div class="row" *ngIf="BillingData">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <agm-map
            [latitude]="BillingData?.latitude"
            [longitude]="BillingData?.longitude"
            [zoom]="15"
          >
            <agm-marker
              [latitude]="BillingData?.latitude"
              [longitude]="BillingData?.longitude"
            ></agm-marker>
          </agm-map>
        </div>
      </div>
      <!-- <div class="card">
        <div class="card-body">
          <agm-map
            [latitude]="BillingData?.latitude"
            [longitude]="BillingData?.longitude"
            [zoom]="zoom"
          >
            <agm-marker
              [latitude]="deliveryCoordinate?.latitude"
              [longitude]="deliveryCoordinate?.longitude"
            ></agm-marker>
            <agm-direction
              [origin]="{
                lat: OrderObject?.branches?.latitude,
                lng: OrderObject?.branches?.longitude
              }"
              [destination]="{
                lat: BillingData?.latitude,
                lng: BillingData?.longitude
              }"
            >
            </agm-direction>
          </agm-map>
        </div>
      </div> -->
    </div>
  </div>
  <div class="table-responsive card custom-br custom-shadow">
    <nz-table
      #headerTable
      nzShowPagination
      [nzData]="data"
      [nzPageSize]="10"
      [nzScroll]="{ x: '240px' }"
      class="table forms-table table-striped"
      *ngIf="data != null"
    >
      <thead>
        <tr class="bg-light">
          <th *ngFor="let head of header; let i = index">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let allData of headerTable.data; let i = index">
          <td>{{ i + 1 }}</td>
          <ng-container *ngFor="let d of allData | render">
            <td *ngIf="d != 'options'">{{ allData[d] }}</td>
          </ng-container>

          <td>
            <p *ngFor="let option of allData['options']">
              {{ option.menu_options.name }}
            </p>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-table
      [nzData]="data"
      *ngIf="data != null && data.length == 0"
    ></nz-table>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row button-group" style="margin: 15px 0">
        <ng-container *ngIf="OrderObject?.order_status_id == 0">
          <div class="col-3">
            <button
              (click)="AcceptOrder()"
              class="btn btn-rounded btn-block btn-info"
            >
              قبول
            </button>
          </div>

          <div class="col-3">
            <button
              nz-popconfirm
              nzPopconfirmTitle="Are you sure delete this restuarant?"
              (nzOnConfirm)="CancelOrder()"
              (nzOnCancel)="cancel()"
              nzPopconfirmPlacement="topLeft"
              nz-button
              class="btn btn-rounded btn-block btn-danger"
            >
              الغاء
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="OrderObject?.order_status_id == 1">
          <div class="col-3">
            <button
              (click)="OrderCompleteInKitchen()"
              class="btn btn-rounded btn-block btn-info"
            >
              تم التحضير
            </button>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            OrderObject?.order_status_id == 2 && OrderObject?.delivery_id == 0
          "
        >
          <div class="col-3">
            <nz-select
              style="width: 200px"
              [(ngModel)]="deliveryID"
              nzShowSearch
              nzAllowClear
              nzPlaceHolder="اختيار مندوب"
            >
              <nz-option
                *ngFor="let d of delivery; let i = index"
                [nzLabel]="d?.user?.username"
                [nzValue]="i"
              ></nz-option>
            </nz-select>
          </div>

          <div class="col-3">
            <button
              (click)="sendOrderToDelivery()"
              class="btn btn-rounded btn-block btn-info"
            >
              ارسال مندوب
            </button>
          </div>
        </ng-container>

        <ng-container
          style="color: chartreuse"
          *ngIf="OrderObject?.order_status_id == 3"
        >
          جاري التوصيل
        </ng-container>
      </div>
    </div>
  </div>
</nz-modal>
