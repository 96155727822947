<nz-modal
  [(nzVisible)]="Show"
  [nzTitle]="'اضافة قسم جديد للمنيو'"
  (nzOnCancel)="handleCancel()"
  [nzFooter]='null'
>
  <nz-input-group *appPermessions="[userGroup.Content, userGroup.Admin]">
    <label
      for="xlss"
      class="btn btn-success"
      matRipple
      style="margin-bottom: 15px"
    >
      <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      ادخال من ملف اكسيل
    </label>
    <input
      type="file"
      class="form-control-filee"
      id="xlss"
      name="xlss"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      (change)="onFileChangee($event)"
    />
  </nz-input-group>

  <input
    type="text"
    style="width: 100%; margin-bottom: 15px; direction: rtl"
    nz-input
    nzSize="large"
    id="address"
    *ngIf="CateData"
    [(ngModel)]="CateData.name"
    placeholder="الاسم"
  />

  <input
    type="text"
    style="width: 100%; margin-bottom: 15px; direction: rtl"
    nz-input
    nzSize="large"
    id="address"
    [(ngModel)]="CateData.name_en"
    placeholder="الاسم بالانجليزي"
  />

  <div class="d-flex flex-column align-items-start">
    <label for="active"> هل هو عرض ؟ </label>
    <p-inputSwitch
      [(ngModel)]="CateData.is_offer"
      [trueValue]="1"
      [falseValue]="0"
    ></p-inputSwitch>
  </div>

  <div class="input-group-lg">
    <nz-input-group>
      <label for="photo" class="btn btn-block" matRipple>
        <i class="mdi mdi-upload"></i>
        اختر الصورة
      </label>
      <input
        type="file"
        class="form-control-file"
        id="photo"
        name="photo"
        accept="image/*,.xlsx,.xls"
        [(ngModel)]="imgInput"
        (change)="getImageName($event.target.files)"
      />
    </nz-input-group>
    <div class="w-25 m-auto" *ngIf="ImgSrc">
      <button
        type="submit"
        class="close m-2"
        nz-popconfirm
        nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="deleteImg()"
      >
        <span style="color: red">&times;</span>
      </button>
      <img [src]="ImgSrc" />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12">
      <div class="form-group mb-3">
        <button class="btn btn-block btn-lg btn-info" (click)="save()">
          <i nz-icon nzType="loading" *ngIf="loading" nzTheme="outline"></i>
          اضافة
        </button>
      </div>
    </div>
  </div>
</nz-modal>
