<div class="row bigrow">
  <div class="col-md-12">
    <div class="row townsinputs">
      <div class="col-md-6" *ngIf="!isedit">
        <input
          class="form-control location"
          placeholder=" اسم المستخدم"
          [(ngModel)]="User.username"
          (blur)="validateUniq(User.username, 'username')"
          type="text"
          [required]="!isedit"
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!User.username">
            بالرجاء كتابة اسم المستخدم
          </span>
          <span style="color: red" *ngIf="isUsed?.username">
            اسم المستخدم مموجود بالفعل
          </span>
        </div>
      </div>

      <div class="col-md-6" *ngIf="!isedit">
        <input
          class="form-control location"
          placeholder="الباسوورد"
          type="text"
          value="codesroots@123"
          disabled
        />
      </div>

      <div class="col-md-6">
        <input
          class="form-control location"
          placeholder="اسم الفرع"
          [(ngModel)]="BranchObj.name"
          type="text"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.name">
            بالرجاء كتابة اسم الفرع
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <input
          class="form-control"
          [(ngModel)]="BranchObj.name_en"
          placeholder="اسم الفرع انجليزي"
          type="text"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.name_en">
            بالرجاء كتابة اسم الفرع باللغة الانجليزية
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <input
          class="form-control location"
          placeholder="العنوان"
          [(ngModel)]="BranchObj.address"
          type="text"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.address">
            بالرجاء كتابة العنوان
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <input
          class="form-control"
          [(ngModel)]="BranchObj.address_en"
          placeholder="العنوان انجليزى"
          type="text"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.address_en">
            بالرجاء كتابة العنوان باللغة الإنجليزية
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <input
          class="form-control"
          [(ngModel)]="coordinate"
          placeholder="الاحداثيات"
          required
        />
        <div class="d-flex">
          <span
            style="color: red"
            *ngIf="coordinate && !coordinate.includes(',')"
          >
            بالرجاء كتابة الإحداثيات بطريقة صحيحة
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <img
          class="mapicon"
          (click)="editLocation($event, '')"
          src="./../../../../assets/images/location.png"
        />
      </div>
    </div>

    <div class="row townsinputs">
      <div class="col-md-6">
        <input
          class="form-control"
          [(ngModel)]="BranchObj.phone"
          placeholder="رقم الهاتف "
          type="tel"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.phone">
            بالرجاء كتابة رقم الهاتف
          </span>
        </div>
      </div>

      <div class="col-md-6">
        <input
          class="form-control"
          [(ngModel)]="BranchObj.phone_two"
          placeholder="رقم الهاتف اخر"
          required
        />
        <div class="d-flex">
          <span style="color: red" *ngIf="!BranchObj.phone_two">
            بالرجاء كتابة رقم الهاتف
          </span>
          <span
            style="color: red"
            *ngIf="
              BranchObj.phone_two && BranchObj.phone_two == BranchObj.phone
            "
          >
            بالرجاء كتابة رقم الهاتف مختلف
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group mb-3">
          <button class="btn btn-block btn-lg btn-info" (click)="add()">
            <!-- <i nz-icon nzType="loading" nzTheme="outline"></i> -->
            {{ isedit ? "تعديل" : "اضافة" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<nz-modal
  [(nzVisible)]="isLocationVisible"
  [nzTitle]="'تحديث العنوان'"
  [nzFooter]="null"
  (nzOnCancel)="isLocationVisible = false"
>
  <input
    type="text"
    style="width: 100%; margin-bottom: 15px; direction: rtl"
    nz-input
    nzSize="large"
    id="address"
    placeholder="كتابة العنوان الجديد"
    (keyup)="changeAddress($event)"
  />

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" #map3>
            <agm-marker
              (dragEnd)="markerDragEnd($event)"
              [markerDraggable]="true"
              [latitude]="lat"
              [longitude]="lng"
            ></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
    <div class="col-12">
      <button
        class="form-control w-100"
        style="margin-top: 15px"
        nz-button
        matRipple
        (click)="updateLocation()"
      >
        <div class="loader text-center w-100" *ngIf="updateLoader"></div>
        <span *ngIf="!updateLoader"> تحديث العنوان </span>
      </button>
    </div>
  </div>
</nz-modal>

<!-- end modal -->
