<div class="row mainview px-2">
  <div class="col-4 table-hover">
    <button
      matRipple
      matRippleColor="rgba(0,0,0,.2) "
      matRippleCentered="centered"
      nz-button
      nzType="default"
      (click)="loadTwo()"
      [nzLoading]="isLoadingTwo"
    >
      <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
      اضافة قسم
    </button>

    <div
      class="topics"
      *ngFor="let menucats of MenuCat; let i = index"
      [class.hovercolor]="menucats.hover"
      (mouseover)="menucats.hover = true"
      (mouseout)="menucats.hover = false"
      [class.active]="menucats.active"
      (click)="chooseCategory(i)"
      #menu_dishes
    >
      <div class="col-md-10 topicstext">{{ menucats.name }}</div>

      <div>
        <!-- *appPermessions="[userGroup.SetMenuDepartment,userGroup.Content, userGroup.Admin]" -->
        <nz-avatar
          (click)="editcategory(menucats)"
          nzIcon="edit"
          nzSize="small"
          style="vertical-align: left"
        ></nz-avatar>

        <nz-avatar
          nz-popconfirm
          nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
          nzPopconfirmPlacement="top"
          (nzOnConfirm)="deletecategory(menucats)"
          nzIcon="delete"
          nzSize="small"
          style="float: left; right: -6px"
        ></nz-avatar>
      </div>
    </div>
  </div>

  <div class="col-8">
    <div class="row">
      <div class="col-md-12 d-flex gap-2 mb-2" *ngIf="CatId">
        <button
          matRipple
          matRippleColor="rgba(0,0,0,.2) "
          matRippleCentered="centered"
          nz-button
          nzType="default"
          class="m-0"
          (click)="SwitchToAddTopic()"
          [nzLoading]="isLoadingTwo"
        >
          <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
          اضافة طبق
        </button>

        <a
          class="btn-warning p-1 text-center border-round-lg"
          href="../../../../assets/ExcelSheetsExamples/الاطباق.xlsx"
        >
          <i class="fa fa-download"></i>
          <span> تحميل مثال </span>
        </a>
        <label for="xls" class="btn btn-success" matRipple>
          <i nz-icon nzType="file-excel" nzTheme="outline"></i>
          ادخال من ملف اكسيل
        </label>
        <input
          type="file"
          class="form-control-file"
          id="xls"
          [(ngModel)]="file"
          name="xls"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          (change)="onFileChange($event)"
        />
        <ng-container>
          <button
            matRipple
            matRippleColor="rgba(0,0,0,.2) "
            matRippleCentered="centered"
            nz-button
            nzType="default"
            class="m-0"
            (click)="UploadExcelFile()"
            [nzLoading]="isLoadingTwo"
          >
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            رفع ال excel
          </button>
        </ng-container>
      </div>
      <div class="col-md-4">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="searchTxt"
          (ngModelChange)="search()"
          placeholder="بحث الاطباق"
        />
      </div>
      <div class="col-md-8">
        <button
          nz-popconfirm
          nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
          nzPopconfirmPlacement="top"
          class="btn btn-danger float-right"
          (nzOnConfirm)="deleteItemsByCategries()"
        >
          <i class="fa fa-trash" nzTheme="outline"></i>
          deleteAll
        </button>
        <button
          *ngIf="!ShowDeSelect"
          nzPopconfirmPlacement="top"
          class="btn btn-success float-right ml-1"
          (click)="SelectAllDishes()"
        >
          <i class="fas fa-check" nzTheme="outline"></i>

          اختيار كل الاطباق
        </button>
        <button
          *ngIf="currentUser != departmentEnum.Training && ShowDeSelect"
          nzPopconfirmPlacement="top"
          class="btn btn-danger float-right ml-1"
          (click)="deSelect()"
        >
          <i class="fas fa-check" nzTheme="outline"></i>

          الغاء اخنيار الاطباق
        </button>
        <button
          nzPopconfirmPlacement="top"
          class="btn btn-success float-right ml-1"
          (click)="showModal()"
        >
          <i class="fas fa-check" nzTheme="outline"></i>

          النقل الى
        </button>
      </div>
    </div>

    <div class="my-orders" *ngFor="let menuitem of MenuItem; let i = index">
      <div
        class="order-box"
        [ngClass]="{ 'bg-black-alpha-20': !menuitem.active }"
      >
        <div class="row">
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <input
              hidden
              type="file"
              #uploader
              accept=".jpg"
              (change)="uploadFile($event)"
            />

            <button
              (click)="uploader.click(); set_dish_object(menuitem)"
              class="MuiButtonBase-root jss29 example-ripple-container mat-elevation-z4"
              tabindex="0"
              type="button"
              data-testid="addImage"
            >
              <img
                src="{{ URL }}/{{ menuitem.photo }}"
                alt="order-img"
                class="img-thumbnail img-fluid"
              />
            </button>
          </div>

          <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
            <ul class="order-details-list list-unstyled">
              <li class="order-name">
                <strong> {{ menuitem.name }} ({{ menuitem.name_en }}) </strong>
              </li>
              <li class="descitem">
                {{ menuitem.descriptions }}
              </li>
              <div *ngIf="menuitem.price > 0; then content; else other_content">
                here is ignored
              </div>
              <ng-template #content>{{ menuitem.price }} جنيه</ng-template>
              <ng-template #other_content>السعر حسب الاختيار</ng-template>

              <li *ngFor="let size of menuitem.menu_options_topics">
                <b style="margin-left: 5px">{{ size.name }}:</b>
                <label
                  class="label label-warning"
                  *ngFor="let options of size.menu_options"
                  style="margin-left: 5px"
                  >{{ options.name }}</label
                >
                <!--<span *ngIf="order.confirm == 1">
                                <button class="btn float-left" matRipple>
                                    <i nz-icon nzType="reload" nzTheme="outline"></i>
                                    اطلب مرة اخرى
                                </button>
                            </span>
                            <span *ngIf="orders[i].typeorder == 2">
                                <button class="btn float-left" (click)="editLocation($event, i)" matRipple>
                                    <i nz-icon nzType="environment" nzTheme="outline"></i>
                                    تحديث العنوان
                                </button>
                            </span>     -->
              </li>
            </ul>

            <!-- edit -->
            <nz-avatar
              (click)="editItem(menuitem)"
              nzIcon="edit"
              nzSize="small"
              style="float: left"
            ></nz-avatar>
            <!-- delete -->
            <nz-avatar
              nz-popconfirm
              nzPopconfirmTitle="هل انت متأكد من عملية الحذف؟"
              nzPopconfirmPlacement="top"
              (nzOnConfirm)="deleteItem(menuitem, i)"
              nzIcon="delete"
              nzSize="small"
              style="float: left; right: -6px"
            ></nz-avatar>
            <span
              nzIcon="active"
              nzSize="small"
              class="position-absolute badge"
              (click)="changeItemStatus(menuitem)"
              [ngClass]="{
                'badge-success': !menuitem.active,
                'badge-danger': menuitem.active
              }"
              style="left: 26px; top: -1px; font-size: 12px"
              >{{ menuitem.active ? "اخفاء" : "اظهار" }}</span
            >
            <input
              type="checkbox"
              name="chekedDish"
              (click)="Checked(menuitem.id, i)"
              [value]="menuitem.id"
              style="height: 20px; width: 20px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-add-category-modal
  (closeDV)="successOperation()"
  [CateData]="seletedCatMenu"
  [Show]="showaddcat"
></app-add-category-modal>

<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  [nzOkText]="null"
  nzWidth="60%"
  [nzFooter]="null"
>
  <div class="form-group mb-3">
    <nz-form-label nzRequired>
      <label for="user_id"> الاقسام </label>
    </nz-form-label>
    <div class="input-group-lg">
      <nz-select
        style="width: 100%"
        nzShowSearch
        [(ngModel)]="MenuCat_id"
        (ngModelChange)="sendTheDishToCat($event)"
      >
        <nz-option
          *ngFor="let d of MenuCat; let i = index"
          [nzLabel]="d.name"
          [nzValue]="{ id: d.id, index: i }"
        ></nz-option>
      </nz-select>
    </div>
  </div>
</nz-modal>
