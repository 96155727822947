import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'round'})
export class RoundPipe implements PipeTransform {
    transform(value, args:string[]) : any {
        if (isNaN(value) || value == null || value % 1 == 0) {
            return value;
        }
        if(value == null) return 0
        return value.toFixed(3);
    }
}
