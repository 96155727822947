import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { UserGroup } from "src/core/enums/user.group.enum";
import { MenuItemsTopics, MenuOption } from "src/core/models/MenuModel";
import { environment } from "src/environments/environment";
import { BindingService } from "src/core/services/binding/binding.service";
import {
  MenuCategories,
  MenuItems,
} from "src/core/services/cities/citiesModel";
import { GenericService } from "src/core/services/generic/generic.service";
import { MinMenuService } from "src/core/services/min-menu/min-menu.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-MainMenuindex",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class MainMenuIndexComponent implements OnInit {
  @ViewChild("menu_dishes") menu_dishes: any;
  isLoadingTwo = false;
  active = false;
  MenuCat: MenuCategories[] = [];
  MenuItem: MenuItems[] = [];
  FilterMenuItem: MenuItems[] = [];
  seletedCatMenu: MenuCategories;
  searchTxt: string;
  userGroup = UserGroup;
  file;
  showaddcat = false;
  URL = environment.photoPath;
  CatId = 0;
  RestId;
  image: any;
  itemphotofile: any;
  menu_categorie_id: any;
  dish_obj_data: any;
  image_url_ready: any;
  isDished: boolean;
  departmentEnum = DepartmentsEnum;
  currentUser = JSON.parse(localStorage.getItem("department_id"));
  currentOptionLength: number = 0;
  checkedInputs: any[] = [];
  ShowDeSelect: boolean = false;
  isVisible: any;
  MenuCat_id;
  constructor(
    private service: MinMenuService,
    private generic: GenericService,
    private binding: BindingService,
    private navigate: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getTownsList();
    this.binding.checkIsHasTitle.subscribe((res) => {
      console.log(res);
      if (res == true) {
        this.getTownsList();
      } else {
        this.showaddcat = false;
      }
    });
  }
  async getTownsList() {
    this.RestId = JSON.parse(localStorage.getItem("user")).restaurants.id;

    const data = await this.service.getMenuCategories(this.RestId);
    this.MenuCat = data;

    console.log(this.MenuCat);
  }
  successOperation() {
    this.showaddcat = false;
  }
  loadTwo() {
    this.showaddcat = true;
  }

  // menu
  async chooseCategory(i) {
    this.menu_categorie_id = i;
    this.active = !this.active;
    this.CatId = this.MenuCat[i].id;
    this.MenuCat[i].active = this.active;
    const data = await this.service.getItemsByCategories(this.MenuCat[i].id);
    this.isDished = Boolean(data.length);
    this.MenuItem = data;
    this.currentOptionLength = this.MenuItem.length;
    this.FilterMenuItem = data;
    this.file = null;
  }

  async deleteItemsByCategries() {
    const data = await this.service.deleteItemsByCategories(this.CatId);

    if (data > 0) {
      this.generic.showNotification("success", "تم المسح", "تم الحذف بنجاح");

      const data = await this.service.getItemsByCategories(this.CatId);
      this.isDished = Boolean(data.length);
      this.MenuItem = data;
      this.FilterMenuItem = data;
      this.file = null;
    } else {
      this.generic.showNotification(
        "error",
        "فشل الحذف",
        "لم يتم الحذف الرجاء المحاولة لاحقا"
      );
    }
  }

  // dish

  set_dish_object(dish_obj: any) {
    this.dish_obj_data = dish_obj;
  }

  uploadFile($event) {
    if ($event.target.files[0].name.endsWith(".exe")) {
      return this.generic.showNotification(
        "error",
        "ملف غير صالح",
        "هذا الملف غير صالح يرجى اختيار صورة"
      );
    }
    console.log($event.target.files[0]); // outputs the first file
    this.image = $event.target.files[0];

    this.image_url_ready = "uploads/cities/" + this.image.name;

    console.log(this.image_url_ready);

    var reader = new FileReader();
    reader.readAsDataURL(this.image);

    reader.onload = (_event) => {
      this.itemphotofile = reader.result;
    };

    this.edit_image(this.dish_obj_data);
  }

  async edit_image(data_obj: any) {
    console.log("my data : ", data_obj);

    setTimeout(async () => {
      console.log("image upload and we are in!");

      // set new object
      // data_obj.photo = this.image_url_ready;

      let new_obj = data_obj;

      var formData: any = new FormData();

      formData.append("data", JSON.stringify(new_obj));
      formData.append("img", this.image);

      const status_of_api = await this.service.addNewItemInMenu(formData);

      if (status_of_api) {
        this.generic.showNotification(
          "success",
          "تمت العملية",
          "تمت اضافة الطبق بنجاح"
        );
        const data = await this.service.getItemsByCategories(this.CatId);
        this.isDished = Boolean(data.length);
        this.MenuItem = data;
        this.FilterMenuItem = data;
        this.file = null;
      } else {
        this.generic.showNotification(
          "error",
          "لم تتم العملية",
          "لم يتم اضافة الطبق "
        );
      }
    }, 1000);
  }

  async changeItemStatus(item) {
    const data = await this.service.editMenuItem(item.id, {
      active: +!item.active,
    });
    if (data) {
      this.getItemDataByCatId(this.CatId);
      this.generic.showNotification("success", "تم", "تم تغيير الحالة بنجاح");
    } else {
      this.generic.showNotification(
        "error",
        "لم يتم",
        "فشل تغيير الحالة الرجاء المحاولة مرة اخرى"
      );
    }
  }

  onFileChange(evt: any) {
    // console.log(this.file)
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const ab: ArrayBuffer = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(ab);

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const wsname2: string = wb.SheetNames[1];
      const ws2: XLSX.WorkSheet = wb.Sheets[wsname2];

      const wsname3: string = wb.SheetNames[2];
      const ws3: XLSX.WorkSheet = wb.Sheets[wsname3];

      // console.log(ws)
      // console.log(ws2)
      // console.log(ws3)

      /* save data */
      const ItemData = XLSX.utils.sheet_to_json(ws) as MenuItems[];
      const TopicData = XLSX.utils.sheet_to_json(ws2) as MenuItemsTopics[];
      const OptionsData = XLSX.utils.sheet_to_json(ws3) as MenuOption[];

      ItemData.forEach((element, index) => {
        element.menu_categories_id = this.CatId;
        this.MenuItem.push(element);
        TopicData.forEach((topic) => {
          if (topic.item_name == element.name) {
            // delete topic.item_name
            if (
              !this.MenuItem[index + this.currentOptionLength]
                .menu_options_topics
            ) {
              this.MenuItem[
                index + this.currentOptionLength
              ].menu_options_topics = new Array<MenuItemsTopics>();
            }
            this.MenuItem[
              index + this.currentOptionLength
            ].menu_options_topics.push(topic);
          }
        });
      });
      for (
        let ii = 0;
        ii < this.MenuItem.length - this.currentOptionLength;
        ii++
      ) {
        try {
          this.MenuItem[
            ii + this.currentOptionLength
          ].menu_options_topics.forEach((topic, topicIndex) => {
            OptionsData.forEach((options, i) => {
              if (
                options.topic_name == topic.name &&
                options.item_name == topic.item_name
              ) {
                if (
                  !this.MenuItem[ii + this.currentOptionLength]
                    .menu_options_topics[topicIndex].menu_options
                ) {
                  this.MenuItem[
                    ii + this.currentOptionLength
                  ].menu_options_topics[topicIndex].menu_options =
                    new Array<MenuOption>();
                }
                delete options.topic_name;
                delete options.item_name;
                this.MenuItem[
                  ii + this.currentOptionLength
                ].menu_options_topics[topicIndex].menu_options.push(options);
              }
            });
            delete topic.item_name;
            console.log(topic);
          });
        } catch (e) {}
      }
    };
    reader.readAsArrayBuffer(target.files[0]);
  }
  async UploadExcelFile() {
    const data = await this.service
      .addNewItemInMenuFromExcel(this.MenuItem)
      .then((res) => {
        this.generic.showNotification("success", "تم", res.data);
        this.getItemDataByCatId(this.CatId);
        this.file = null;
      })
      .catch((err) => {
        this.generic.showNotification("error", "تم", err.data);
      });
  }
  async getItemDataByCatId(CatId) {
    const data = await this.service.getItemsByCategories(CatId);
    this.MenuItem = data;
  }
  search() {
    this.MenuItem = this.FilterMenuItem.filter((x) => {
      return x.name.includes(this.searchTxt);
    });
    if (this.searchTxt == "") {
      this.MenuItem = this.FilterMenuItem;
    }
    console.log(this.searchTxt);
  }
  SwitchToAddTopic() {
    if (this.CatId != 0) {
      this.navigate.navigate(["menu/add/" + this.CatId + "/" + this.RestId]);
    } else {
      this.generic.showNotification(
        "error",
        "خطأ",
        "قم باختيار قسم الطبق اولا"
      );
    }
  }

  async deletecategory(data: MenuCategories) {
    await this.service.Delete(data.id);
    this.generic.showNotification("success", "تم", "تم مسح القسم بنجاح");
    this.menu_categorie_id = undefined;
    this.active = !this.active;
    this.CatId = undefined;
    this.isDished = false;
    this.MenuItem = [];
    this.currentOptionLength = this.MenuItem.length;
    this.FilterMenuItem = undefined;
    this.file = null;

    this.getTownsList();
  }
  editcategory(data: MenuCategories) {
    this.showaddcat = true;
    this.seletedCatMenu = data;
  }
  async deleteItem(data: MenuItems, index) {
    await this.service.DeleteMenuItem(data.id);
    this.generic.showNotification("success", "تم", "تم مسح الطبق بنجاح");
    this.MenuItem.splice(index, 1);
  }
  editItem(data: MenuItems) {
    if (this.CatId != 0) {
      localStorage.setItem("edit_dish", JSON.stringify(true));

      this.navigate.navigateByUrl(
        "menu/add/" + this.CatId + "/" + this.RestId,
        {
          state: data,
        }
      );

      // save data
      localStorage.setItem("dish_obj", JSON.stringify(data));
    } else {
      this.generic.showNotification(
        "error",
        "خطأ",
        "قم باختيار قسم الطبق اولا"
      );
    }
  }
  SelectAllDishes() {
    this.checkedInputs = [];
    var ele: any = document.getElementsByName("chekedDish");
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
      this.checkedInputs.push(+ele[i].value);
    }
    this.ShowDeSelect = true;
    console.log(this.checkedInputs);
  }

  Checked(id, i) {
    var ele: any = document.getElementsByName("chekedDish");
    if (ele[i].checked) {
      this.checkedInputs.push(id);
      console.log(this.checkedInputs);
    } else {
      const index = this.checkedInputs.indexOf(id);
      if (index > -1) {
        this.checkedInputs.splice(index, 1);
      }
      console.log(this.checkedInputs);
    }
  }
  deSelect() {
    var ele: any = document.getElementsByName("chekedDish");
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    this.checkedInputs = [];
    this.ShowDeSelect = false;
    console.log(this.checkedInputs);
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  showModal(): void {
    this.isVisible = true;
  }
  async sendTheDishToCat(event) {
    const data = await this.service.sendTheDishesToCategory(
      event.id,
      this.checkedInputs
    );
    this.isVisible = false;
    this.chooseCategory(event.index);
    this.checkedInputs = [];
    this.MenuCat_id = null;
  }
}
