import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlatformsService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  getDropDown() {
    return this.apollo.watchQuery({
      query: gql`
        {
          platforms {
            id
            name
          }
        }
      `,
    }).valueChanges;
  }
}
